import React, { useState, useCallback, useEffect } from 'react'
import './input-file-component.scss'
import { InputFileProps, MultiInputFileProps, FileDataProps, Event } from '../../types'
import {
  FileUploadIcon,
  FileUploadedIcon,
  DeleteIcon,
  CheckCircleIcon,
  DeniedIcon,
  PendingIcon,
} from '../Icon'
import { ERROR, SUCCESS } from '../../utils/message'
import { StatusLabel } from '../StatusLabel'
import { Flex } from '../Flex'
import { TagWithModal } from '../Tag'
import { trimText } from '../../utils/helpers'
import { ToolTip } from '../ToolTip'
import { generateSupportedTypeMessage, generateMimeAcceptString } from '../../../utils/helpers'

const InputFile = ({
  label,
  name,
  handleFiles,
  file,
  type = 'image',
  errors,
  touched,
  dirty,
  fileInfo,
  handleUpdateReply,
  isClearInputFileData,
  setKycStatusData,
  setRemoveFile,
}: InputFileProps) => {
  const [fileData, setFileData] = useState<FileDataProps | any>(file || fileInfo)
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const date = fileInfo && fileInfo.updatedAt ? new Date(fileInfo.updatedAt) : new Date()
  useEffect(() => {
    if (fileInfo) {
      setFileData(fileInfo)
    }
  }, [fileInfo])
  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])
  const manageFiles = (e: Event) => {
    const files = e.target.files
    if (files && files[0]) {
      setFileData(files[0])
      handleFiles(files[0])
    }
  }
  useEffect(() => {
    if (isClearInputFileData) handleDeleteClick()
  }, [isClearInputFileData])
  const handleDeleteClick = () => {
    setRemoveFile && setRemoveFile((preValue: any) => [...preValue, name])
    setFileData({})
    setKycStatusData && setKycStatusData('PENDING')
  }

  useEffect(() => {
    if (fileData && fileData.name && fileData.lastModified) {
      const newFileData = fileData
      newFileData.status = 'UPLOADED'
      setFileData(newFileData)
    }
  }, [fileData])
  const handleStatusClass = (): string => {
    if (!!fileData && fileData.status === 'APPROVED')
      return 'input-file-main-container success-border'
    if (!!fileData && fileData.status === 'PENDING')
      return 'input-file-main-container pending-border'
    if (!!fileData && fileData.status === 'DECLINED')
      return 'input-file-main-container error-border'
    return 'input-file-main-container'
  }

  return (
    <div className={handleStatusClass()}>
      <span className='label-text'> {label} </span>
      {fileInfo && fileInfo.Placeholder ? (
        <ToolTip clickable={false} content={fileInfo.Placeholder}>
          <p className='placeholder-description'>
            {' '}
            Document Required{' '}
            <PendingIcon classes={['info-icon']} type='default-light' height='15' width='15' />
          </p>
        </ToolTip>
      ) : null}
      {!!fileData && fileData.status && fileData.status === 'PENDING' ? (
        <Flex variant='column' classes={['gap-12 mb-24']}>
          <Flex classes={['gap-12']}>
            <PendingIcon height='20' width='20' />
            <span className='pending'>SENT FOR APPROVAL</span>
          </Flex>
          {fileData.comment ? (
            <>
              <ToolTip clickable={true} content={fileData.comment}>
                <p className='comment pointer'>
                  <strong>Credibila Team comment:</strong> {trimText(fileData.comment)}
                </p>
              </ToolTip>
            </>
          ) : null}
          {fileData.reply ? (
            <>
              <ToolTip clickable={true} content={fileInfo.reply}>
                <p className='comment pointer'>
                  <strong>Reply:</strong> {trimText(fileInfo.reply)}
                </p>
              </ToolTip>
            </>
          ) : null}
          {fileData.comment ? (
            <TagWithModal
              fileInfo={fileInfo}
              handleUpdateReply={handleUpdateReply}
              variant='white-bg'
              text='Reply to comment'
            />
          ) : null}
        </Flex>
      ) : null}
      {fileData && !!fileData.status && fileData.status === 'DECLINED' ? (
        <Flex variant='column' classes={['gap-12 mb-24']}>
          <Flex classes={['gap-12']}>
            <DeniedIcon width='20' height='20' />
            <span className='error'>{fileData.status}</span>
          </Flex>
          {fileData.comment ? (
            <>
              <ToolTip clickable={true} content={fileData.comment}>
                <p className='error pointer'>
                  <strong>Credibila Team comment:</strong> {trimText(fileData.comment)}
                </p>
              </ToolTip>
            </>
          ) : null}
          {fileData.reply ? (
            <>
              <ToolTip clickable={true} content={fileInfo.reply}>
                <p className='pointer'>
                  <strong>Reply:</strong> {trimText(fileInfo.reply)}
                </p>
              </ToolTip>
            </>
          ) : null}
          {fileData.comment ? (
            <TagWithModal
              fileInfo={fileInfo}
              handleUpdateReply={handleUpdateReply}
              variant='white-bg'
              text='Reply to comment'
            />
          ) : null}
        </Flex>
      ) : null}
      {fileData && !!fileData.status && fileData.status === 'APPROVED' ? (
        <Flex variant='column' classes={['gap-12 mb-24']}>
          <Flex classes={['gap-12']}>
            <CheckCircleIcon height='20' width='20' />
            <span className='success'>{fileData.status}</span>
          </Flex>
          {fileData.comment ? (
            <>
              <ToolTip clickable={true} content={fileData.comment}>
                <p className='comment pointer'>
                  <strong>Credibila Team comment:</strong> {trimText(fileData.comment)}
                </p>
              </ToolTip>
            </>
          ) : null}
        </Flex>
      ) : null}
      {fileData && !!fileData.status && fileData.status === 'UPLOADED' ? (
        <Flex variant='column' classes={['gap-12 mb-24']}>
          <Flex classes={['gap-12']}>
            <CheckCircleIcon height='20' width='20' />
            <span className='success'>UPLOADED</span>
          </Flex>
          {fileData.comment ? (
            <>
              <ToolTip clickable={true} content={fileData.comment}>
                <p className='comment pointer'>
                  <strong>Credibila Team comment:</strong> {trimText(fileData.comment)}
                </p>
              </ToolTip>
            </>
          ) : null}
        </Flex>
      ) : null}
      <label
        className={`${
          fileData && fileData.name ? 'input-file-container selected-file' : 'input-file-container'
        }`}
        htmlFor={name}
      >
        {!fileData.name ? (
          <>
            <FileUploadIcon />
            <div className='input-file-label-container'>
              <span className='input-file-label mb-8'>Browse to upload</span>
              <span className='input-file-sub-label'>
                {type === 'image'
                  ? 'Supported file types: jpeg, jpg upto 10MB'
                  : 'Supported file types: pdf upto 10MB'}
              </span>
            </div>{' '}
          </>
        ) : (
          <>
            <FileUploadedIcon />
            <div className='input-file-label-container'>
              <span className='input-file-label mb-8'> {fileData && fileData.name} </span>
              <span className='input-file-sub-label'>
                {fileData &&
                  `Uploaded  ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}{' '}
              </span>
            </div>
            <span className='input-file-delete-icon'>
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (e.isDefaultPrevented() && e.isPropagationStopped()) handleDeleteClick()
                }}
              />
            </span>
          </>
        )}
        <input
          id={name}
          className='input-file'
          name={name}
          type='file'
          accept={type === 'image' ? 'image/jpg, image/jpeg' : 'application/pdf'}
          onChange={(e: Event) => manageFiles(e)}
          onClick={(e: any) => {
            e.target.value = null
          }}
        ></input>
      </label>
      {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
    </div>
  )
}

const MultiInputFile = ({
  label,
  name,
  handleFiles,
  file,
  type = ['image'],
  errors,
  touched,
  dirty,
  handleDeleteFile,
  limit = undefined,
  maxSizeInMB = undefined,
}: MultiInputFileProps) => {
  const [fileData, setFileData] = useState<FileDataProps[] | undefined>(file)
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const date = new Date()
  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])
  const manageFiles = (e: Event) => {
    const files = e.target.files
    if (limit && files && files?.length > limit) {
      alert(`You can only upload a maximum of ${limit} files.`)
      return
    }

    const totalUploadSize = Object.values(files ?? {})?.reduce(
      (acc, curr) => acc + curr.size / 1000000,
      0,
    )

    if (maxSizeInMB && totalUploadSize > maxSizeInMB) {
      alert(`Total file size must be less than ${maxSizeInMB}.`)
      return
    }

    if (files) setFileData([...files])
    if (files) handleFiles([...files])
  }

  const handleDeleteClick = () => {
    setFileData(undefined)
    handleDeleteFile && handleDeleteFile()
  }

  const handleFileDelete = (removeIndex: number) => {
    const filtered = fileData?.filter((data, index) => index !== removeIndex)
    setFileData(filtered)
    handleFiles(filtered)
    if (filtered?.length === 0) setFileData(undefined)
  }

  return (
    <div className='input-file-main-container'>
      <span> {label} </span>
      <label
        className={`${
          fileData ? 'input-file-container multi selected' : 'input-file-container multi'
        }`}
        htmlFor={name}
      >
        {!fileData ? (
          <>
            <FileUploadIcon />
            <div className='input-file-label-container'>
              <span className='input-file-label mb-8'>Browse to upload</span>
              <span className='input-file-sub-label'>
                {`${generateSupportedTypeMessage(type)} upto 10MB each`}
              </span>
            </div>
          </>
        ) : (
          <>
            {fileData && fileData.length > 0 ? '' : <FileUploadedIcon />}
            <div className='input-file-label-container'>
              <ol>
                {fileData &&
                  fileData.map((obj, index): any => (
                    <li key={index} className=''>
                      <div className='file-list'>
                        <span key={index} className='input-file-label mb-8 mr-4'>
                          {' '}
                          {obj.name}{' '}
                        </span>
                        <DeleteIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            if (e.isDefaultPrevented() && e.isPropagationStopped())
                              handleFileDelete(index)
                          }}
                        />
                      </div>
                    </li>
                  ))}
              </ol>
              <span className='input-file-sub-label'>
                {fileData &&
                  `Uploaded  ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}{' '}
              </span>
            </div>
            <span className='input-file-delete-icon'></span>
          </>
        )}
        <input
          id={name}
          className='input-file'
          name={name}
          type='file'
          onChange={(e: Event) => manageFiles(e)}
          multiple
          accept={generateMimeAcceptString(type)}
        ></input>
      </label>
      {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
    </div>
  )
}

export { InputFile, MultiInputFile }
