import { axiosInstance } from '.'

import {
  GET_DOCUMENT_ACCESS_TOKEN,
  GET_DOCUMENT_DOWNLOAD_LINK,
  GET_DOCUMENT_SIGNING_LINK,
} from './endpoints'

export const documentServices = {
  getAccessToken: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_DOCUMENT_ACCESS_TOKEN, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  getSigningLink: async (accessToken: string, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_DOCUMENT_SIGNING_LINK, {
        params: { accessToken },
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  getDownloadLink: async (
    accessToken: string,
    documentId: string,
    headers: { [key: string]: string },
  ) => {
    try {
      return await axiosInstance.get(GET_DOCUMENT_DOWNLOAD_LINK, {
        params: { accessToken, documentId },
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
}
