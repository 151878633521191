/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { H2, DeniedIcon, P, Button, Flex } from '../../lib/components'
import './accessdenied.scss'

const AccessDenied = () => {
  return (
    <Flex variant='row-center' classes={['mb-24']}>
      <Flex variant='column-center'>
        <div className='bounce-amimation bounce-1'>
          <DeniedIcon />
        </div>
        <H2 classes={['mb-32 mt-32']} text={'Whoops!'} />
        <P
          text={'You are not authorised to access this resource.Please contact the administrator'}
          classes={['center']}
        />
        <Button
          classes={['auto-btn-width', 'mt-36']}
          variant={'primary-btn'}
          text={'Go back'}
          handleClick={() => {}}
        />
      </Flex>
    </Flex>
  )
}

export { AccessDenied }
