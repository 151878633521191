import React, { useEffect, useState } from 'react'
import { FlexCenter } from '../../lib/components'
import { WelcomePresenter } from './welcome-presenter'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { userServices } from '../../services'
import { Verification } from '../../lib/types'
import { showBanner } from '../../global-state/banner-slice'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
import { EVENTS } from '../../lib/utils/constants'

const WelcomeContainer = () => {
  const { email, isEmailVerified } = useSelector((state: RootState) => state.investor)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isEmailVerifiedValue, setIsEmailVerifiedValue] = useState(isEmailVerified)
  useEffect(() => {
    if (!email || isEmailVerified) {
      navigate('/signup')
    }
  }, [email, isEmailVerified])

  const handleClick = () => navigate('/')

  const handleSendVerificationEmailLink = async () => {
    const response: any =
      !isEmailVerifiedValue &&
      (await userServices.sendVerificationLink(email, Verification.verifyEmail))
    if (!!response && response.status === 200) {
      dispatch(
        showBanner({
          text: 'Email verification link has been sent to email successfully.',
          variant: 'success-banner',
        }),
      )
      setIsEmailVerifiedValue(true)
      navigate('/login')
    } else {
      dispatch(
        showBanner({
          text:
            (response.data && response.data.message) || 'Something went wrong! While verify email',
          variant: 'error-banner',
        }),
      )
    }
  }

  // v1
  const handleSendVerificationEmailLink_v1 = async () => {
    const response: any =
      !isEmailVerifiedValue &&
      (await userServices.sendVerificationLink_v1(email, Verification.verifyEmail))
    if (!!response && response.status === 200) {
      captureEvent(EVENTS.email_verification_link_sent, { email })
      dispatch(
        showBanner({
          text: 'Email verification link has been sent to email successfully.',
          variant: 'success-banner',
        }),
      )
      setIsEmailVerifiedValue(true)
      navigate('/login')
    } else {
      dispatch(
        showBanner({
          text:
            (response.data && response.data.message) || 'Something went wrong! While verify email',
          variant: 'error-banner',
        }),
      )
    }
  }

  return (
    <FlexCenter classes={['p-24']}>
      <WelcomePresenter
        email={email}
        isEmailVerified={isEmailVerifiedValue || isEmailVerified}
        handleClick={handleClick}
        // handleSendVerificationEmailLink={handleSendVerificationEmailLink}
        handleSendVerificationEmailLink={handleSendVerificationEmailLink_v1}
      />
    </FlexCenter>
  )
}

export { WelcomeContainer }
