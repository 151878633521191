import React from 'react'
import './card-component.scss'
import { ICardProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

const Card = ({ classes, children }: ICardProps) => {
  return (
    <>
      <div className={`card-container ${concatClassName(classes)}`}> {children} </div>
    </>
  )
}

export { Card }
