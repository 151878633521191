import React, { useEffect, useState } from 'react'
import './about-us.scss'
import { sanityServices } from '../../services/sanity-service'
import { AboutUsContent, AboutUsStakeHolder } from '../../lib/types'
import { H1, Image, Flex, H2, P, H4, H5 } from '../../lib/components'
import { PortableText, PortableTextBlock, PortableTextComponents } from '@portabletext/react'

const customComponents: Partial<PortableTextComponents> = {
  block: {
    normal: ({ children, ...rest }) => {
      // Checking for new/empty line
      if (
        children &&
        Array.isArray(children) &&
        (children?.length === 0 ||
          children.every((child) => typeof child === 'string' && child.trim() === ''))
      ) {
        return <></>
      }
      return <P text={children as string} classes={['mb-16', 'column-data']} />
    },
  },
}

const AboutUs = () => {
  const [pageContent, setPageContent] = useState<AboutUsContent>({} as AboutUsContent)
  const [missionColumns, setMissionColumns] = useState<PortableTextBlock[][]>([])
  const [visionColumns, setVisionColumns] = useState<PortableTextBlock[][]>([])

  // Hook for fetching page content from CMS
  useEffect(() => {
    const fetchPageContent = async () => {
      const content: AboutUsContent[] = await sanityServices.getAboutUsPage()
      setPageContent(content[0])

      const missionCols = splitIntoColumns(content[0]?.mission)
      setMissionColumns(missionCols)

      const visionCols = splitIntoColumns(content[0]?.vision)
      setVisionColumns(visionCols)
    }

    const splitIntoColumns = (blocks: PortableTextBlock[]) => {
      const totalNodes = blocks.length
      const half = Math.ceil(totalNodes / 2)
      return [blocks.slice(0, half), blocks.slice(half)]
    }

    fetchPageContent()
  }, [])
  return (
    <div className='about-us-main-layout'>
      {/* Heading section */}
      <Flex classes={['row-center', 'no-wrap', 'about-us-main-layout__header-section']}>
        <H1
          text={pageContent?.introText as string}
          classes={['about-us-main-layout__header-section__heading']}
        />
        <Image
          source={pageContent?.heroImageURL}
          classes={['about-us-main-layout__header-section__hero-image']}
        />
      </Flex>

      {/* Team */}
      <Flex classes={['column', 'no-wrap', 'about-us-main-layout__team-section']}>
        <H2 text={pageContent?.directors?.title as string} classes={['center']} />

        <Flex classes={['mt-24', 'border', 'space-around', 'wrap']}>
          {pageContent?.directors?.stakeholderList.map(
            ({ image, name, designation, about }: AboutUsStakeHolder, idx) => {
              return (
                <div
                  key={name + idx}
                  className='border about-us-main-layout__team-section__stakeholder mb-32'
                >
                  <Image source={image} classes={['about-us-main-layout__stakeholder-image']} />
                  <H4 text={name} classes={['mt-12']} />
                  <H5 text={designation} classes={['mt-12']} />
                  <P text={about} classes={['mt-12']} />
                </div>
              )
            },
          )}
        </Flex>
      </Flex>

      {/* Core Values */}
      <Flex classes={['column-center', 'no-wrap', 'about-us-main-layout__values-section']}>
        <H2 text={'Our Values'} />
        <P
          classes={['mt-24', 'center', 'about-us-main-layout__values-section__values']}
          text={pageContent?.coreValues as string}
        />
      </Flex>

      {/* Vision */}
      <Flex classes={['column', 'no-wrap', 'about-us-main-layout__vision-section']}>
        <H2 text={'Our Vision'} classes={['center']} />

        <div className='about-us-main-layout__vision-section__vision-container mt-16'>
          <div className='about-us-main-layout__vision-section__vision-container__column'>
            <PortableText value={visionColumns[0]} components={customComponents} />
          </div>
          <div className='about-us-main-layout__vision-section__vision-container__column'>
            <PortableText value={visionColumns[1]} components={customComponents} />
          </div>
        </div>
      </Flex>

      {/* Mission */}
      <Flex classes={['column', 'no-wrap', 'about-us-main-layout__mission-section']}>
        <H2 text={'Our Mission'} classes={['center']} />

        <div className='about-us-main-layout__mission-section__mission-container mt-16'>
          <div className='about-us-main-layout__mission-section__mission-container__column'>
            <PortableText value={missionColumns[0]} components={customComponents} />
          </div>
          <div className='about-us-main-layout__mission-section__mission-container__column'>
            <PortableText value={missionColumns[1]} components={customComponents} />
          </div>
        </div>
      </Flex>
    </div>
  )
}

export { AboutUs }
