import React from 'react'
import { Anchor } from '../../lib/components'
import './terms-and-conditions.scss'

const TermsAndConditionsContainer = () => {
  return (
    <div className='container terms-and-conditions-container'>
      <h1>Terms and Conditions of Use</h1>
      <br></br>

      <p>
        Credibila (&ldquo;Credibila&rdquo;) is a website operated by Credibila Markets
        (&ldquo;Credibila Markets&rdquo;), bearing Company No. 209658GBC, which is a Global Business
        Company incorporated in Mauritius, and has been granted an Investment Dealer (Full Service
        Dealer, excluding Underwriting) License , having License No. GB24202929 pursuant to Section
        29 of the Securities Act 2005, Rule 4 of the Securities (Licensing ) Rules 2007 and the
        Financial Services (Consolidated Licensing and Fees) Rules, 2008, and is duly regulated by
        the Financial Services Commission, Mauritius.
      </p>

      <br />
      <p>
        By accessing the Website, the pages contained in it, and the information and material
        contained or described herein (together the “Information”), you acknowledge your agreement
        to be bound by, and understanding and acceptance of, these Terms and Conditions of Use, our
        {
          <Anchor
            variant='basic-anchor'
            classes={['ml-4', 'mr-4']}
            href='/privacy_policy'
            text='Privacy Policy'
          />
        }
        and our Confidentiality Agreement.
      </p>

      <br />
      <p>
        You acknowledge your understanding that these Terms and Conditions of Use constitute a
        binding agreement between you and Credibila (sometimes referred to as “we” or “us”) that
        governs your access and use of the Website, which includes any images, text, illustrations,
        designs, icons, photographs, programs, music clips, downloads, systems and methods of
        trading, video clips, graphics, user interfaces, visual interfaces, information, data,
        tools, products, written materials, services and other content (together, ”Content”),
        including but not limited to the design, structure, selection, coordination, expression and
        arrangement of the Content available on or through the Website.
      </p>

      <br />
      <p>
        The Website and the offerings therein are intended only for Clients whose credentials have
        been accepted by the Credibila Team.
      </p>

      <br />
      <p>
        Credibila grants you a limited right to use the Website. The individual user shall not use
        the Website in any way that is fraudulent or unlawful. Your right to use the Website is
        subject to your agreement to abide by the Terms and Conditions of Use in their entirety, as
        well as any other rules, procedures, policies, terms or conditions that govern all or any
        portion of the Website.
      </p>

      <br />
      <p>
        Credibila reserves the right to make changes to the Website and the Terms and Conditions of
        Use at any time without prior notice to you. For this reason, each time you use the Website,
        you should visit and review the then-current Terms and Conditions of Use that apply to your
        use of the Website.
      </p>

      <h2>Client Support</h2>

      <p>
        All questions regarding transactions or your Account can be directed to your account
        manager. Your account manager can be your point of contact to resolve issues and errors
        relating to transactions and your account balance.
      </p>

      <h2>Electronic Fund Transfer Disclosure Statement</h2>

      <p>
        The following disclosures are made in accordance with the applicable laws with respect to
        the electronic fun transfers that are done on this website:
      </p>

      <ul className='mt-8'>
        <li>
          <p>
            <strong>Your Liability - Authorized Transfers:</strong> You are solely liable for all
            the transfers that you authorise whether directly or indirectly.
          </p>
        </li>
        <li>
          <p>
            <strong>Your Liability - Unauthorized Transfers:</strong> Contact us immediately, if you
            believe that you have not authorised a transfer.
          </p>
        </li>
      </ul>

      <h2>Website Security</h2>

      <p>
        You may not violate or attempt to violate the security of the Website. Tampering with any
        portion of the Website, providing untruthful or inaccurate information, misrepresenting your
        identity, or conducting fraudulent activities on the Website, whether or not through the use
        of agents, are prohibited and constitute a breach of these Terms and Conditions of Use.
      </p>

      <br />
      <p>
        We may, without prior notice or warning of any kind, restrict or terminate the access of any
        and all users to the Website if we reasonably conclude that such restriction or termination
        is necessary to prevent, or prevent the further spread, of a virus, security breach or
        system malfunction
      </p>

      <br />
      <p>
        When accessing the Website, users should be aware that the internet is generally not
        regarded as a secure environment, and that data sent via the internet can be accessed by
        unauthorized third parties, potentially leading to disclosures, changes in content or
        technical failures. Data sent via the internet may be transmitted across international
        borders even though both sender and receiver are located in the same country. Credibila does
        not accept any responsibility or liability for the security of data while in transit via the
        internet.
      </p>

      <br />
      <p>
        The Website may contain certain links. Activating links on the Website may cause individual
        users to leave the Website. Such links are provided solely for individual user&apos;s
        convenience and information. Credibila&apos;s not reviewed any of the websites linked with
        or connected to the Website and using links on or to the Website is at each individual
        user&apos;s own risk.
      </p>

      <h2>Ownership of Materials on Website</h2>

      <p>
        You may download or copy Content only to the extent such download is expressly permitted in
        writing on the Website. No right, title or interest in any downloaded materials or software
        is transferred to you as a result of any such downloading or copying.
      </p>

      <br />
      <p>
        You may not reproduce (except as noted above), publish, transmit, distribute, display,
        modify, create derivative works from, sell or participate in any sale of or exploit in any
        way, in whole or in part, any of the Content, the Website or any related software.
      </p>

      <br />
      <p>The compilation of all Contents on the Website is the exclusive property of Credibila</p>

      <br />
      <p>
        Subject to the above, individual users are entitled to view the Information on the Website
        and to copy and print such information for personal use. Individual users are not permitted
        to sell or distribute or otherwise deal with the Information on the Website or any
        deviations of such information without the prior written consent of Credibila.
      </p>

      <h2>Past performance / Forward-looking statements</h2>

      <p>
        Nothing herein shall be relied upon as a promise or representation as to past or future
        performance. The Website may contain forward-looking statements. In some cases, you can
        identify forward-looking statements by terminology such as “may”, “will”, “should”,
        “expects”, “plans”, “anticipates”, “believes”, “targeted”, “projected”, “underwritten”,
        “estimates”, “predicts”, “potential”, or “continue” or the negative of these terms or other
        comparable terminology. These forward-looking statements include, but are not limited to,
        statements concerning the company, property, risk factors, plans and projections.
        Forward-looking statements are subject to various risks and uncertainties. Accordingly,
        there are or will be important factors that could cause actual outcomes or results to differ
        materially from those indicated in these statements.
      </p>

      <h2>No Warranty; Limitation on Liability</h2>

      <p>
        By using the website, you expressly agree that such use is at your sole risk. The website
        and related services are provided on an “as is”, “as available” and “with all faults” basis.
      </p>

      <br />
      <p>
        Neither Credibila nor any of its respective officers, directors, employees, agents,
        third-party content providers, designers, contractors, distributors, merchants, sponsors,
        licensors or the like (collectively, “associates”) warrant that use of the website or
        related services will be uninterrupted or error-free. Neither Credibila nor its associates
        warrant the accuracy, integrity, completeness, availability or timeliness of the content
        provided in the website or the materials or services offered in the website now or in the
        future. Credibila and its associates specifically disclaim all warranties, whether expressed
        or implied, including but not limited to warranties of title, merchantability, fitness for a
        particular purpose or non-infringement of the website, information on the website or the
        results obtained from use of the website or related services. Credibila and its associates
        accepts no responsibility for updating any party of the website or the content therein.
      </p>

      <br />
      <p>
        Under no circumstances will Credibila or its associates be liable to you or anyone else for
        any direct, consequential, incidental, special, exemplary, punitive or other indirect
        damages, including but not limited to lost profits, trading losses, unauthorized access,
        systems failure, communications line failure, internet failure or damages that result from
        use or loss of use of the website, content, inconvenience or delay. This is true even if
        Credibila has been advised of the possibility of such damages or losses.
      </p>

      <h2>Third Party Content and Linked Websites</h2>

      <p>
        References on this Website to any names, marks, products or services of third parties, or
        hypertext links to third party websites or information or Content provided by third parties,
        are provided solely as a convenience to you and do not in any way constitute or imply our
        endorsement, sponsorship or recommendation of the third party, its information, materials or
        services. We are not responsible for the practices or policies of such third parties, nor
        the Content of any third-party sites, and do not make any representations regarding third
        party materials or services, or the Content or accuracy of any material on such third party
        sites. If you decide to link to any such third-party sites, you do so entirely at your own
        risk.
      </p>

      <h2>Warning Regarding Web Fraud and Phishing</h2>

      <p>When communicating with Credibila through digital media, please:</p>
      <ul>
        <li>
          Do not share your password and login ID with anyone. Credibila web sites are private,
          available only to clients through secure log-in procedures. Apart from allowing you to use
          your password and log-in to enter an authorized web site, Credibila will never ask you for
          your password or login information. If you forget your password or login, we will issue
          you new ones.
        </li>
        <li>Confirm you are visiting a Credibila authorized web site.</li>
      </ul>
      <p className='mt-8'>
        If you have any questions about the above, please contact Credibila at
        <Anchor
          variant='basic-anchor'
          classes={['ml-4']}
          href='mailto:support@credibila.com'
          text='support@credibila.com'
        />
      </p>

      <h2>Governing Law</h2>

      <p>
        Headings used in these Terms and Conditions of Use are for reference purposes only and in no
        way define or limit the scope of the section. These Terms and Conditions of Use are governed
        by the laws of Mauritius . Subject to the next section of the Terms and Conditions regarding
        arbitration, any claim related to any dispute arising as a result of the Website or under
        these Terms and Conditions of Use will be made before a court of competent jurisdiction
        located in Mauritius. If any provision of these Terms and Conditions of Use is held to be
        unenforceable, such provision will be reformed only to the extent necessary to make it
        enforceable. The failure of Credibila to act with respect to a breach of these Terms and
        Conditions of Use by you or others does not constitute a waiver and will not limit
        Credibila&apos;s rights with respect to such breach or any subsequent breaches.
      </p>

      <h2>Arbitration</h2>

      <p>
        By using the Website, you agree that Credibila, at its sole discretion, may require you to
        submit any disputes arising from the use of the Website, related services or these Terms and
        Conditions of Use concerning or, including disputes arising from or concerning their
        interpretation, violation, nullity, invalidity, non-performance or termination, as well as
        disputes about filling gaps in this contract or its adaptation to newly arisen
        circumstances, to final and binding arbitration under the International Rules of Arbitration
        of the American Arbitration Association, by one or more arbitrators appointed in accordance
        with the said rules. Notwithstanding these rules, however, such proceeding shall be governed
        by the laws of the state as set forth in the previous section.
      </p>
    </div>
  )
}

export { TermsAndConditionsContainer }
