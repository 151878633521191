import React from 'react'
import './progress-bar-component.scss'
import { ProgressBarProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

const ProgressBar = ({
  variant = 'green-bar',
  value = '0',
  maxValue = '100',
}: ProgressBarProps) => {
  return (
    <>
      <progress className={variant} value={value} max={maxValue}></progress>
    </>
  )
}
export { ProgressBar }
