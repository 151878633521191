import React, { useEffect, useState } from 'react'
import './profile.scss'
import { ProfilePresenter } from './profile-presenter'
import { investorGeneralSchema, investorDetailSchema } from '../../lib/utils/schema'
import { useNavigate } from 'react-router-dom'
import { userServices } from '../../services'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { showBanner } from '../../global-state/banner-slice'

const ProfileContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { authToken, email, acceptedNDA, kycStatus } = useSelector(
    (state: RootState) => state.investor,
  )
  const [isUpdateProfile, setIsUpdateProfile] = useState<boolean>(false)
  const [initialValuesGeneral, setInitialValuesGeneral] = useState({
    name: '',
    countryOfResidence: '',
    email: email,
    phoneNumber: '',
    citizenship: '',
    countryCode: 'US',
    passportNumber: '',
  })

  useEffect(() => {
    const fetchUserProfile = async () => {
      //   const data: any =  await userServices.getUserProfile({ 'Authorization': authToken ? authToken : '' });
      const data: any = await userServices.getUserProfile_v1({
        Authorization: authToken ? authToken : '',
      })
      if (data && data.data && data.status === 200) {
        setInitialValuesGeneral(data.data)
      }
    }
    if (authToken) fetchUserProfile()
    else navigate('/')
    setIsUpdateProfile(false)
  }, [isUpdateProfile])

  const handleGeneralFormSubmit = async (val: any) => {
    // const data: any = await userServices.userProfileUpdate(val, {
    //   Authorization: authToken ? authToken : '',
    // });
    const data: any = await userServices.userProfileUpdate_v1(val, {
      Authorization: authToken ? authToken : '',
    })
    if (!!data && data.status === 200) {
      dispatch(
        showBanner({
          text: 'Profile updated, successfully',
          variant: 'success-banner',
        }),
      )
      setIsUpdateProfile(true)
    } else {
      dispatch(
        showBanner({
          text: data.data.message || 'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
    }
  }
  return (
    <>
      <ProfilePresenter
        initialValues={initialValuesGeneral}
        investorGeneralSchema={investorGeneralSchema}
        handleGeneralFormSubmit={handleGeneralFormSubmit}
        investorDetailSchema={investorDetailSchema}
        acceptedNDA={acceptedNDA}
      />
    </>
  )
}

export { ProfileContainer }
