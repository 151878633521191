import React, { useEffect, useState, useCallback } from 'react'
import { FlexCenter, H2 } from '../../lib/components'
import { ResetPasswordInstructions } from './reset-instructions-presenter'
import { userServices } from '../../services'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { showBanner } from '../../global-state/banner-slice'
import { Verification } from '../../lib/types'

const ResetInstructionsContainer = () => {
  const navigator = useNavigate()

  const handleClick = () => navigator('/login')
  return (
    <FlexCenter classes={['p-24']}>
      <ResetPasswordInstructions handleClick={handleClick} />
    </FlexCenter>
  )
}

export { ResetInstructionsContainer }
