import React from 'react'
import { IconProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

export const GeneralIcon = ({ width = '16', height = '18', classes }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={concatClassName(classes)}
    viewBox='0 0 40 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 36C5.8 36 3.91667 35.2167 2.35 33.65C0.783333 32.0833 0 30.2 0 28C0 25.8 0.783333 23.9167 2.35 22.35C3.91667 20.7833 5.8 20 8 20C10.2 20 12.0833 20.7833 13.65 22.35C15.2167 23.9167 16 25.8 16 28C16 30.2 15.2167 32.0833 13.65 33.65C12.0833 35.2167 10.2 36 8 36ZM20 16C17.8 16 15.9167 15.2167 14.35 13.65C12.7833 12.0833 12 10.2 12 8C12 5.8 12.7833 3.91667 14.35 2.35C15.9167 0.783334 17.8 0 20 0C22.2 0 24.0833 0.783334 25.65 2.35C27.2167 3.91667 28 5.8 28 8C28 10.2 27.2167 12.0833 25.65 13.65C24.0833 15.2167 22.2 16 20 16ZM32 36C29.8 36 27.9167 35.2167 26.35 33.65C24.7833 32.0833 24 30.2 24 28C24 25.8 24.7833 23.9167 26.35 22.35C27.9167 20.7833 29.8 20 32 20C34.2 20 36.0833 20.7833 37.65 22.35C39.2167 23.9167 40 25.8 40 28C40 30.2 39.2167 32.0833 37.65 33.65C36.0833 35.2167 34.2 36 32 36ZM20 12C21.1 12 22.042 11.608 22.826 10.824C23.6087 10.0413 24 9.1 24 8C24 6.9 23.6087 5.95867 22.826 5.176C22.042 4.392 21.1 4 20 4C18.9 4 17.9587 4.392 17.176 5.176C16.392 5.95867 16 6.9 16 8C16 9.1 16.392 10.0413 17.176 10.824C17.9587 11.608 18.9 12 20 12ZM8 32C9.1 32 10.0413 31.6087 10.824 30.826C11.608 30.042 12 29.1 12 28C12 26.9 11.608 25.9587 10.824 25.176C10.0413 24.392 9.1 24 8 24C6.9 24 5.95867 24.392 5.176 25.176C4.392 25.9587 4 26.9 4 28C4 29.1 4.392 30.042 5.176 30.826C5.95867 31.6087 6.9 32 8 32ZM32 32C33.1 32 34.042 31.6087 34.826 30.826C35.6087 30.042 36 29.1 36 28C36 26.9 35.6087 25.9587 34.826 25.176C34.042 24.392 33.1 24 32 24C30.9 24 29.958 24.392 29.174 25.176C28.3913 25.9587 28 26.9 28 28C28 29.1 28.3913 30.042 29.174 30.826C29.958 31.6087 30.9 32 32 32Z'
      fill='#1C1B1F'
    />
  </svg>
)

export const InvestingIcon = ({ width = '16', height = '18', classes }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={concatClassName(classes)}
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.6742 15.75C11.9576 15.2 12.1742 14.621 12.3242 14.013C12.4742 13.4043 12.5492 12.775 12.5492 12.125C12.5492 10.7917 12.2576 9.57067 11.6742 8.462C11.0909 7.354 10.2992 6.43333 9.29922 5.7L6.99922 8V0H14.9992L12.6992 2.3C13.5659 3.08333 14.2576 4.01667 14.7742 5.1C15.2909 6.18333 15.5492 7.35833 15.5492 8.625C15.5492 10.1417 15.1952 11.5167 14.4872 12.75C13.7786 13.9833 12.8409 14.9833 11.6742 15.75ZM0.999219 18L3.29922 15.7C2.41589 14.9167 1.72022 13.9833 1.21222 12.9C0.703552 11.8167 0.449219 10.6417 0.449219 9.375C0.449219 7.85833 0.803552 6.48333 1.51222 5.25C2.22022 4.01667 3.16589 3.01667 4.34922 2.25C4.06589 2.8 3.84522 3.379 3.68722 3.987C3.52855 4.59567 3.44922 5.225 3.44922 5.875C3.44922 7.20833 3.74522 8.429 4.33722 9.537C4.92855 10.6457 5.71589 11.5667 6.69922 12.3L8.99922 10V18H0.999219Z'
      fill='#545A5E'
    />
  </svg>
)

export const PrivacyPolicyIcon = ({ width = '16', height = '18', classes }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={concatClassName(classes)}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V4H2V16ZM4 9V7H14V9H4ZM4 13V11H10V13H4Z'
      fill='#545A5E'
    />
  </svg>
)

export const DividendsIcon = ({ width = '16', height = '18', classes }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={concatClassName(classes)}
    viewBox='0 0 40 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4 38C2.9 38 1.95867 37.6087 1.176 36.826C0.392 36.042 0 35.1 0 34V12C0 10.9 0.392 9.95867 1.176 9.176C1.95867 8.392 2.9 8 4 8H12V4C12 2.9 12.392 1.958 13.176 1.174C13.9587 0.391333 14.9 0 16 0H24C25.1 0 26.042 0.391333 26.826 1.174C27.6087 1.958 28 2.9 28 4V8H36C37.1 8 38.042 8.392 38.826 9.176C39.6087 9.95867 40 10.9 40 12V34C40 35.1 39.6087 36.042 38.826 36.826C38.042 37.6087 37.1 38 36 38H4ZM16 8H24V4H16V8ZM4 34H36V12H4V34Z'
      fill='#1C1B1F'
    />
  </svg>
)
export const KYCIcon = ({ width = '16', height = '18', classes }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={concatClassName(classes)}
    viewBox='0 0 40 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 28C10.9 28 9.95867 27.6087 9.176 26.826C8.392 26.042 8 25.1 8 24V10H12V24H30V28H12ZM20 20C18.9 20 17.9587 19.608 17.176 18.824C16.392 18.0413 16 17.1 16 16V4C16 2.9 16.392 1.958 17.176 1.174C17.9587 0.391333 18.9 0 20 0H36C37.1 0 38.042 0.391333 38.826 1.174C39.6087 1.958 40 2.9 40 4V16C40 17.1 39.6087 18.0413 38.826 18.824C38.042 19.608 37.1 20 36 20H20ZM20 16H36V8H20V16ZM4 36C2.9 36 1.95867 35.6087 1.176 34.826C0.392 34.042 0 33.1 0 32V18H4V32H22V36H4Z'
      fill='#1C1B1F'
    />
  </svg>
)

export const PlatformIcon = ({ width = '16', height = '18', classes }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={concatClassName(classes)}
    viewBox='0 0 40 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4 32C2.9 32 1.95867 31.6087 1.176 30.826C0.392 30.042 0 29.1 0 28V4C0 2.9 0.392 1.95867 1.176 1.176C1.95867 0.392 2.9 0 4 0H36C37.1 0 38.042 0.392 38.826 1.176C39.6087 1.95867 40 2.9 40 4V28C40 29.1 39.6087 30.042 38.826 30.826C38.042 31.6087 37.1 32 36 32H4ZM4 28H12V4H4V28ZM16 28H24V4H16V28ZM28 28H36V4H28V28Z'
      fill='#1C1B1F'
    />
  </svg>
)
