/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { useSelector } from 'react-redux'
import { H2, Button, CheckboxInput, FlexCenter, Flex, P } from '../../lib/components'
import { RootState } from '../../store'
import { AcceptNDAText } from './accept-nda-text'
import './accept-nda.scss'

interface SelfdeclarationProps {
  isButtonDisabled: boolean
  handleChange: (fieldName: string, value: boolean) => void
  handleButtonClick: () => void
  expand: boolean
  acceptedNDA: boolean
  acceptedTNC: boolean
  handleSelfDeclarationSubmit: () => void
}
const AcceptNDAPresenter = React.forwardRef<HTMLDivElement, SelfdeclarationProps>(
  (
    {
      isButtonDisabled,
      handleChange,
      handleButtonClick,
      expand,
      acceptedNDA,
      acceptedTNC,
      handleSelfDeclarationSubmit,
    },
    ref,
  ) => {
    const { name, email } = useSelector((state: RootState) => state.investor)
    return (
      <>
        <div className='self-declaration-container'>
          <div className='self-declaration-heading'>
            <H2 text={'Confidentiality Agreement'} />
            <p>
              Know your Client process for &nbsp;
              <strong>{name?.length > 0 ? name : email}</strong>
            </p>
          </div>
          <div className='self-declaration-sub-heading'>
            <p>
              Read the Confidentiality agreement and confirm the acceptance of our terms of service.
            </p>
          </div>
          <div
            className={`self-declaration-wrapper ${
              expand ? 'self-declaration-wrapper-overflow' : ''
            }`}
            ref={ref}
          >
            {/* <P text={text}/> */}
            <div className='self-declaration-wrapper-text'>
              <AcceptNDAText />
            </div>

            {!expand && (
              <div className='self-declaration-muted-wrapper'>
                <FlexCenter>
                  <Button
                    variant='secondary-btn'
                    classes={['self-declaration-expand-button']}
                    text='+'
                    handleClick={handleButtonClick}
                  />
                </FlexCenter>
              </div>
            )}
          </div>
          <div className='self-declaration-footer'>
            <Flex classes={['mb-16']}>
              <CheckboxInput
                labelText='I accept the terms and conditions in the self declaration'
                name='acceptedTNC'
                value={acceptedTNC ? 'true' : 'false'}
                handleChange={(val) => handleChange('acceptedTNC', val.target.checked)}
              />
            </Flex>
            <Flex classes={['mb-40']}>
              <CheckboxInput
                labelText='I accept to not misuse, resuse the information in any format'
                name='acceptedNDA'
                value={acceptedNDA ? 'true' : 'false'}
                handleChange={(val) => handleChange('acceptedNDA', val.target.checked)}
              />
            </Flex>

            <Flex>
              <Button
                variant='primary-btn'
                classes={['auto-btn-width']}
                text='Accept'
                isDisabled={isButtonDisabled}
                handleClick={handleSelfDeclarationSubmit}
              />
            </Flex>
          </div>
        </div>
      </>
    )
  },
)

AcceptNDAPresenter.displayName = 'AcceptNDAPresenter'
export { AcceptNDAPresenter }
