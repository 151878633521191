/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { P, Flex, Button, NotFoundProjectIcon } from '../../lib/components'
import { useNavigate } from 'react-router-dom'

const NoProjectSection = () => {
  const navigate = useNavigate()
  return (
    <>
      <>
        <Flex classes={['no-project-found-wrapper']}>
          <NotFoundProjectIcon />
          <span> {'Looks like you don’t have any opportunities'} </span>
          <P
            textSize='small'
            variant='default-gray'
            text={'Discover opportunities and invest in one to show up here.'}
          />
          <Button
            classes={['auto-btn-width', 'mt-40']}
            variant={'primary-btn'}
            text={'Discover opportunities'}
            handleClick={() => {
              navigate('/opportunities')
            }}
          />
        </Flex>
      </>
    </>
  )
}

export { NoProjectSection }
