import React from 'react'
import SectionHeader from './section-header'
import { Span } from '../../lib/components'

const ClosedPortfolio = ({ averageReturn, currentInvestmentValue, investments }: any) => {
  return (
    <div className='dashboard-container__closed-portfolio-section'>
      <SectionHeader
        sectionName='Closed Portfolio'
        sectionHelperValues={[
          { name: 'Avg Return', value: averageReturn },
          { name: 'Current Investment Value*', value: currentInvestmentValue },
        ]}
      />
      <Span text='' variant='thin'>
        You currently have <Span text={`${investments.length} closed`} variant='bold' /> investments
      </Span>
    </div>
  )
}

export { ClosedPortfolio }
