import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexCenter } from '../../lib/components'
import { SavedProjectsPresenter } from './saved-projects-presenter'
import { SearchParams } from '../../lib/types'
import { projectServices } from '../../services'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { showBanner } from '../../global-state/banner-slice'
import { setPreviousUrl } from '../../lib/utils/helpers'

function SavedProjectsContainer() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { authToken, isEmailVerified, acceptedNDA } = useSelector(
    (state: RootState) => state.investor,
  )
  const [isSaveForLater, setIsSaveForLater] = useState(false)
  const [projects, setProjects] = useState([])
  const [searchParams, setSearchParams] = useState<SearchParams>({
    limit: '5',
    offset: '0',
    sortBy: 'CLOSING_DATE_DESC',
    filterBy: 'isSaved',
  })
  const [pagination, setPagination] = useState({
    total: 20,
    limit: 5,
    count: 5,
    offset: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const fetchAllProjects = async () => {
      const data: any = await projectServices.getSearchProject(searchParams, {
        Authorization: authToken ? authToken : '',
      })
      if (data.status === 200) {
        setProjects(data.projects || [])
        setPagination(data.pagination)
      }
    }
    fetchAllProjects()
  }, [currentPage, searchParams])

  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    const { limit, offset } = pagination
    setSearchParams({
      ...searchParams,
      offset: '0',
      limit: limit.toString(),
      [name]: value,
    })
    setCurrentPage(1)
    setPagination({ ...pagination, offset: 0 })
  }

  const resetSearchParams = async () => {
    setSearchParams({
      limit: '5',
      offset: '0',
      sortBy: 'CLOSING_DATE_DESC',
      keyword: '',
      filterBy: 'isSaved',
    })
  }

  const handleCurrentPage = (currentPage: number) => {
    const { limit } = pagination
    setCurrentPage(currentPage)
    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })
    setPagination({ ...pagination, offset: (currentPage - 1) * limit })
  }

  const handleProjectClick = (projectSlug: string, id: number) => {
    if (!!authToken && isEmailVerified) {
      if (!acceptedNDA) {
        setPreviousUrl(`/opportunity/${projectSlug}/${id}`)
      } else setPreviousUrl('')
      navigate(`/opportunity/${projectSlug}/${id}`)
    } else if (!!authToken && !isEmailVerified) {
      dispatch(
        showBanner({
          text: 'Please verify your email address to view the opportunity details.',
          variant: 'warning-banner',
        }),
      )
    } else {
      navigate('/login')
    }
  }

  return (
    <FlexCenter>
      <SavedProjectsPresenter
        pagination={pagination}
        handleCurrentPage={handleCurrentPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        resetSearchParams={resetSearchParams}
        searchParams={searchParams}
        handleSearchParams={handleSearchParams}
        projects={projects}
        handleProjectClick={handleProjectClick}
      />
    </FlexCenter>
  )
}

export { SavedProjectsContainer }
