import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import './tool-tip-component.scss'
import { ToolTipProps } from '../../types'

const ToolTip = ({ content, children, clickable = false }: ToolTipProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const showToolTip = () => {
    setIsOpen(true)
  }

  const closeToolTip = () => {
    setIsOpen(false)
  }

  const toolTipContent = () => {
    return <div className={'tool-tip-container'}>{content}</div>
  }

  return (
    <>
      {clickable ? (
        <div className={'pointer width-fit-content'} onClick={() => setIsOpen(!isOpen)}>
          <Popover
            isOpen={isOpen}
            positions={['top', 'bottom', 'right', 'left']}
            content={toolTipContent}
            onClickOutside={() => setIsOpen(false)}
          >
            {children}
          </Popover>
        </div>
      ) : (
        <div
          className={'pointer width-fit-content'}
          onMouseOver={showToolTip}
          onMouseLeave={closeToolTip}
        >
          <Popover
            isOpen={isOpen}
            positions={['top', 'bottom', 'right', 'left']}
            content={toolTipContent}
          >
            {children}
          </Popover>
        </div>
      )}
    </>
  )
}

export { ToolTip }
