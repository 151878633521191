import React from 'react'
import './flex-component.scss'
import { FlexProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

const Flex = ({ children, variant = 'row', classes, onClick }: FlexProps) => {
  return (
    <>
      {
        <div onClick={onClick} className={`flex ${variant} ${concatClassName(classes)}`}>
          {' '}
          {children}{' '}
        </div>
      }
    </>
  )
}
export { Flex }

const FlexCenter = ({ children, classes, onClick }: FlexProps) => {
  const classNames = classes && [...classes, 'vh']
  return (
    <Flex onClick={onClick} variant='row-center' classes={classNames}>
      <Flex variant='column-center'>{children}</Flex>
    </Flex>
  )
}
export { FlexCenter }
