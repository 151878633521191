import React from 'react'
import './project-card-component.scss'
import { Tag } from '../Tag'
import { Line } from '../Line'
import { ProjectListProps, LastMinuteListProps } from '../../types'
import { calculateRemainingDays } from '../../utils/helpers'
import { H4 } from '../Heading'
import { Anchor } from '../Anchor'
import { captureEvent } from '../../utils/posthogUtils/usePostHog'
import { EVENTS } from '../../utils/constants'

function ProjectCard({
  id,
  projectSlug,
  projectName,
  assetClass,
  commitedPercentage,
  isHighCommitenceProject,
  projectSummary,
  irr,
  ticketSize,
  projectCover,
  handleClick,
  closingDate,
}: ProjectListProps) {
  return (
    <div
      key={id}
      className='project-card-container'
      tabIndex={0}
      onClick={() => {
        captureEvent(EVENTS.PROJECT_VIEWED, {
          projectSlug,
          id,
          projectName,
          assetClass,
          ticketSize,
          projectCover,
          closingDate,
        })
        handleClick && handleClick(projectSlug, id)
      }}
    >
      <img
        src={projectCover}
        width={412}
        height={220}
        alt='project-display-image'
        style={{ objectFit: 'cover' }}
      />
      <div className='project-card-container-wrapper card-content'>
        <div className='flex space-between'>
          <div className='title-container'>
            <h2 className='title'>{projectName}</h2>
            <div className='tags mt-12'>{assetClass && <Tag text={assetClass} />}</div>
          </div>
          <div className='title-container right'>
            <p>
              <span>{commitedPercentage}</span> committed
            </p>
            <p
              className={
                closingDate && calculateRemainingDays(new Date(), new Date(closingDate)) > 0
                  ? 'mt-16'
                  : 'mt-16 closed-for-investment'
              }
            >
              {closingDate && calculateRemainingDays(new Date(), new Date(closingDate)) > 0
                ? `${
                    closingDate && calculateRemainingDays(new Date(), new Date(closingDate))
                  } days left`
                : 'Closed for investment'}
            </p>
          </div>
        </div>
        <Line />
        <div className='lower-container'>
          <div className='description-container'>
            <p>{projectSummary}</p>
          </div>
          <div className='stats-container'>
            <div className='stats-card'>
              <p className='card-main'>{irr}</p>
              <p className='card-sub'>IRR</p>
            </div>
            <div className='stats-card'>
              <p className='card-main'>{ticketSize}</p>
              <p className='card-sub'>Ticket size</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ProjectCard }

function ProjectCardHorizontal({
  id,
  projectSlug,
  projectName,
  commitedPercentage,
  projectSummary,
  projectCover,
  handleClick,
  closingDate,
}: LastMinuteListProps) {
  return (
    <div
      className='project-h-container'
      onClick={() => {
        captureEvent(EVENTS.PROJECT_VIEWED, { projectSlug, id, projectName })
        handleClick && handleClick(projectSlug, id)
      }}
    >
      <div className='project-h-image'>
        <img src={projectCover} alt='' />
      </div>
      <div className='project-h-content'>
        <div className='project-h-text'>
          <H4 text={projectName} />
          <p>
            {projectSummary} &nbsp;{' '}
            <Anchor
              text='know more'
              href={`/opportunity/${projectSlug}/${id}`}
              variant='basic-anchor'
            />
          </p>
        </div>
        <div className='project-h-stats'>
          <p>
            <strong>{commitedPercentage}</strong> committed
          </p>
          <p>
            {closingDate && calculateRemainingDays(new Date(), new Date(closingDate))}{' '}
            <strong>days</strong>
          </p>
        </div>
      </div>
    </div>
  )
}

export { ProjectCardHorizontal }
