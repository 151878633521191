import { axiosInstance } from '.'
import {
  USER_CREATE,
  USER_SIGN_IN,
  GOOGLE_SIGN_IN,
  VERIFY_EMAIL,
  SEND_VERIFICATION_LINK,
  RESET_PASSWORD,
  USER_LOGOUT,
  UPDATE_PROFILE,
  GET_PROFILE,
  LOGGED_IN_USER,
  USER_CREATE_V1,
  SEND_VERIFICATION_LINK_V1,
  RESET_PASSWORD_V1,
  USER_SIGN_IN_V1,
  VERIFY_EMAIL_V1,
  USER_LOGOUT_V1,
  GET_PROFILE_V1,
  LOGGED_IN_USER_V1,
  UPDATE_PROFILE_V1,
  GET_SESSION_STATUS,
  RESET_PASSWORD_TOKEN_VALID,
  GOOGLE_SIGN_IN_V1,
} from './endpoints'
import { NewUser, User, Verification } from '../lib/types'
import { removeEmptyValues } from '../lib/utils/helpers'
import { parsePhoneNumber } from 'react-phone-number-input'

export const userServices = {
  userSignUp: async (userData: NewUser, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(USER_CREATE, userData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
  userLogin: async (loginData: User, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(USER_SIGN_IN, loginData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
  googleSignIn: async (loginData: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(GOOGLE_SIGN_IN, loginData)
    } catch (error: any) {
      return error.response
    }
  },
  verifyEmail: async (code: string, user: string, type: Verification) => {
    try {
      return await axiosInstance.get(VERIFY_EMAIL(code, user, type))
    } catch (error: any) {
      return error.response
    }
  },
  sendVerificationLink: async (email: string, type: Verification) => {
    try {
      return await axiosInstance.post(SEND_VERIFICATION_LINK, { email, type })
    } catch (error: any) {
      return error.response
    }
  },
  getIsResetPasswordLinkValid: async (userId: string, code: string) => {
    try {
      return await axiosInstance.get(RESET_PASSWORD_TOKEN_VALID(code, userId))
    } catch (error: any) {
      console.log(error.response)
      return error.response
    }
  },
  resetPassword: async (resetPasswordData: object, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(RESET_PASSWORD, resetPasswordData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
  getProfileForCurrentUser: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(LOGGED_IN_USER, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },
  userLogout: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(USER_LOGOUT, {}, { headers: headers })
    } catch (error: any) {
      return error.response
    }
  },
  userProfileUpdate: async (profileData: any, headers: { [key: string]: string }) => {
    try {
      if ('email' in profileData) delete profileData['email']
      let payload = removeEmptyValues(profileData)
      if (profileData.phoneNumber) {
        const phoneNumber = profileData.phoneNumber && parsePhoneNumber(profileData.phoneNumber)
        payload = { ...payload, countryCode: phoneNumber?.country }
      }
      const data = await axiosInstance.post(UPDATE_PROFILE, payload, {
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },
  getUserProfile: async (headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_PROFILE, { headers: headers })
      const profileData = {
        citizenship: data.data.citizenship || '',
        countryCode: data.data.countryCode || '',
        countryOfResidence: data.data.countryOfResidence || '',
        email: data.data.email || '',
        name: data.data.name || '',
        passportNumber: data.data.passportNumber || '',
        phoneNumber: data.data.phoneNumber || '',
      }
      return { data: profileData, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },

  // v1
  userSignUp_v1: async (userData: NewUser, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(USER_CREATE_V1, userData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  verifyEmail_v1: async (code: string, user: string, type: Verification) => {
    try {
      return await axiosInstance.get(VERIFY_EMAIL_V1(code, user, type))
    } catch (error: any) {
      return error.response
    }
  },

  sendVerificationLink_v1: async (email: string, type: Verification) => {
    try {
      return await axiosInstance.post(SEND_VERIFICATION_LINK_V1, {
        email,
        type,
      })
    } catch (error: any) {
      return error.response
    }
  },

  resetPassword_v1: async (resetPasswordData: object, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(RESET_PASSWORD_V1, resetPasswordData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  userLogin_v1: async (
    loginData: User & { role: 'INVESTOR' },
    headers: { [key: string]: string },
  ) => {
    try {
      return await axiosInstance.post(USER_SIGN_IN_V1, loginData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  userLogout_v1: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(USER_LOGOUT_V1, {}, { headers: headers })
    } catch (error: any) {
      return error.response
    }
  },

  getProfileForCurrentUser_v1: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(LOGGED_IN_USER_V1, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  getUserProfile_v1: async (headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_PROFILE_V1, {
        headers: headers,
      })
      const profileData = {
        countryOfResidence: data.data.country ?? '',
        email: data.data.email ?? '',
        name: data.data.name ?? '',
        phoneNumber: data.data.phone ?? '',
      }
      return { data: profileData, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },

  userProfileUpdate_v1: async (profileData: any, headers: { [key: string]: string }) => {
    try {
      if ('email' in profileData) delete profileData['email']
      const sanitizedData = removeEmptyValues(profileData)
      const payload = {
        name: sanitizedData.name,
        phone: sanitizedData.phoneNumber,
        country: sanitizedData.countryOfResidence,
        address: sanitizedData.address,
      }
      const data = await axiosInstance.patch(UPDATE_PROFILE_V1, payload, {
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },

  getSession: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_SESSION_STATUS, { headers: headers })
    } catch (error: any) {
      return error.response
    }
  },

  googleSignIn_v1: async (loginData: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(GOOGLE_SIGN_IN_V1, loginData)
    } catch (error: any) {
      return error.response
    }
  },
}
