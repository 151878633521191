import React from 'react'
import './video.scss'
import { concatClassName } from '../../utils/helpers'

interface VideoComponentProps {
  url: string
  height?: number | string
  width?: number | string
  classes?: string[]
}

function Video({ url, classes }: VideoComponentProps) {
  return (
    <video className={`${concatClassName(classes)}`} muted autoPlay loop>
      <source src={url} type='video/mp4' />
      Your browser does not support HTML video.
    </video>
  )
}

export { Video }
