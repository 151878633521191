/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { InvestmentCard, SearchIcon, Input, SelectDropdown, Button } from '../../lib/components'
import { trimText } from '../../lib/utils/helpers'
import { DashboardInfoSection } from './info-section'
import { NoProjectSection } from './no-project-section'
import { dashboardFilters, dashboardSort } from '../../lib/utils/message'
import { useBreakpoint } from '../../lib/utils/hooks'
import { SM } from '../../lib/utils/constants'
import { Pagination } from '../../lib/components/Pagination'

const DashboardPresenter = ({
  email,
  name,
  kycStatus,
  userInvestmentDetail,
  investments,
  expectedIRR,
  totalInvestments,
  handleSearchParams,
  searchParams,
  resetSearchParams,
  currentPage,
  pagination,
  handleCurrentPage,
  quarterlyReport,
  handleFilePreview,
  handleStepperForInvestment,
}: any) => {
  const navigate = useNavigate()
  const { breakpoint } = useBreakpoint()

  return (
    <>
      <DashboardInfoSection
        name={name}
        email={email}
        kycStatus={kycStatus}
        userInvestmentDetail={userInvestmentDetail}
        totalInvestments={totalInvestments}
        expectedIRR={expectedIRR}
        quarterlyReport={quarterlyReport}
        handleFilePreview={handleFilePreview}
      />

      <div className='toolbar-layout gap-12 dashboard-toolbar-layout'>
        <div className='search-input'>
          <span className='search-input-icon'>
            <SearchIcon />
          </span>
          <Input
            name='keyword'
            value={searchParams.keyword}
            handleChange={(e) => handleSearchParams({ name: e.target.name, value: e.target.value })}
            placeholder='Search'
          />
        </div>
        <SelectDropdown
          name='filterBy'
          id='filterId'
          formId=''
          list={dashboardFilters}
          value={searchParams.filterBy}
          placeholder={'Filter'}
          handleChange={(val) => handleSearchParams({ name: 'filterBy', value: val })}
        />
        <div className='sort-reset'>
          <SelectDropdown
            name='sortBy'
            id='sortById'
            formId=''
            list={dashboardSort}
            value={searchParams.sortBy}
            handleChange={(val) => handleSearchParams({ name: 'sortBy', value: val })}
          />
          <Button
            handleClick={() => resetSearchParams()}
            classes={['reset-btn']}
            variant='secondary-btn'
            text='Reset'
          />
        </div>
      </div>

      {investments.length === 0 && <NoProjectSection />}

      {investments.length > 0 &&
        investments.map((investment: any) => {
          const {
            id,
            opportunity_id,
            opportunity_slug,
            asset_class,
            currency_code,
            amount,
            paymentCopy,
            agreement,
            opportunity_title,
            status,
            opportunity_summary,
            investment_return,
            ticket_size,
            images,
            fundAllocation,
            cover_image,
          } = investment
          return (
            <InvestmentCard
              key={id}
              projectCover={cover_image ?? images[0]}
              projectName={opportunity_title}
              projectSummary={trimText(opportunity_summary, 200)}
              investmentStatus={status}
              investmentAmount={amount}
              irr={investment_return}
              ticketSize={ticket_size}
              currencyCode={currency_code}
              assetClass={asset_class}
              agreement={agreement}
              paymentCopy={paymentCopy}
              fundAllocation={fundAllocation}
              projectSlug={opportunity_slug}
              projectId={opportunity_id}
              handleProjectClick={() => {
                navigate(`/opportunity/${opportunity_slug}/${opportunity_id}`)
              }}
              activeStep={handleStepperForInvestment(investment)}
            />
          )
        })}
      {(breakpoint && breakpoint <= SM) || investments.length === 0 ? null : (
        <div className='mt-40'>
          <div className='projects-pagination-wrapper'>
            <div className='projects-pagination-wrapper-info'>
              Showing {pagination.offset + 1} - {pagination.offset + pagination.count} of total{' '}
              {pagination.total} results.
            </div>
            <Pagination
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
              pagination={pagination}
            />
          </div>
        </div>
      )}
    </>
  )
}

export { DashboardPresenter }
