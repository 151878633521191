import { createClient } from '@sanity/client'

const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true,
})

export const sanityServices = {
  getAllArticles: async () => {
    try {
      const groqQuery = `*[_type == "article" && isPublished == true] {
        title,
        "slug": slug.current,
        createdAt,
        content
      }`

      const results = await client.fetch(groqQuery)
      return results
    } catch (error) {
      console.error('Error fetching all articles:', error)
      throw new Error('Failed to fetch all articles')
    }
  },
  getFeaturedArticles: async () => {
    try {
      const groqQuery = `*[_type == "article" && isPublished == true && "featured" in tags] {
        title,
        "slug": slug.current,
        createdAt,
        content,
        shortSummary
      }`

      const results = await client.fetch(groqQuery)
      return results
    } catch (error) {
      console.error('Error fetching featured articles:', error)
      throw new Error('Failed to fetch featured articles')
    }
  },
  searchArticles: async (query: string) => {
    try {
      const groqQuery = `*[_type == "article" && isPublished == true && 
        (title match $query || content[].children[].text match $query)
        ] {
        title,
        "slug": slug.current,
        content,
        shortSummary
      }`

      const params = { query: `${query}*` }

      const results = await client.fetch(groqQuery, params)
      return results
    } catch (error: any) {
      console.error('Error fetching search results:', error)
      throw new Error('Failed to fetch search results')
    }
  },
  getArticleById: async (id: string) => {
    try {
      const groqQuery = `*[_type == "article" && isPublished == true && 
        slug.current == $slug
        ] {
        title,
        content,
        createdAt
      }`

      const params = { slug: `${id}` }

      const results = await client.fetch(groqQuery, params)
      return results
    } catch (error: any) {
      console.error('Error fetching search results:', error)
      throw new Error('Failed to fetch search results')
    }
  },
  getAboutUsPage: async () => {
    try {
      const groqQuery = `*[_type == "about-us"] {
        introText,
        "heroImageURL": heroImage.asset->url,
        story,
        coreValues,
        directors{
          title,
          stakeholderList[]{
            "image": image.asset->url,
            name,
            designation,
            about
          },
        },
        
        mission,
        vision
      }`

      const results = await client.fetch(groqQuery)
      return results
    } catch (error) {
      console.error('Error fetching about us page content:', error)
      throw new Error('Failed to fetch about us page content')
    }
  },
}
