import React, { useState } from 'react'
import './contact-us.scss'
import { ContactUsIFormProps, InvestorContactUsFormProps_v1 } from '../../lib/types'
import {
  H3,
  Flex,
  Input,
  SelectDropdown,
  TextboxInput,
  MultiInputFile,
  Button,
} from '../../lib/components'
import { CONTACT_US_ASSISTANCE_CATEGORIES as ASSISTANCE_CATEGORIES } from '../../lib/utils/constants'
import { Formik, FormikProps } from 'formik'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { getError } from '../../lib/utils/helpers'

const ContactUsPresenter = ({
  initialValues,
  validationSchema,
  handleFormSubmit,
  reRef,
  maxSize,
}: ContactUsIFormProps<InvestorContactUsFormProps_v1>) => {
  const [isValidPhoneNumberValue, setIsValidPhoneNumberValue] = useState(true)

  return (
    <Flex classes={['contact-us-main-layout__form']}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(formik) => {
          const {
            values,
            touched,
            errors,
            dirty,
            handleChange,
            setFieldValue,
            handleSubmit,
          }: FormikProps<typeof initialValues> = formik
          return (
            <form>
              <Flex>
                <div className='field-container'>
                  <Input
                    name='name'
                    labelText='Name*'
                    placeholder='Full Name'
                    type='text'
                    required
                    value={values.name}
                    handleChange={(e) => {
                      setFieldValue('name', e.target.value)
                    }}
                    dirty={dirty}
                    errors={{
                      name: !!errors && errors.name ? errors.name : null,
                    }}
                    touched={{
                      name: !!touched && !!touched.name,
                    }}
                  />
                </div>
              </Flex>
              <Flex classes={['mt-16', 'column']}>
                <div className='input-label'> Email*</div>
                <span className='general-input-info mb-8'>
                  Please provide the email address associated with your Credibila account
                </span>
                <Input
                  name='email'
                  placeholder='Email Address'
                  type='text'
                  required
                  value={values.email}
                  handleChange={(e) => {
                    setFieldValue('email', e.target.value)
                  }}
                  dirty={dirty}
                  errors={{
                    email: !!errors && errors.email ? errors.email : null,
                  }}
                  touched={{
                    email: !!touched && !!touched.email,
                  }}
                />
              </Flex>
              <Flex classes={['mt-16', 'column']}>
                <div className='input-label'> Phone*</div>
                <PhoneInput
                  international
                  placeholder='Phone number'
                  value={values.phone}
                  onChange={(val: any) => {
                    if (val && isValidPhoneNumber(val)) {
                      setFieldValue('phone', val)
                      setIsValidPhoneNumberValue(true)
                    } else setIsValidPhoneNumberValue(false)
                  }}
                />
                {(!isValidPhoneNumberValue || errors.phone) && (
                  <span className='general-input-error mt-8'> Invalid Mobile Number </span>
                )}
              </Flex>
              <Flex classes={['mt-16', 'column']}>
                <div className='input-label'> Category*</div>
                <span className='general-input-info mt-4'> What can we assist you with? </span>
                <SelectDropdown
                  name='assistance_category'
                  id='assistance_categoryId'
                  formId=''
                  list={ASSISTANCE_CATEGORIES}
                  value={values.assistance_category}
                  handleChange={(val: any) => setFieldValue('assistance_category', val)}
                  dirty={dirty}
                  errors={{
                    assistance_category:
                      !!errors && errors.assistance_category ? errors.assistance_category : null,
                  }}
                  touched={{
                    assistance_category: !!touched && !!touched.assistance_category,
                  }}
                />
              </Flex>
              <Flex classes={['mt-16', 'column']}>
                <div className='input-label'> Subject*</div>
                <span className='general-input-info mb-8'>
                  Please enter a brief description of your inquiry.
                </span>
                <Input
                  name='subject'
                  placeholder='Subject'
                  type='text'
                  required
                  value={values.subject}
                  handleChange={(e) => {
                    setFieldValue('subject', e.target.value)
                  }}
                  dirty={dirty}
                  errors={{
                    subject: !!errors && errors.subject ? errors.subject : null,
                  }}
                  touched={{
                    subject: !!touched && !!touched.subject,
                  }}
                />
              </Flex>
              <Flex classes={['mt-16', 'column']}>
                <div className='input-label'> Message*</div>
                <span className='general-input-info mb-8'>
                  Please enter a detailed description of your inquiry.
                </span>
                <TextboxInput
                  name='message'
                  placeholder='Message'
                  required
                  value={values.message}
                  handleChange={(e) => {
                    setFieldValue('message', e.target.value)
                  }}
                  dirty={dirty}
                  errors={{
                    message: !!errors && errors.message ? errors.message : null,
                  }}
                  touched={{
                    message: !!touched && !!touched.message,
                  }}
                />
              </Flex>
              <Flex classes={['mt-16', 'column']}>
                <div className='input-label'> Attachments</div>
                <span className='general-input-info mb-8'>
                  Please attach any photos or documents related to your inquiry below (10 files max,
                  total file size must be less than {maxSize}MB)
                </span>
                <MultiInputFile
                  name='contactUsAttachments'
                  type={['image', 'pdf', 'xls', 'doc']}
                  limit={10}
                  maxSizeInMB={maxSize}
                  handleFiles={async (files: any) => {
                    if (files) {
                      setFieldValue('attachments', files)
                    }
                  }}
                  label=''
                />
              </Flex>

              <Flex variant='flex-end' classes={['mt-40']}>
                <div className='project-form-save-button-wrapper'>
                  <Flex>
                    <Button
                      classes={['auto-btn-width']}
                      variant={'primary-btn'}
                      text='Submit'
                      handleClick={handleSubmit}
                    />
                  </Flex>
                  <div className='contact-us-form-error-message-wrapper mt-24'>
                    {Object.entries(errors).map((data, index) => (
                      <span className='error-basic-label' key={index}>
                        {getError(data[1])}
                      </span>
                    ))}
                  </div>
                </div>
              </Flex>
            </form>
          )
        }}
      </Formik>
    </Flex>
  )
}

export { ContactUsPresenter }
