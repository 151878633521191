import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { IGoogleReCaptchaProps } from '../../types'

const V2_SITE_KEY = process.env.REACT_APP_SITE_KEY_V2 || '6LeJerYpAAAAAFezIHdP9U3_8Cjou_DQlJ1ujSVQ'
const V3_SITE_KEY = process.env.REACT_APP_SITE_KEY_V3 || '6LeJerYpAAAAAFezIHdP9U3_8Cjou_DQlJ1ujSVQ'

const GoogleReCaptcha = ({
  handleChange,
  handleExpired,
  reCaptchaRef,
  size = 'normal',
  version = 'v3',
}: IGoogleReCaptchaProps) => {
  return (
    //I used my personal credentials like sitekey 6LcREdQgAAAAAL6e-UR51EyvBYoQFBN5MoQtQ0hZ for testing ReCaptcha
    // set this sitekey into .env file
    <ReCAPTCHA
      sitekey={version === 'v3' ? V3_SITE_KEY : V2_SITE_KEY}
      onChange={handleChange}
      size={size}
      onExpired={handleExpired}
      ref={reCaptchaRef}
    />
  )
}

export { GoogleReCaptcha }
