/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { Span, Flex, Card, H4, H2, Line, Button } from '../../lib/components'

export const MFA = () => {
  const appName = 'Authy'
  const phoneNumber = '1234'
  const recoveryEmail = 'test@gmail.com'
  const recoveryPhoneNumber = '45678'
  const forgotPassword = false
  //for forgot password, the user has to enter to follow this step and then goto reset password page to enter the new password.
  //for signin the user will do this step after the signin.
  return (
    <Flex variant='column-center'>
      <H2 text={'2-Step Verification'} classes={['mb-32']} />
      <Card classes={['p-40']}>
        <Span
          text={
            'To help keep your account safe, Credibila Team wants to make sure that it’s really you trying to sign in'
          }
          classes={['center']}
        />
        <H4 text={'Choose how you want to sign in:'} classes={['mb-32', 'mt-32']} />
        <Flex classes={['pointer']} onClick={() => {}}>
          Get a verification code from the &nbsp;<b>{appName}</b>
        </Flex>
        <Line />
        <Flex classes={['pointer']} onClick={() => {}}>
          Get a verification code at &nbsp;<b>{phoneNumber}</b>
        </Flex>
        <Line />
        {forgotPassword && (
          <>
            <Flex classes={['pointer']} onClick={() => {}}>
              Get a verification code at &nbsp;<b>{recoveryPhoneNumber}</b>
            </Flex>
            <Line />
            <Flex classes={['pointer']} onClick={() => {}}>
              Get a verification code at &nbsp;<b>{recoveryEmail}</b>
            </Flex>
            <Line />
          </>
        )}
        <Flex classes={['pointer']} onClick={() => {}}>
          Enter your 8 digit backup code{' '}
        </Flex>
        <Line />
        <Flex classes={['pointer']} variant='row-center'>
          {' '}
          <Button
            text='Back to login'
            classes={['mt-16']}
            variant='primary-btn'
            handleClick={() => {}}
          />{' '}
        </Flex>
        <Line />
      </Card>
    </Flex>
  )
}
