import React from 'react'
import './heading-component.scss'
import { IHeadingProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

const H1 = ({ text, classes, id }: IHeadingProps) => {
  return (
    <h1 id={id || ''} className={`h1-heading ${concatClassName(classes)}`}>
      {text}
    </h1>
  )
}

const H2 = ({ text, classes, id }: IHeadingProps) => {
  return (
    <h2 id={id || ''} className={`h2-heading ${concatClassName(classes)}`}>
      {' '}
      {text}{' '}
    </h2>
  )
}

const H3 = ({ text, classes, id }: IHeadingProps) => {
  return (
    <h3 id={id || ''} className={`h3-heading ${concatClassName(classes)}`}>
      {' '}
      {text}{' '}
    </h3>
  )
}

const H4 = ({ text, classes, id }: IHeadingProps) => {
  return (
    <h4 id={id || ''} className={`h4-heading ${concatClassName(classes)}`}>
      {' '}
      {text}{' '}
    </h4>
  )
}

const H5 = ({ text, classes, id }: IHeadingProps) => {
  return (
    <h5 id={id || ''} className={`h5-heading ${concatClassName(classes)}`}>
      {' '}
      {text}{' '}
    </h5>
  )
}

const H6 = ({ text, classes, id }: IHeadingProps) => {
  return (
    <h6 id={id || ''} className={`h6-heading ${concatClassName(classes)}`}>
      {' '}
      {text}{' '}
    </h6>
  )
}

export { H1, H2, H3, H4, H5, H6 }
