/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  Button,
  Flex,
  CTABanner,
  ApprovedIcon,
  DeniedIcon,
  PendingIcon,
  RefreshIcon,
} from '../../lib/components'
import { RootState } from '../../store'
import { KYCProps } from '../../lib/types'
import { CONTACT_US, GET_IN_TOUCH_WITH_ADMIN, QUESTION_FOR_KYC } from '../../lib/utils/message'
import { ContactUsModal } from '../../lib/components/ContactUsModal'
import { SumSub } from './kyc-sumsub'
import './kyc.scss'

const KYCPresenter = ({ kycStatusData, getKYC }: KYCProps) => {
  const { name, email } = useSelector((state: RootState) => state.investor)
  const params = useParams()
  const { profileTab } = params
  const [showContactUsModal, setShowContactUsModal] = useState(false)
  const handleClickModal = () => {
    setShowContactUsModal((state) => !state)
  }

  const RotatingButton = () => {
    const [isRotating, setIsRotating] = useState(false)

    const handleClick = () => {
      setIsRotating(true)

      getKYC().then(() => {
        setIsRotating(false)
      })
    }

    return (
      <button
        style={{
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '20px',
        }}
        onClick={handleClick}
      >
        <RefreshIcon classes={[isRotating ? 'rotate' : '']} />
      </button>
    )
  }

  return (
    <>
      <div className={profileTab === 'kyc' ? 'kyc-container-profile' : 'kyc-container'}>
        {profileTab === 'kyc' && (
          <div className='kyc-status-container'>
            <span className='kyc-status-container-label'>Your current status</span>
            <span className={`kyc-status-container-status-${kycStatusData}`}>
              {kycStatusData === 'NOT_STARTED' && (
                <>
                  <PendingIcon height='21' width='21' /> Not Started
                </>
              )}
              {kycStatusData === 'INITIATED' && (
                <>
                  <PendingIcon height='21' width='21' /> Initiated
                </>
              )}
              {kycStatusData === 'PENDING' && (
                <>
                  <PendingIcon height='21' width='21' /> Sent For Approval
                </>
              )}
              {kycStatusData === 'REJECTED' && (
                <>
                  <DeniedIcon height='21' width='21' /> Rejected
                </>
              )}
              {kycStatusData === 'RESUBMIT' && (
                <>
                  <DeniedIcon height='21' width='21' /> Resubmit
                </>
              )}
              {kycStatusData === 'APPROVED' && (
                <>
                  <ApprovedIcon height='21' width='21' /> Approved
                </>
              )}
              {kycStatusData !== 'APPROVED' && <RotatingButton />}
            </span>
          </div>
        )}
        <div className='kyc-heading'>
          <p className='kyc-sub-heading-text'>
            Know your Client process for &nbsp; <strong>{name?.length > 0 ? name : email}</strong>
          </p>
        </div>
        <SumSub kycStatus={kycStatusData} />

        <p className='kyc-sub-heading-text mt-28 mb-16'>
          Note: We currently accept individual investors only. If you are a corporate entity or
          institution, please contact your relationship manager or reach out to us via the Contact
          Us button below.
        </p>

        <div className='kyc-footer'>
          <Flex variant='row-center' classes={['mt-10']}>
            <CTABanner
              heading={QUESTION_FOR_KYC}
              subHeading={GET_IN_TOUCH_WITH_ADMIN}
              ButtonComponent={
                <Button
                  classes={['auto-btn-width']}
                  variant='primary-btn'
                  text={CONTACT_US}
                  handleClick={handleClickModal}
                />
              }
            />
          </Flex>
          {showContactUsModal ? (
            <ContactUsModal active={true} subject={'General'} handleOnClose={handleClickModal} />
          ) : null}
        </div>
      </div>
    </>
  )
}

export { KYCPresenter }
