import React from 'react'
import { IStatusProps } from '../../types'
import './status-label-component.scss'

const StatusLabel = ({ type, text, variant = 'basic', IconComponent }: IStatusProps) => {
  return (
    <div className='vertical-center'>
      {IconComponent && IconComponent}
      <span
        className={
          IconComponent ? `${type}-${variant}-label  margin-left` : `${type}-${variant}-label`
        }
      >
        {' '}
        {text}{' '}
      </span>
    </div>
  )
}

export { StatusLabel }
