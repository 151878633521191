import React from 'react'
import { FlexCenter } from '../../lib/components'
import { ForgotPasswordPresenter } from './forgot-password-presenter'
import { emailInputSchema } from '../../lib/utils/schema'
import { userServices } from '../../services'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showBanner } from '../../global-state/banner-slice'
import { Verification } from '../../lib/types'
import './forgot-password.scss'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
import { EVENTS } from '../../lib/utils/constants'

const initialValues = {
  email: '',
}

const ForgotPasswordContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = async (val: any) => {
    const response: any = await userServices.sendVerificationLink(
      val.email,
      Verification.resetPassword,
    )
    if (!!response && response.status === 200) {
      dispatch(
        showBanner({
          text: 'Instruction to reset password has been sent to your email.',
          variant: 'success-banner',
        }),
      )
      navigate('/reset-instructions')
    } else {
      dispatch(
        showBanner({
          text:
            (response.data && response.data.message) || 'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
    }
  }

  // v1
  const handleSubmit_v1 = async (val: any) => {
    const response: any = await userServices.sendVerificationLink_v1(
      val.email,
      Verification.resetPassword,
    )
    if (!!response && response.status === 200) {
      captureEvent(EVENTS.reset_password_link_sent, { email: val.email })
      dispatch(
        showBanner({
          text: 'Instruction to reset password has been sent to your email.',
          variant: 'success-banner',
        }),
      )
      navigate('/reset-instructions')
    } else {
      dispatch(
        showBanner({
          text:
            (response.data && response.data.message) || 'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
    }
  }

  return (
    <FlexCenter variant={'column-center'} classes={['mt-0 center-in-page-container']}>
      <ForgotPasswordPresenter
        initialValues={initialValues}
        // handleFormSubmit={handleSubmit}
        handleFormSubmit={handleSubmit_v1}
        validationSchema={emailInputSchema}
      />
    </FlexCenter>
  )
}

export { ForgotPasswordContainer }
