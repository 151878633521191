import React from 'react'
import {
  displayDollar,
  displayPercentage,
  displayReturnsTitle,
  displayStatus,
} from '../../lib/utils/helpers'
import { H4, Anchor, Flex } from '../../lib/components'
import { ASSET_CLASSES } from '../../lib/utils/constants'
import { useNavigate } from 'react-router-dom'

const DashboardOpportunityCard = (props: any) => {
  const navigate = useNavigate()
  return (
    <div className='dashboard-opportunity-card' onClick={props.handleClick}>
      <div className='dashboard-opportunity-card__row-wrapper'>
        {/* Opportunity image, title and summary section */}
        <div className='dashboard-opportunity-card__row-wrapper__opportunity-details-container'>
          <div className='dashboard-opportunity-card__row-wrapper__opportunity-details-container__image'>
            <img src={props.opportunityCover} alt='opportunity cover' />
          </div>
          <div className='dashboard-opportunity-card__row-wrapper__opportunity-details-container__content'>
            <div className='dashboard-opportunity-card__row-wrapper__opportunity-details-container__text'>
              <H4 text={props.opportunityName} />
              <p>{props.opportunitySummary} &nbsp; </p>
            </div>
          </div>
        </div>

        {/* Opportunity stats section */}
        <div className='dashboard-opportunity-card__row-wrapper'>
          <div className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container'>
            {props?.assetClass !== ASSET_CLASSES.LISTED_EQUITIES && props.irr !== '0.00' && (
              <div className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container__stats-card'>
                <p className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container__stats-card__main'>
                  {displayPercentage(props.irr)}
                </p>
                <p className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container__stats-card__sub'>
                  {displayReturnsTitle(props.assetClass)}
                </p>
              </div>
            )}
            <div className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container__stats-card'>
              <p className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container__stats-card__main'>
                {displayDollar(props.raiseGoal, props.currencyCode)}
              </p>
              <p className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container__stats-card__sub'>
                Raise Goal
              </p>
            </div>
            <div className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container__stats-card'>
              <p className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container__stats-card__main'>
                {displayDollar(props.ticketSize, props.currencyCode)}
              </p>
              <p className='dashboard-opportunity-card__row-wrapper__opportunity-stats-container__stats-card__sub'>
                Ticket Size
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { DashboardOpportunityCard }
