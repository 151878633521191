/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import './payment.scss'
import { PaymentPresenter } from './payment-presenter'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { projectServices, investServices } from '../../services'
import { getFileKeyFromSignedUrl } from '../../lib/utils/helpers'
import { showBanner } from '../../global-state/banner-slice'

const PaymentContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { projectSlug, projectId, paymentPage } = params
  const { authToken } = useSelector((state: RootState) => state.investor)
  const [project, setProject] = useState()
  const [investmentData, setInvestmentData] = useState<any>()
  const [showContactUsModal, setContactUsModal] = useState(false)

  useEffect(() => {
    const fetchProjectById = async () => {
      // const data: any =  await projectServices.getProjectById( projectId as string,{ 'Authorization': authToken ? authToken : '' });
      const data: any = await projectServices.getOpportunityById(projectId as string, {
        Authorization: authToken ? authToken : '',
      })
      if (data && data.data && data.status === 200) {
        setProject(data.data)
      }
    }
    const getUserInvestmentLatest = async () => {
      // const response: any = await InvestServices.getInvestmentLatest({ projectId: projectId ? parseInt(projectId) : 0 },{
      const response: any = await investServices.getInvestmentLatest_v1(
        { opportunityId: projectId ? parseInt(projectId) : 0 },
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (response && response.data && response.status === 200) {
        setInvestmentData(response.data)
      }
    }

    if (projectId) {
      fetchProjectById()
      getUserInvestmentLatest()
    }
  }, [projectId])

  const handleViewSummary = () => {
    navigate(`/payment/${projectSlug}/${projectId}/payment-summary`)
  }

  const handlePrintPaymentSummary = () => {
    window.print()
  }
  const handleShowContactUsModal = () => setContactUsModal(!showContactUsModal)

  const handleFilePreview = async (fileKey: string) => {
    const signedUrl = getFileKeyFromSignedUrl(fileKey)
    const data = await projectServices.previewFile(signedUrl, {
      Authorization: authToken ? authToken : '',
    })
    if (data && data.data && data.status === 200) return data.data.url
    else {
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong preview document',
          variant: 'error-banner',
        }),
      )
      return ''
    }
  }

  return (
    <>
      {project && (
        <PaymentPresenter
          project={project}
          paymentPage={(paymentPage && paymentPage) || ''}
          handlePrintPaymentSummary={handlePrintPaymentSummary}
          handleViewSummary={handleViewSummary}
          investmentData={investmentData}
          showContactUsModal={showContactUsModal}
          handleShowContactUsModal={handleShowContactUsModal}
          handleFilePreview={handleFilePreview}
        />
      )}
    </>
  )
}

export { PaymentContainer }
