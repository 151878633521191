import { configureStore } from '@reduxjs/toolkit'
import { bannerSlice } from './global-state/banner-slice'
import { investorSlice } from './global-state/investor-slice'
import { confirmModelSlice } from './global-state/confirm-model-slice'
const store = configureStore({
  reducer: {
    investor: investorSlice.reducer,
    banner: bannerSlice.reducer,
    confirmModel: confirmModelSlice.reducer,
  },
})
export { store }
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
