import React, { useEffect, useRef, useState } from 'react'
import {
  Span,
  KYCIcon,
  DividendsIcon,
  InvestingIcon,
  PrivacyPolicyIcon,
  PlatformIcon,
  GeneralIcon,
  Anchor,
} from '../../lib/components'

const SCROLL_TRIGGER_DELAY = 500
const SCROLL_TRIGGER_THRESHOLD = 0.5
const SCROLL_INTERVAL = 20
const SCROLL_BY_PIXEL = 1
const benefits = [
  {
    title: 'Am I eligible to invest?',
    icon: <InvestingIcon width='36' height='36' />,
    href: '/Investing',
    content: (
      <>
        Whether you are an individual or institution, you may be eligible to invest. Once you
        register, we ask for a few documents to verify your identity and background. This is called
        a
        <Anchor variant='basic-anchor' classes={['ml-4']} href='/faq-details/General' text='KYC' />
        (Know your Client). Once you complete your KYC we will let you know your eligibility. It
        only takes a few minutes to register and complete your KYC. So what are you waiting for? Go
        grab that
        <Anchor
          variant='basic-anchor'
          classes={['ml-4']}
          href='/opportunities'
          text='Opportunity!'
        />
      </>
    ),
  },
  {
    title: 'Can I withdraw from an investment at any time?',
    icon: <DividendsIcon width='36' height='36' />,
    href: '/Dividends',
    content: (
      <>
        You can withdraw from an investment at anytime prior to transferring the funds towards your
        investment. Post transfer of funds, you may request our team or your relationship manager.
        Once your investment is complete, you may still withdraw from the investment by opting for
        redemption. Please check the redemption conditions on the
        <Anchor
          variant='basic-anchor'
          classes={['ml-4']}
          href='/opportunities'
          text='Opportunity '
        />
        page for more details.,
      </>
    ),
  },
  {
    title: 'How long does it take to process a KYC?',
    icon: <KYCIcon width='36' height='36' />,
    subTitle:
      'Most of our KYC process is automated so we strive to complete the process within 4 business hours of you submitting the application.\
    In case more information is required it could take longer but we keep you proactively informed throughout the process.',
  },
  {
    title: 'Do I have to accept the terms & condition?',
    icon: <PrivacyPolicyIcon width='36' height='36' />,
    href: '/Investing',
    subTitle:
      'Yes, You have to accept the terms & condition also accept the NDA for every opportunity. \
    We have made this process simple and seamless using technology so its easy and quick.',
  },
  {
    title: 'Can I invest in multiple opportunities?',
    icon: <GeneralIcon width='36' height='36' />,
    href: '/Dividends',
    content: (
      <>
        Yes, You can invest in multiple opportunities as long as you are within your
        <Anchor
          variant='basic-anchor'
          classes={['ml-4']}
          href='/faq-details/Investing'
          text='Investment Limit'
        />
        .
      </>
    ),
  },
  {
    title: 'How long does it take to process the investment?',
    icon: <PlatformIcon width='36' height='36' />,
    subTitle:
      'Once you complete the investment application and transfer the funds, the Credibila team will review and confirm your investment within 6 business hours. If we need more information, it might take a bit longer, but we\'ll keep you updated via your registered email.',
  },
]

const LearnMoreSection = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [shouldScroll, setShouldScroll] = useState<boolean>(false)

  useEffect(() => {
    const container = containerRef.current

    if (container) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !isHovered) {
              if (entry.intersectionRatio >= SCROLL_TRIGGER_THRESHOLD) {
                setTimeout(() => {
                  setShouldScroll(true)
                }, SCROLL_TRIGGER_DELAY)
              }
            } else {
              setShouldScroll(false)
            }
          })
        },

        {
          root: null,
          threshold: SCROLL_TRIGGER_THRESHOLD,
        },
      )

      if (container) {
        observer.observe(container)
      }

      return () => {
        observer.disconnect()
      }
    }
  }, [isHovered])

  useEffect(() => {
    const container = containerRef.current
    let interval: NodeJS.Timeout | null = null

    if (container && shouldScroll) {
      interval = setInterval(() => {
        if (!isHovered) {
          container.scrollBy({
            left: SCROLL_BY_PIXEL,
            behavior: 'smooth',
          })
        }
      }, SCROLL_INTERVAL)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [shouldScroll, isHovered])

  return (
    <div
      ref={containerRef}
      className='flex benefit-cards-container'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ scrollBehavior: isHovered ? 'auto' : 'smooth' }}
    >
      {benefits &&
        benefits.map(({ title, icon, subTitle, content }, index) => (
          <div key={title + index} className='benefit-cards'>
            <div className='benefit-cards-icon'>{icon}</div>
            <Span variant='bold' classes={['benefit-cards-heading']} text={title} />
            {subTitle ? (
              <Span classes={['benefit-cards-subheading']} text={subTitle} />
            ) : (
              <Span classes={['benefit-cards-subheading']} text={''}>
                {content}
              </Span>
            )}
          </div>
        ))}
    </div>
  )
}

export default LearnMoreSection
