import React, { useEffect } from 'react'
import { gapi } from 'gapi-script'
import { GoogleLogin } from 'react-google-login'

import { Button, GoogleIcon } from '../../lib/components'
import { GLoginProps } from '../../lib/types'

const client_Id = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''

export const GLogin = ({
  clientId = client_Id,
  onSuccess,
  onFailure,
  cookiePolicy = 'single_host_origin',
  buttonText = 'Continue with google',
}: GLoginProps) => {
  useEffect(() => {
    gapi.load('client:auth2', () => gapi.client.init({}))
  })
  return (
    <GoogleLogin
      clientId={clientId}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={cookiePolicy}
      render={(renderProp) => (
        <Button
          classes={['mb-16']}
          text={buttonText}
          variant='basic-btn'
          handleClick={renderProp.onClick}
          IconComponent={<GoogleIcon />}
          iconVariant='left'
          isDisabled={renderProp.disabled}
        />
      )}
    />
  )
}
