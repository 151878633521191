import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch, bannerState } from '../lib/types'

const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    show: false,
    text: '',
    variant: undefined,
    duration: 3000,
  },
  reducers: {
    showBanner: (state: bannerState, action: { payload: any }) => {
      state.show = true
      state.text = action.payload.text
      state.variant = action.payload.variant || 'warning-banner'
      state.duration = action.payload.duration || 3000
    },
    hideBanner: (state: bannerState) => {
      state.show = false
      state.text = ''
      state.variant = undefined
    },
  },
})
export const showBannerTimeout = (duration: number) => (dispatch: AppDispatch) => {
  setTimeout(() => {
    dispatch(hideBanner())
  }, duration)
}
export { bannerSlice }
export const { showBanner, hideBanner } = bannerSlice.actions
