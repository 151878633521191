import React from 'react'
import { PortableText, PortableTextComponents, PortableTextBlock } from '@portabletext/react'
import { Anchor, H1, H2, H3, H4, H5, H6, P } from '../'

interface RichTextRendererProps {
  content: PortableTextBlock[]
  variant?: 'FAQ' | 'help-center'
}

const FAQComponents: Partial<PortableTextComponents> = {
  block: {
    h1: ({ children }) => <H1 text={children as string} />,
    h2: ({ children }) => <H2 text={children as string} />,
    h3: ({ children }) => <H3 text={children as string} />,
    h4: ({ children }) => <H4 text={children as string} />,
    h5: ({ children }) => <H5 text={children as string} />,
    h6: ({ children }) => <H6 text={children as string} />,
    normal: ({ children }) => {
      // Checking for new/empty line
      if (
        children &&
        Array.isArray(children) &&
        (children?.length === 0 ||
          children.every((child) => typeof child === 'string' && child.trim() === ''))
      ) {
        return <br />
      }
      return <>{children}</>
    },
  },
  marks: {
    link: ({ children, value }) => {
      return (
        <a href={value.href} target='_blank' rel='noreferrer'>
          {children}
        </a>
      )
    },
  },
  list: {
    bullet: ({ children }) => <ul className='ml-16'>{children}</ul>,
    number: ({ children }) => <ol className='ml-16'>{children}</ol>,
  },
  listItem: ({ children }) => (
    <li>
      <div className={'ml-4'}>{children}</div>
    </li>
  ),
}

const HelpCenterComponents: Partial<PortableTextComponents> = {
  block: {
    h1: ({ children }) => <H1 text={children as string} />,
    h2: ({ children }) => <H2 text={children as string} />,
    h3: ({ children }) => <H3 text={children as string} />,
    h4: ({ children }) => <H4 text={children as string} />,
    h5: ({ children }) => <H5 text={children as string} />,
    h6: ({ children }) => <H6 text={children as string} />,
    normal: ({ children }) => {
      // Checking for new/empty line
      if (
        children &&
        Array.isArray(children) &&
        (children?.length === 0 ||
          children.every((child) => typeof child === 'string' && child.trim() === ''))
      ) {
        return <br />
      }
      return <P text={children as string} />
    },
  },
  marks: {
    link: ({ children, value }) => {
      return (
        <Anchor
          variant='basic-anchor'
          text={children as string}
          href={value.href}
          target='_blank'
        />
      )
    },
  },
  list: {
    bullet: ({ children }) => <ul className='ml-16'>{children}</ul>,
    number: ({ children }) => <ol className='ml-16'>{children}</ol>,
  },
  listItem: ({ children }) => (
    <li>
      <P text={children as string} classes={['ml-4']} />
    </li>
  ),
}

const RichTextRenderer = ({ content, variant }: RichTextRendererProps) => {
  let customComponents

  switch (variant) {
    case 'FAQ':
      customComponents = FAQComponents
      break
    case 'help-center':
      customComponents = HelpCenterComponents
      break
    default:
      customComponents = {}
      break
  }

  return <PortableText value={content} components={customComponents} />
}

export { RichTextRenderer }
