import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../lib/components'
import { kycServices } from '../../services'
import { RootState } from '../../store'

interface SumSubProps {
  kycStatus: string | undefined
}

const SumSub = ({ kycStatus }: SumSubProps) => {
  const { authToken, userId } = useSelector((state: RootState) => state.investor)
  const [SumsubExternalLink, setSumsubExternalLink] = useState('')
  const [KYCLinkText, setKYCLinkText] = useState('Start')

  useEffect(() => {
    const fetchToken = async () => {
      const response: any = await kycServices.generateSumsubSDKLink(
        {
          Authorization: authToken ? authToken : '',
        },
        userId,
        'INDIVIDUAL',
      )
      const { url } = response.data
      setSumsubExternalLink(url)
    }
    if (kycStatus === 'NOT_STARTED') {
      setKYCLinkText('Start')
      fetchToken()
    } else if (kycStatus === 'INITIATED') {
      setKYCLinkText('Continue')
      fetchToken()
    } else if (kycStatus === 'RESUBMIT') {
      setKYCLinkText('Continue')
      fetchToken()
    }
  }, [kycStatus])

  const handleButtonClick = () => {
    window.open(SumsubExternalLink, '_blank')
  }

  if (['PENDING', 'APPROVED', 'REJECTED'].includes(kycStatus ?? 'NOT_STARTED')) {
    return <></>
  }

  return (
    <div>
      <div>
        <p className='kyc-sub-heading-text mb-16'>
          During your KYC, you can always leave anytime and return from here. Your progress is saved
          so you continue from where you left.
        </p>
      </div>
      <Button
        classes={['auto-btn-width']}
        variant='primary-btn'
        text={KYCLinkText}
        handleClick={handleButtonClick}
      />
    </div>
  )
}

export { SumSub }
