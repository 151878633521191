import React from 'react'
import { H4, Flex, Card, P, InfoIcon } from '../../lib/components'
import { TERMS } from '../../lib/utils/message'
import { Badge } from '../../lib/components/Badge'

export interface OpportunityTerm {
  level1:
    | {
        header: string
        termObjects: Array<{ title: string; content: string }>
      }
    | undefined
  level2:
    | {
        header: string
        termObjects: Array<{ title: string; content: string }>
      }
    | undefined
}

interface TermCardProps {
  fieldName: string
  value: string
}

const TermCard = ({ fieldName, value }: TermCardProps) => (
  <Card classes={['term-card', 'mr-8']}>
    <Flex variant='row'>
      <P text={fieldName} classes={['mr-8', 'text-align-left']} />
      <InfoIcon />
    </Flex>
    <H4 text={value} />
  </Card>
)

const TermListItem = ({ fieldName, value }: TermCardProps) => {
  return (
    <li className='mb-16'>
      <P text={`${fieldName}: ${value}`} classes={['mr-8', 'text-align-left', 'default-gray']} />
    </li>
  )
}

const Terms = ({ level1, level2 }: OpportunityTerm) => {
  return (
    <Flex variant='column' classes={['mt-72']}>
      <H4 text={TERMS} id='terms' />
      <Flex variant='column' classes={['ml-16']}>
        <ul className='mt-32 ml-24'>
          {level1 && level1.header?.length > 0 && (
            <>
              {level1.termObjects.map(({ title, content }, idx) => (
                <TermListItem key={idx} fieldName={title} value={content} />
              ))}
            </>
          )}
          {level2 && level2.header?.length > 0 && (
            <>
              {level2.termObjects.map(({ title, content }, idx) => (
                <TermListItem key={idx} fieldName={title} value={content} />
              ))}
            </>
          )}
        </ul>
      </Flex>
    </Flex>
  )
}

export { Terms }
