import React, { useEffect, useState } from 'react'
import './dashboard.scss'
import { useNavigate } from 'react-router-dom'
import { projectServices, investServices, kycServices } from '../../services'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { showBanner } from '../../global-state/banner-slice'
import { DashboardPresenter } from './dashboard-presenter'
import { getFileKeyFromSignedUrl } from '../../lib/utils/helpers'
import { updateConfiguration } from '../../global-state/investor-slice'

const DashboardContainer = () => {
  const dispatch = useDispatch()
  const { authToken, email, name, kycStatus, userId } = useSelector(
    (state: RootState) => state.investor,
  )
  const [userInvestmentDetail, setUserInvestmentDetail] = useState<any>([])
  const [investments, setInvestments] = useState<any>([])
  const [expectedIRR, setExpectedIRR] = useState<any>()
  const [totalInvested, setTotalInvested] = useState<any>()
  const [totalInvestments, setTotalInvestments] = useState<any>()
  const [kycStatusData, setKycStatusData] = useState(kycStatus)
  const [searchParams, setSearchParams] = useState<any>({
    limit: '5',
    offset: '0',
    sortBy: 'CREATED_AT_DESC',
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    total: 10,
    limit: 5,
    count: 5,
    offset: 0,
  })
  const [quarterlyReport, setQuarterlyReport] = useState([])

  useEffect(() => {
    const getUserInvestments = async () => {
      // const response: any = await InvestServices.getUserInvestmentDetail({
      //   Authorization: authToken ? authToken : '',
      // });
      const response: any = await investServices.getUserInvestmentDetail_v1({
        Authorization: authToken ? authToken : '',
      })
      if (response && response.data && response.status === 200) {
        setUserInvestmentDetail(response.data)
      }
    }
    const getUserInvestmentsList = async () => {
      // const response = await InvestServices.searchInvestments(
      //   { ...searchParams, userId },
      //   {
      //     Authorization: authToken ? authToken : '',
      //   }
      // );
      const response = await investServices.searchInvestments_v1(
        {
          ...searchParams,
          investor_id: userId,
          opportunity_id: searchParams.projectId,
        },
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (response.status === 200) {
        setInvestments(response.investments)
        setPagination({
          ...pagination,
          total: response.total,
          limit: response.limit,
          count: response.count,
          offset: response.offset,
        })
      }
    }

    const getUserInvestmentMetrics = async () => {
      // const response = await InvestServices.getInvestmentMetrics({
      //   Authorization: authToken ? authToken : '',
      // })
      const response = await investServices.getInvestmentMetrics_v1({
        Authorization: authToken ? authToken : '',
      })
      if (response.status === 200 && response.data) {
        setTotalInvestments(response.data.no_of_investments)
        setTotalInvested(response.data.total_invested_amount)
        setExpectedIRR(response.data.expected_return)
      }
    }

    const getInvestmentQuarterlyReport = async () => {
      const response = await investServices.getInvestmentQuarterlyReport(
        {
          investorId: userId,
        },
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (response.status === 200 && response.data) {
        setQuarterlyReport(response.data)
      }
    }
    const getKyc = async () => {
      // const response: any = await kycServices.getKYC(userId.toString(), {
      //   Authorization: authToken ? authToken : '',
      // });
      const response: any = await kycServices.getKYC_v1(userId.toString(), {
        Authorization: authToken ? authToken : '',
      })

      if (!!response && response.data) {
        dispatch(
          updateConfiguration({
            name: 'kycStatus',
            value: response.data.status,
          }),
        )
        dispatch(
          updateConfiguration({
            name: 'acceptedNDA',
            value: response.data.is_nda_accepted,
          }),
        )
        setKycStatusData(response.data.status ? response.data.status : '')
      }
    }
    if (authToken && userId) {
      getUserInvestmentsList()
      getUserInvestments()
      getUserInvestmentMetrics()
      getInvestmentQuarterlyReport()
      getKyc()
    }
  }, [])

  useEffect(() => {
    const getUserInvestmentsList = async () => {
      // const response = await InvestServices.searchInvestments(
      //   { ...searchParams, userId },
      //   {
      //     Authorization: authToken ? authToken : '',
      //   }
      // );
      const response = await investServices.searchInvestments_v1(
        {
          ...searchParams,
          investor_id: userId,
          opportunity_id: searchParams.projectId,
        },
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (response.status === 200) {
        setInvestments(response.investments)
        setPagination({
          ...pagination,
          total: response.total,
          limit: response.limit,
          count: response.count,
          offset: response.offset,
        })
      }
    }

    if (authToken) getUserInvestmentsList()
  }, [currentPage, searchParams])

  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    const { limit, offset } = pagination
    setSearchParams({
      ...searchParams,
      offset: '0',
      limit: limit.toString(),
      [name]: value,
    })
    setCurrentPage(1)
    setPagination({ ...pagination, offset: 0 })
  }

  const resetSearchParams = async () => {
    setSearchParams({
      limit: '5',
      offset: '0',
      sortBy: 'CREATED_AT_DESC',
      filterBy: '',
      keyword: '',
    })
  }

  const handleCurrentPage = (currentPage: number) => {
    const { limit } = pagination
    setCurrentPage(currentPage)
    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })
    setPagination({ ...pagination, offset: (currentPage - 1) * limit })
  }

  const handleFilePreview = async (fileKey: string) => {
    const signedUrl = getFileKeyFromSignedUrl(fileKey)
    const data = await projectServices.previewFile(signedUrl, {
      Authorization: authToken ? authToken : '',
    })
    if (data && data.data && data.status === 200) return data.data.url
    else {
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong preview document',
          variant: 'error-banner',
        }),
      )
      return ''
    }
  }

  const handleStepperForInvestment = (investment: any): number => {
    const INVESTMENT_AGREEMENT = 1
    const PAYMENT_CONFIRMATION = 2
    const INVESTMENT_REVIEW = 3
    const INVESTMENT_APPROVAL = 4

    switch (investment.metadata?.completedSteps) {
      case PAYMENT_CONFIRMATION:
        return PAYMENT_CONFIRMATION
      case INVESTMENT_REVIEW:
        return investment.status === 'APPROVED' ? INVESTMENT_APPROVAL : INVESTMENT_REVIEW
      default:
        return INVESTMENT_AGREEMENT
    }
  }

  return (
    <>
      <DashboardPresenter
        name={name}
        email={email}
        kycStatus={kycStatusData}
        userInvestmentDetail={userInvestmentDetail}
        investments={investments}
        expectedIRR={expectedIRR}
        totalInvested={totalInvested}
        totalInvestments={totalInvestments}
        handleSearchParams={handleSearchParams}
        searchParams={searchParams}
        resetSearchParams={resetSearchParams}
        currentPage={currentPage}
        pagination={pagination}
        handleCurrentPage={handleCurrentPage}
        quarterlyReport={quarterlyReport}
        handleFilePreview={handleFilePreview}
        handleStepperForInvestment={handleStepperForInvestment}
      />
    </>
  )
}

export { DashboardContainer }
