import React from 'react'
import { H4, H6, Flex, Card, P, FileIcon, AnchorLabel, Anchor } from '../../lib/components'
import { DOCUMENTS } from '../../lib/utils/message'
import { truncateString } from '../../lib/utils/helpers'
interface DocumentsProps {
  documents: {
    title: string
    url: string
  }[]
}

const Documents = ({ documents }: DocumentsProps) => {
  return (
    <Flex variant='column' classes={['mt-72']}>
      <H4 text={DOCUMENTS} id='documents' />
      <div>
        <Flex variant='row' classes={['mt-40', 'mr-40', 'ml-32', 'space-between', 'wrap']}>
          {documents.map(({ title, url }, idx) => (
            <Flex key={idx} variant='row' classes={['row-center', 'mr-50', 'mb-16']}>
              <FileIcon classes={['mr-13']} />
              <Anchor
                variant='basic-anchor'
                text={truncateString(title ?? `Document ${idx + 1}`, 80)}
                target='_blank'
                href={url}
                classes={['opportunity-detail-document-anchor']}
              />
            </Flex>
          ))}
        </Flex>
      </div>
    </Flex>
  )
}

export { Documents }
