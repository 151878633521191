import React from 'react'
import { P } from '..'
import './stepper.scss'
import { BasicStepperProps } from '../../types'
import { MD } from '../../utils/constants'
import { useBreakpoint } from '../../utils/hooks'

export const Stepper = ({ steps }: { steps: { counter: string; desc?: string }[] }) => (
  <div className='stepper-wrapper'>
    {steps.map(({ counter, desc }) => (
      <div className='stepper-item' key={counter}>
        <p className='step-counter'>{counter} </p>
        <P classes={['step-name']} text={desc ? desc : ''} />
      </div>
    ))}
  </div>
)

const StepperStep = ({
  step,
  title,
  activeStep,
  showStatus,
  isMobileView = false,
  isFirst = false,
  isLast = false,
}: {
  activeStep: number
  step: number
  title?: string
  showStatus?: boolean
  isMobileView?: boolean
  isFirst?: boolean
  isLast?: boolean
}) => {
  let classType = 'basic-step-counter'
  if (step < activeStep) classType = 'basic-step-counter completed'
  if (step === activeStep) classType = 'basic-step-counter active'
  return (
    <div
      className={`basic-stepper-item ${isMobileView ? 'stepper-item-mobile' : ''} ${
        isFirst ? 'first-stepper-item' : ''
      } ${isLast ? 'last-stepper-item' : ''}
      `}
    >
      <P classes={[classType]} text={''} />
      <P
        classes={
          showStatus && step > activeStep
            ? ['basic-step-name inactive-status']
            : ['basic-step-name']
        }
        text={title ?? ''}
      />
      {showStatus && (
        <span
          className={
            step <= activeStep ? 'basic-stepper-status' : 'basic-stepper-status inactive-status'
          }
        >
          {' '}
          {step === activeStep
            ? 'IN PROGRESS'
            : step < activeStep
            ? 'COMPLETED'
            : 'NOT STARTED'}{' '}
        </span>
      )}
    </div>
  )
}

export const BasicStepper = ({ steps, activeStep, showStatus = false }: BasicStepperProps) => {
  const { breakpoint } = useBreakpoint()
  return (
    <div className='basic-stepper-wrapper'>
      {breakpoint &&
        breakpoint > MD &&
        steps.map(({ step, title }) => (
          <StepperStep
            key={step}
            step={step}
            title={title}
            activeStep={activeStep}
            showStatus={showStatus}
          />
        ))}
      {breakpoint && breakpoint <= MD && (
        <StepperStep
          step={steps[activeStep - 1].step}
          title={steps[activeStep - 1].title}
          activeStep={activeStep}
          showStatus={showStatus}
          isMobileView={true}
          isFirst={activeStep - 1 === 0}
          isLast={activeStep === steps.length}
        />
      )}
    </div>
  )
}
