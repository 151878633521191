import React, { useEffect, useState } from 'react'
import './payment.scss'
import { PaymentUploadDocument } from './upload-document'
import { PaymentSubmittedSuccessful } from './submit-successful'
import { PaymentSummary } from './summary'
import { ContactUsModal } from '../../lib/components/ContactUsModal'

const PaymentPresenter = ({
  handlePrintPaymentSummary,
  project,
  paymentPage,
  handleViewSummary,
  investmentData,
  showContactUsModal,
  handleShowContactUsModal,
  handleFilePreview,
}: any) => {
  return (
    <>
      {paymentPage === 'upload-document' && <PaymentUploadDocument project={project} />}
      {paymentPage === 'submitted-successful' && (
        <PaymentSubmittedSuccessful handleViewSummary={handleViewSummary} />
      )}
      {paymentPage === 'payment-summary' && (
        <PaymentSummary
          handlePrintPaymentSummary={handlePrintPaymentSummary}
          project={project}
          investmentData={investmentData}
          handleShowContactUsModal={handleShowContactUsModal}
          handleFilePreview={handleFilePreview}
        />
      )}
      {showContactUsModal ? (
        <ContactUsModal
          active={true}
          subject={'Inquiry'}
          handleOnClose={handleShowContactUsModal}
        />
      ) : null}
    </>
  )
}

export { PaymentPresenter }
