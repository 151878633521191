/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import { H1, Tabs, Tab, Flex } from '../../lib/components'
import { GeneralForm } from './general-form'
import { InvestorDetailForm } from './investor-detail-form'
import { KYCContainer } from '../KYC/kyc-container'
import { useNavigate, useParams } from 'react-router-dom'
import { setPreviousUrl } from '../../lib/utils/helpers'

const ProfilePresenter = ({
  initialValues,
  handleGeneralFormSubmit,
  investorDetailSchema,
  investorGeneralSchema,
  acceptedNDA,
}: any) => {
  const navigate = useNavigate()
  const [active, setActive] = useState(0)
  const params = useParams()
  const { profileTab } = params
  useEffect(() => {
    if (profileTab === 'general') setActive(0)
    if (profileTab === 'kyc') setActive(1)
  }, [])
  const handleChange = (index: number) => setActive(index)
  useEffect(() => {
    if (active === 0) navigate('/profile/general', { replace: true })
    if (active === 1) navigate('/profile/kyc', { replace: true })
    if (active === 1 && !acceptedNDA) {
      setPreviousUrl('/profile/kyc')
      navigate('/accept-NDA', { replace: true })
    }
  }, [active])

  return (
    <>
      <H1 text='Edit profile' />
      <div className='profile-tab-container'>
        <Tabs active={active} handleChange={handleChange}>
          <Tab title='General'>
            <GeneralForm
              initialValues={initialValues}
              handleFormSubmit={handleGeneralFormSubmit}
              validationSchema={investorGeneralSchema}
            />
          </Tab>
          <Tab title='KYC'>
            <KYCContainer />
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

export { ProfilePresenter }
