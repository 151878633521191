import React, { useState } from 'react'
import { ModalComponent } from '../../../pages/KYC/modal-component'
import { TagProps } from '../../types'
import { concatClassName } from '../../utils/helpers'
import { InputModal } from '../InputModal'
import './tag-component.scss'

const Tag = ({ variant = 'golden-bg', text, classes, onClick }: TagProps) => {
  return (
    <div onClick={onClick} className={`tag-container ${variant} ${concatClassName(classes)}`}>
      <p>{text}</p>
    </div>
  )
}

const TagWithModal = ({
  variant = 'golden-bg',
  text,
  classes,
  fileInfo,
  handleUpdateReply,
}: TagProps) => {
  const [modalVisible, setModalVisible] = useState(false)
  const handleClose = () => {
    setModalVisible((state) => !state)
  }
  const handleClick = () => {
    setModalVisible((state) => !state)
  }
  return (
    <>
      <div onClick={handleClick} className={`tag-container ${variant} ${concatClassName(classes)}`}>
        <p>{text}</p>
      </div>
      {modalVisible ? (
        <InputModal>
          <ModalComponent
            documentInfo={fileInfo}
            handleUpdateReply={handleUpdateReply}
            headerLabel={'Reply to comment'}
            handleCloseModal={handleClose}
          />
        </InputModal>
      ) : null}
    </>
  )
}

export { Tag, TagWithModal }
