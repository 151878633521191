import React from 'react'
import { IconProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

export const InvestIconHIW = ({ width = '100', height = '100', classes }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 400 300'
    className={concatClassName(classes)}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='210.5' cy='123.5' r='123.5' fill='#FFEFEF' />
    <rect
      x='2.46325'
      y='66.7828'
      width='247.041'
      height='147.829'
      rx='13.2847'
      stroke='#464646'
      strokeWidth='4.9265'
    />
    <path
      d='M193.359 310.762V148.411H228.829V310.762L215.083 281.425C213.499 278.044 208.689 278.044 207.105 281.425L193.359 310.762Z'
      fill='white'
      stroke='#464646'
      strokeWidth='4.9265'
    />
    <path
      d='M221.932 148.411H253.462V246.388L241.33 228.707C239.58 226.156 235.815 226.156 234.065 228.707L236.096 230.1L234.065 228.707L221.932 246.388V148.411Z'
      fill='white'
      stroke='#464646'
      strokeWidth='4.9265'
    />
    <circle
      cx='223.754'
      cy='108.85'
      r='59.9538'
      fill='white'
      stroke='#464646'
      strokeWidth='4.9265'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='7.77 15.54 23.31 31.08'
    />
    <circle
      cx='223.559'
      cy='109.044'
      r='34.2452'
      fill='white'
      stroke='#464646'
      strokeWidth='4.9265'
    />
    <path
      d='M43.3069 109.989L120.472 110.382'
      stroke='#464646'
      strokeWidth='4.72439'
      strokeLinecap='round'
    />
    <path
      d='M43.3069 130.461L120.472 130.855'
      stroke='#464646'
      strokeWidth='4.72439'
      strokeLinecap='round'
    />
    <path
      d='M43.3069 150.933L120.472 151.327'
      stroke='#464646'
      strokeWidth='4.72439'
      strokeLinecap='round'
    />
    <path
      d='M39.7636 195.421C50.6559 194.765 70.787 190.382 64.1729 178.099C55.9052 162.744 31.8896 166.681 39.7636 178.099C47.6375 189.516 74.4091 194.634 79.9208 186.366C85.4326 178.099 79.9208 177.311 94.4877 186.366C109.055 195.421 108.267 186.366 114.96 186.366C120.314 186.366 130.314 186.366 134.645 186.366'
      stroke='#464646'
      strokeWidth='3.93699'
      strokeLinecap='round'
    />
  </svg>
)

export const EarnIconHIW = ({ width = '100', height = '100', classes }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 400 300'
    className={concatClassName(classes)}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='227.5' cy='123.5' r='123.5' fill='#FFEFEF' />
    <rect
      x='212.202'
      y='152.538'
      width='25.1898'
      height='51.3126'
      fill='white'
      stroke='#464646'
      strokeWidth='4.66478'
    />
    <rect
      x='238.324'
      y='131.08'
      width='25.1898'
      height='72.7706'
      fill='white'
      stroke='#464646'
      strokeWidth='4.66478'
    />
    <rect
      x='264.447'
      y='105.89'
      width='25.1898'
      height='97.9604'
      fill='white'
      stroke='#464646'
      strokeWidth='4.66478'
    />
    <path
      d='M205.205 125.483H155.292V177.728L210.336 204.317H311.095'
      stroke='#464646'
      strokeWidth='4.66478'
      strokeLinecap='round'
    />
    <circle
      cx='117.973'
      cy='132.013'
      r='59.7092'
      fill='white'
      stroke='#464646'
      strokeWidth='4.66478'
    />
    <circle
      cx='221.065'
      cy='48.5137'
      r='46.1813'
      fill='white'
      stroke='#464646'
      strokeWidth='4.66478'
    />
    <circle cx='221.065' cy='48.5136' r='23.7904' stroke='#464646' strokeWidth='4.66478' />
    <line
      x1='221.531'
      y1='22.3909'
      x2='221.531'
      y2='1.86583'
      stroke='#464646'
      strokeWidth='4.66478'
    />
    <line
      x1='263.611'
      y1='60.7439'
      x2='243.785'
      y2='55.4316'
      stroke='#464646'
      strokeWidth='4.66478'
    />
    <line
      x1='242.476'
      y1='88.8663'
      x2='232.113'
      y2='71.1494'
      stroke='#464646'
      strokeWidth='4.66478'
    />
    <path
      d='M83.9202 155.373C88.585 145.075 76.4566 140.41 67.127 147.442L26.145 181.869C26.0997 181.907 26.0509 181.941 25.9993 181.97L2.85645 194.876C2.32862 195.171 2.21569 195.882 2.62631 196.325L48.3373 245.691C48.6727 246.053 49.2321 246.092 49.6137 245.778L66.9824 231.527C67.0779 231.449 67.1879 231.39 67.3062 231.355L99.3944 221.728C99.6408 221.654 99.8457 221.482 99.9608 221.252L109.397 202.38C109.512 202.15 109.716 201.978 109.961 201.904L145.029 191.291C157.624 187.524 154.358 174.498 139.431 174.498H75.6503C74.9528 174.498 74.5019 173.761 74.8199 173.14L83.9202 155.373Z'
      fill='white'
      stroke='#464646'
      strokeWidth='4.66478'
      strokeLinecap='round'
    />
    <line
      x1='24.0616'
      y1='185.012'
      x2='66.9776'
      y2='231.66'
      stroke='#464646'
      strokeWidth='4.66478'
    />
    <path
      d='M119.369 167.299H115.208V95.426H119.369V167.299ZM117.402 160.944C113.367 160.944 110.114 159.658 107.643 157.085C105.196 154.513 103.973 151.134 103.973 146.948V145.056H109.648V146.948C109.648 149.444 110.354 151.462 111.766 153C113.178 154.513 115.057 155.27 117.402 155.27C119.748 155.27 121.626 154.513 123.039 153C124.451 151.462 125.157 149.444 125.157 146.948V142.711C125.157 141.324 125.107 140.227 125.006 139.42C124.905 138.588 124.627 137.919 124.173 137.415C123.745 136.911 122.988 136.444 121.904 136.015C120.845 135.561 119.344 135.019 117.402 134.389C114.048 133.279 111.451 132.359 109.61 131.627C107.769 130.896 106.445 130.114 105.638 129.282C104.856 128.45 104.377 127.353 104.2 125.991C104.049 124.604 103.973 122.738 103.973 120.392V115.777C103.973 111.566 105.196 108.187 107.643 105.64C110.114 103.067 113.367 101.781 117.402 101.781C121.462 101.781 124.716 103.067 127.162 105.64C129.608 108.187 130.831 111.566 130.831 115.777V117.669H125.157V115.777C125.157 113.281 124.451 111.276 123.039 109.763C121.626 108.224 119.748 107.455 117.402 107.455C115.057 107.455 113.178 108.224 111.766 109.763C110.354 111.276 109.648 113.281 109.648 115.777V120.392C109.648 121.779 109.698 122.889 109.799 123.721C109.9 124.528 110.177 125.184 110.631 125.688C111.085 126.167 111.842 126.634 112.901 127.088C113.96 127.542 115.46 128.084 117.402 128.715C120.756 129.799 123.354 130.719 125.195 131.476C127.036 132.207 128.347 132.989 129.129 133.821C129.936 134.654 130.415 135.751 130.566 137.112C130.743 138.474 130.831 140.34 130.831 142.711V146.948C130.831 151.134 129.608 154.513 127.162 157.085C124.716 159.658 121.462 160.944 117.402 160.944Z'
      fill='#464646'
    />
  </svg>
)

export const RedeemIconHIW = ({ width = '100', height = '100', classes }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 400 300'
    className={concatClassName(classes)}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='139.5' cy='123.5' r='123.5' fill='#FFEFEF' />
    <path
      d='M70.6278 54.4248C63.0771 56.861 58.0305 57.9445 49.3055 58.972C48.107 59.1132 47.4669 60.466 48.1292 61.4747L64.1701 85.9063C49.0123 82.5877 16.8672 101.512 9.54839 203.76C9.54839 203.76 8.74115 213.178 4.70508 219.635C0.669017 226.093 9.54841 228.515 12.5082 225.824C15.468 223.133 21.9257 219.44 23.8092 219.635C25.6928 219.83 49.7363 226.362 73.63 226.362'
      stroke='#464646'
      strokeWidth='5.38145'
      strokeLinecap='round'
    />
    <path
      d='M71.4775 53.8866C78.9516 51.1461 88.8108 43.0561 98.488 42.7674C99.6126 42.7339 100.271 43.9253 99.768 44.9316L79.0116 86.4445C94.1693 83.1259 126.314 102.051 133.633 204.298C133.633 204.298 134.44 213.716 138.477 220.173C142.513 226.631 133.633 229.053 130.673 226.362C127.714 223.671 121.256 219.979 119.372 220.173C117.489 220.368 95.3712 226.362 71.4775 226.362'
      stroke='#464646'
      strokeWidth='5.38145'
      strokeLinecap='round'
    />
    <rect
      x='55.3331'
      y='78.815'
      width='31.7505'
      height='9.68661'
      rx='2.69072'
      fill='white'
      stroke='#464646'
      strokeWidth='5.38145'
    />
    <circle
      cx='71.4773'
      cy='159.537'
      r='37.132'
      fill='white'
      stroke='#464646'
      strokeWidth='5.38145'
    />
    <path
      d='M71.4773 139.649L77.82 149.209L78.3743 150.044L79.3401 150.313L90.3918 153.391L83.2601 162.377L82.6368 163.163L82.6794 164.165L83.1671 175.626L72.4168 171.621L71.4773 171.271L70.5378 171.621L59.7876 175.626L60.2752 164.165L60.3178 163.163L59.6946 162.377L52.5629 153.391L63.6145 150.313L64.5804 150.044L65.1347 149.209L71.4773 139.649Z'
      stroke='#464646'
      strokeWidth='5.38145'
    />
    <rect
      x='102.278'
      y='108.374'
      width='206.17'
      height='103.194'
      transform='rotate(2.95508 102.278 108.374)'
      fill='white'
      stroke='#464646'
      strokeWidth='5.38145'
    />
    <circle
      cx='205.676'
      cy='175.343'
      r='35.9308'
      transform='rotate(2.95508 205.676 175.343)'
      fill='white'
      stroke='#464646'
      strokeWidth='5.38145'
    />
    <path
      d='M191.786 191.661L216.829 156.387L220.192 157.083L195.148 192.357L191.786 191.661ZM196.974 170.754C195.095 170.365 193.699 169.465 192.786 168.054C191.894 166.629 191.646 164.959 192.042 163.045L193.192 157.485C193.588 155.571 194.477 154.145 195.857 153.208C197.258 152.258 198.899 151.977 200.778 152.366C202.658 152.754 204.043 153.661 204.935 155.086C205.848 156.498 206.106 158.161 205.71 160.074L204.56 165.635C204.164 167.549 203.265 168.981 201.864 169.932C200.483 170.869 198.853 171.143 196.974 170.754ZM197.605 167.702C198.554 167.899 199.377 167.745 200.074 167.242C200.789 166.743 201.249 165.993 201.456 164.993L202.607 159.432C202.813 158.432 202.688 157.561 202.23 156.819C201.772 156.077 201.078 155.61 200.147 155.417C199.199 155.221 198.358 155.371 197.627 155.867C196.912 156.366 196.451 157.116 196.244 158.116L195.094 163.677C194.887 164.677 195.012 165.548 195.47 166.29C195.945 167.035 196.657 167.506 197.605 167.702ZM211.173 196.373C209.311 195.988 207.924 195.09 207.011 193.678C206.119 192.253 205.871 190.584 206.267 188.67L207.417 183.109C207.813 181.196 208.702 179.77 210.082 178.833C211.483 177.882 213.115 177.6 214.977 177.985C216.857 178.374 218.242 179.281 219.134 180.706C220.047 182.117 220.305 183.78 219.909 185.694L218.759 191.254C218.363 193.168 217.464 194.6 216.063 195.551C214.682 196.488 213.052 196.762 211.173 196.373ZM211.804 193.322C212.753 193.518 213.584 193.366 214.299 192.867C215.014 192.367 215.474 191.618 215.681 190.618L216.832 185.057C217.038 184.057 216.913 183.186 216.455 182.444C215.997 181.702 215.303 181.235 214.372 181.042C213.406 180.842 212.566 180.992 211.852 181.492C211.137 181.991 210.676 182.741 210.469 183.741L209.319 189.301C209.112 190.301 209.237 191.172 209.695 191.914C210.153 192.656 210.856 193.125 211.804 193.322Z'
      fill='#464646'
    />
    <rect
      x='66.3724'
      y='100.172'
      width='206.17'
      height='103.194'
      transform='rotate(-22.1059 66.3724 100.172)'
      fill='white'
      stroke='#464646'
      strokeWidth='5.38145'
    />
    <circle
      cx='179.913'
      cy='113.214'
      r='35.9308'
      transform='rotate(-22.1059 179.913 113.214)'
      fill='white'
      stroke='#464646'
      strokeWidth='5.38145'
    />
    <path
      d='M188.985 133.238L186.585 134.212L169.748 92.7597L172.148 91.7849L188.985 133.238ZM186.362 130.033C184.034 130.978 181.857 130.999 179.829 130.094C177.815 129.184 176.318 127.521 175.338 125.107L174.895 124.016L178.167 122.687L178.61 123.777C179.195 125.217 180.075 126.215 181.25 126.772C182.419 127.314 183.68 127.31 185.032 126.761C186.385 126.211 187.291 125.335 187.751 124.131C188.206 122.913 188.14 121.584 187.555 120.144L186.563 117.701C186.238 116.901 185.952 116.28 185.705 115.838C185.451 115.382 185.135 115.061 184.755 114.877C184.389 114.686 183.844 114.594 183.118 114.601C182.401 114.587 181.408 114.626 180.141 114.717C177.946 114.863 176.233 114.941 174.999 114.95C173.766 114.96 172.82 114.819 172.159 114.528C171.513 114.231 170.98 113.711 170.559 112.967C170.147 112.202 169.666 111.144 169.117 109.791L168.036 107.129C167.049 104.7 166.963 102.465 167.777 100.423C168.6 98.3601 170.175 96.8562 172.502 95.911C174.844 94.9598 177.021 94.9395 179.035 95.85C181.042 96.746 182.539 98.4085 183.526 100.837L183.969 101.928L180.696 103.258L180.253 102.167C179.668 100.727 178.791 99.7359 177.623 99.194C176.448 98.6376 175.184 98.6342 173.831 99.1836C172.479 99.733 171.575 100.617 171.121 101.835C170.661 103.038 170.723 104.36 171.308 105.8L172.389 108.462C172.714 109.262 173.003 109.89 173.256 110.346C173.504 110.788 173.817 111.101 174.197 111.286C174.571 111.456 175.117 111.548 175.834 111.561C176.551 111.575 177.544 111.536 178.811 111.445C181 111.285 182.714 111.207 183.953 111.212C185.186 111.203 186.125 111.346 186.771 111.643C187.431 111.934 187.965 112.454 188.371 113.204C188.792 113.948 189.28 115.004 189.835 116.371L190.828 118.815C191.809 121.229 191.895 123.465 191.087 125.521C190.278 127.578 188.703 129.082 186.362 130.033Z'
      fill='#464646'
    />
  </svg>
)
