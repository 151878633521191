import React from 'react'
import './cta-banner-component.scss'
import { CTABannerProps } from '../../types'
import { concatClassName } from '../../utils/helpers'
import { Flex } from '../Flex'
import { H4 } from '../Heading'
import { P } from '../P'

const CTABanner = ({ heading, subHeading, ButtonComponent, classes }: CTABannerProps) => {
  return (
    <div className={`cta-banner-container ${concatClassName(classes)}`}>
      <Flex variant='column'>
        {heading && <H4 text={heading} />}
        {subHeading && (
          <P text={subHeading} classes={['mt-24']} textSize='regular' variant='default-gray' />
        )}
      </Flex>
      {ButtonComponent}
    </div>
  )
}

export { CTABanner }
