import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Close, Flex, H4, Input, Line, P } from '../../lib/components'
import { kycServices } from '../../services'
import { RootState } from '../../store'

interface ModalComponentProps {
  handleCloseModal: () => void
  documentInfo?: any
  headerLabel?: string
  handleUpdateReply?: (id: string, reply: string) => void
}

function ModalComponent({
  handleCloseModal,
  documentInfo,
  headerLabel,
  handleUpdateReply,
}: ModalComponentProps) {
  const { authToken } = useSelector((state: RootState) => state.investor)
  const [value, setValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const handleButtonClick = async () => {
    if (!value) {
      setErrorMessage('Reply cannot be empty')
      return
    }
    const updatedFileData = {
      userId: documentInfo.userId,
      reply: value,
    }
    try {
      const response: any = await kycServices.updateKYCDocument(documentInfo.id, updatedFileData, {
        Authorization: authToken ? authToken : '',
      })
      if (!!response && response.status === 200) {
        handleUpdateReply ? handleUpdateReply(documentInfo.id, value) : null
        handleCloseModal()
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Flex variant='column' classes={['input-modal']}>
      <Flex variant='space-between' classes={['modal-title-icon']}>
        <H4 text={headerLabel ? headerLabel : ''} />
        <Close height='24px' onClick={handleCloseModal} />
      </Flex>
      <Line />
      <Flex variant='column' classes={['reply-section']}>
        <Input
          placeholder='Enter your reply here'
          handleChange={(e) => setValue(e.target.value)}
          name='reply'
          value={value}
        />
        {errorMessage ? (
          <P textSize={'small'} classes={['error-message']} text={errorMessage} />
        ) : null}
        <Button variant='primary-btn' text='Send' handleClick={handleButtonClick} />
      </Flex>
    </Flex>
  )
}

export { ModalComponent }
