import React from 'react'
import { displayDollar } from '../../lib/utils/helpers'
import { H2, P } from '../../lib/components'
import { LineChart, Line, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

interface ChartCardProps {
  data: any[]
}

const ChartCard = ({ data }: ChartCardProps) => {
  return (
    <div className='chart-card'>
      <P text={<strong>Current Investment Value*</strong>} textSize='small' />
      <H2 text={displayDollar('13444000', 'USD')} classes={['mt-4', 'mb-20']} />
      <ResponsiveContainer width='100%' height={250}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray='3 5' />
          <YAxis axisLine={false} />
          <Tooltip />
          <Line type='monotone' dataKey='return' stroke='#8884d8' activeDot={{ r: 8 }} name='' />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export { ChartCard }
