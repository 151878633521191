/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { H2, ForwardInboxIcon, Span, Button, ArrowBackIcon, Flex } from '../../lib/components'
import { VerifyEmailProps } from '../../lib/types'
import {
  SIGNUP_SUCCESS,
  VERIFY_EMAIL,
  ACTIVATE_ACCOUNT,
  BACK_TO_HOMEPAGE,
} from '../../lib/utils/message'

const WelcomePresenter = ({
  email,
  handleClick,
  handleSendVerificationEmailLink,
  isEmailVerified,
}: VerifyEmailProps) => {
  return (
    <>
      <ForwardInboxIcon />
      <H2 classes={['mt-29 mb-29']} text={SIGNUP_SUCCESS} />
      <Span text={VERIFY_EMAIL} variant='default' />
      <Span text={`${email}.`} variant='bold'>
        <Span text={ACTIVATE_ACCOUNT} variant='default' />
      </Span>

      <Span
        classes={['mt-12']}
        // eslint-disable-next-line quotes
        text={"If you don't find it in your inbox, please check in your spam folder."}
        variant='default'
      />
      <Span
        text={
          // eslint-disable-next-line quotes
          "If it's not there either, you can request another link by clicking the button below."
        }
        variant='default'
      />
      <Button
        classes={['auto-btn-width', 'mt-36']}
        variant='primary-btn'
        handleClick={handleSendVerificationEmailLink}
        text='Resend verification link'
        isDisabled={isEmailVerified ? true : false}
      />
      <Button
        classes={['auto-btn-width', 'mt-36']}
        IconComponent={<ArrowBackIcon classes={['mr-8']} />}
        iconVariant={'left'}
        variant={'basic-btn'}
        text={BACK_TO_HOMEPAGE}
        handleClick={handleClick}
      />
    </>
  )
}

export { WelcomePresenter }
