import React, { useEffect } from 'react'
import { InvestmentCardProps } from '../../types'
import {
  displayDollar,
  displayPercentage,
  displayReturnsTitle,
  displayStatus,
} from '../../utils/helpers'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { H4 } from '../Heading'
import { Line } from '../Line'
import './investment-card-component.scss'
import { DownloadIcon } from '../Icon'
import { BasicStepper } from '../Stepper'
import { investmentStep } from '../../utils/message'
import { useNavigate } from 'react-router-dom'
import { Anchor } from '../Anchor'
import { ASSET_CLASSES } from '../../utils/constants'

function InvestmentCard(props: InvestmentCardProps) {
  const navigate = useNavigate()
  useEffect(() => {
    const handleStepperClass = () => {
      const elements: any = document.getElementsByClassName('basic-stepper-item')
      for (let i = 0; i < elements.length; i++) {
        if (elements[i] && elements[i].firstChild) {
          if (
            elements[i].firstChild.className ===
            'basic basic-step-counter completed  undefined regular'
          ) {
            elements[i].classList.add('completed-basic-stepper-item')
          }
          if (
            elements[i].firstChild.className ===
            'basic basic-step-counter active  undefined regular'
          ) {
            elements[i].classList.add('active-basic-stepper-item')
          }
        }
      }
    }
    handleStepperClass()
  }, [props.activeStep])

  return (
    <div className='investment-main-container'>
      <div className='investment-row-wrapper'>
        <div className='investment-h-container'>
          <div className='investment-h-image'>
            <img src={props.projectCover} alt='opportunity cover' />
          </div>
          <div className='investment-h-content'>
            <div className='investment-h-text'>
              <H4 text={props.projectName} />
              <p>
                {props.projectSummary} &nbsp;{' '}
                <Anchor
                  text='know more'
                  href={`/opportunity/${props.projectSlug}/${props.projectId}`}
                  variant='basic-anchor'
                />{' '}
              </p>
            </div>
          </div>
        </div>
        <div className='investment-row-wrapper'>
          <Flex variant='column'>
            <p className='investment-status-label'>Status</p>
            <p
              className={
                props.investmentStatus === 'REJECTED' || props.investmentStatus === 'CLOSED'
                  ? 'investment-status error-status'
                  : 'investment-status'
              }
            >
              {displayStatus(props.investmentStatus)}
            </p>
          </Flex>
          <div className='investment-stats-container'>
            {props?.assetClass !== ASSET_CLASSES.LISTED_EQUITIES && props.irr !== '0.00' && (
              <div className='investment-stats-card'>
                <p className='investment-card-main'>
                  {props.assetClass === ASSET_CLASSES.PRIVATE_EQUITY
                    ? displayDollar(props.irr, props.currencyCode)
                    : displayPercentage(props.irr) || ''}
                </p>
                <p className='investment-card-sub'>{displayReturnsTitle(props.assetClass)}</p>
              </div>
            )}
            <div className='investment-stats-card'>
              <p className='investment-card-main'>
                {displayDollar(props.ticketSize, props.currencyCode)}
              </p>
              <p className='investment-card-sub'>Ticket Size</p>
            </div>
            <div className='investment-stats-card'>
              <p className='investment-card-main'>
                {displayDollar(props.investmentAmount, props.currencyCode)}
              </p>
              <p className='investment-card-sub'>Invested Amount</p>
            </div>
          </div>
        </div>
      </div>

      <Line />

      <div className='investment-row-wrapper'>
        <div className='investment-doc-container'>
          <div className='assetclass-label-container'>
            <H4 text={'Asset Class'} />
            <p className='investment-status'>{props.assetClass}</p>
          </div>
          <div className='investment-doc-list'>
            {props.agreement && (
              <a href={props.agreement} rel='noreferrer' target={'_blank'}>
                <DownloadIcon width='12' height='12' /> Agreement Document
              </a>
            )}
            {props.paymentCopy && (
              <a href={props.paymentCopy} rel='noreferrer' target={'_blank'}>
                <DownloadIcon width='14' height='12' /> Payment Document
              </a>
            )}
            {/* {props.fundAllocation && (
              <a href={props.fundAllocation} rel='noreferrer' target={'_blank'}>
                <DownloadIcon width='14' height='12' /> Fund Allocation Document
              </a>
            )} */}
          </div>
          <Flex>
            {props.paymentCopy && (
              <Button
                classes={['investment-go-to-proj', 'mr-16']}
                variant='primary-btn'
                text='Investment summary'
                handleClick={() =>
                  navigate(`/payment/${props.projectSlug}/${props.projectId}/payment-summary`)
                }
              />
            )}
            <Button
              classes={['investment-go-to-proj']}
              variant='basic-btn'
              text='Go to opportunity'
              handleClick={props.handleProjectClick}
            />
          </Flex>
        </div>
        <div className='investment-card-stepper'>
          <BasicStepper steps={investmentStep} activeStep={props.activeStep} showStatus={true} />
        </div>
      </div>
    </div>
  )
}

export { InvestmentCard }
