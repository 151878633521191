import React from 'react'
import { RadioInputProps } from '../../types'
import './radio-input-component.scss'
import { concatClassName } from '../../utils/helpers'

const RadioInput = ({ list, handleChange, labelText, classes, defaultValue }: RadioInputProps) => {
  return (
    <>
      <div className={`${concatClassName(classes)} radio-container`}>
        <span className='radio-heading'> {labelText} </span>
        {list.map((obj: any) => (
          <div key={obj.value}>
            <input
              type='radio'
              value={obj.value}
              checked={obj.value === defaultValue}
              onChange={(e) => handleChange(e.target.value)}
              name={obj.name}
            />
            <span className='radio-label'>{obj.text}</span>
          </div>
        ))}
      </div>
    </>
  )
}

export { RadioInput }
