import posthog from './posthog'

const captureEvent = (eventName: string, properties = {}) => {
  posthog.capture(eventName, properties)
}

const usePostHog = () => {
  return { captureEvent }
}

export { captureEvent }
export default usePostHog
