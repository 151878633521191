import React, { useRef, useState, useEffect } from 'react'
import { Button, Flex, H2, Input, Span, SelectDropdown, SearchIcon } from '../../lib/components'
import { ProjectCard, ProjectCardHorizontal } from '../../lib/components/ProjectCard'
import { Featured } from '../../lib/components/FeaturedProject'
import { Pagination } from '../../lib/components/Pagination'
import { CardSlider } from '../../lib/components/Slider'
import { NextArrow, PreviousArrow } from '../../lib/components/Icon'
import './saved-projects.scss'
import { useBreakpoint } from '../../lib/utils/hooks'
import { breakpoints, SM } from '../../lib/utils/constants'
import { ProjectCardProps } from '../../lib/types'
import {
  calculateCommittedPercentage,
  displayDollar,
  displayPercentage,
  trimText,
} from '../../lib/utils/helpers'
import { projectFilters, projectSort } from '../../lib/utils/message'

function SavedProjectsPresenter({
  projects,
  handleProjectClick,
  searchParams,
  handleSearchParams,
  resetSearchParams,
  pagination,
  handleCurrentPage,
  currentPage,
}: any) {
  const { breakpoint } = useBreakpoint()

  return (
    <>
      <Flex variant='column-center'>
        <H2 text='Browse' classes={['browse-heading-text']} />
        <div className='saved-projects-toolbar-layout gap-12 mb-32'>
          <div className='search-input'>
            <span className='search-input-icon'>
              <SearchIcon />
            </span>
            <Input
              name='keyword'
              value={searchParams.keyword}
              handleChange={(e) =>
                handleSearchParams({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
              placeholder='Search'
            />
          </div>
          <div className='sort-reset'>
            <SelectDropdown
              name='sortBy'
              id='sortById'
              formId=''
              list={projectSort}
              value={searchParams.sortBy}
              handleChange={(val) => handleSearchParams({ name: 'sortBy', value: val })}
            />
            <Button
              handleClick={() => resetSearchParams()}
              classes={['reset-btn']}
              variant='secondary-btn'
              text='Reset'
            />
          </div>
        </div>
        <div className='project-cards-container'>
          {projects.length > 0 ? (
            projects.map(
              ({
                id,
                projectName,
                projectSlug,
                currencyCode,
                projectCover,
                isHighCommitenceProject,
                projectSummary,
                irr,
                assetClass,
                closingDate,
                ticketSize,
                raiseGoal,
                committedAmount,
              }: ProjectCardProps) => (
                <ProjectCard
                  key={id}
                  id={id}
                  projectSlug={projectSlug}
                  closingDate={closingDate}
                  projectName={projectName}
                  projectSummary={projectSummary}
                  commitedPercentage={
                    committedAmount
                      ? calculateCommittedPercentage(committedAmount || '', raiseGoal || '')
                      : '0%'
                  }
                  irr={displayPercentage(irr)}
                  projectCover={projectCover}
                  isHighCommitenceProject={isHighCommitenceProject}
                  assetClass={assetClass}
                  ticketSize={displayDollar(ticketSize, currencyCode)}
                  handleClick={handleProjectClick}
                />
              ),
            )
          ) : (
            <Span variant='thin' classes={['left']} text='No Saved Opportunities Found' />
          )}
        </div>
      </Flex>
      {(breakpoint && breakpoint <= SM) || projects.length === 0 ? null : (
        <>
          <div className='projects-pagination-wrapper'>
            <div className='projects-pagination-wrapper-info'>
              Showing {pagination.offset + 1} - {pagination.offset + pagination.count} of total{' '}
              {pagination.total} results.
            </div>
            <Pagination
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
              pagination={pagination}
            />
          </div>
        </>
      )}
    </>
  )
}

export { SavedProjectsPresenter }
