import React, { useRef, useState, useEffect } from 'react'
import { Button, Flex, H2, Input, Span, SelectDropdown, SearchIcon } from '../../lib/components'

import { OpportunityCard, OpportunityCardHorizontal } from '../../lib/components/OpportunityCard'
import { Featured } from '../../lib/components/FeaturedProject'
import { Pagination } from '../../lib/components/Pagination'
import { CardSlider } from '../../lib/components/Slider'
import { NextArrow, PreviousArrow } from '../../lib/components/Icon'
import './opportunity-list.scss'
import { useBreakpoint } from '../../lib/utils/hooks'
import { breakpoints, SM } from '../../lib/utils/constants'
import { OpportunityCardProps } from '../../lib/types'
import {
  calculateCommittedPercentage,
  displayDollar,
  displayPercentage,
  trimText,
} from '../../lib/utils/helpers'
import { projectFilters, projectSort } from '../../lib/utils/message'
import { url } from 'inspector'

function OpportunityListPresenter({
  projects,
  featuredProject,
  lastMinuteProjects,
  handleProjectClick,
  handleSaveForLater,
  searchParams,
  handleSearchParams,
  resetSearchParams,
  pagination,
  handleCurrentPage,
  currentPage,
}: any) {
  const [slidesToShow, setSlidesToShow] = useState(0)
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const { breakpoint } = useBreakpoint()
  const handleShowChildren = (totalChildren: number) => {
    const noOfMaxCard = (breakpoint && breakpoint / 550 - 0.2) || 0
    // 550 max width of a card
    if (totalChildren <= noOfMaxCard) setSlidesToShow(totalChildren)
    else setSlidesToShow(noOfMaxCard)
  }
  useEffect(() => {
    lastMinuteProjects.length && handleShowChildren(lastMinuteProjects.length)
  }, [breakpoint, lastMinuteProjects.length])

  return (
    <>
      <Flex variant='column-center'>
        {featuredProject && featuredProject.projectName && (
          <Featured
            featuredProject={featuredProject}
            handleClick={handleProjectClick}
            handleSaveForLater={handleSaveForLater}
          />
        )}
        {featuredProject && featuredProject.coverImage && (
          <div
            className='opportunity-gray-background-image'
            style={{ background: `url(${featuredProject.coverImage}) no-repeat center center` }}
          ></div>
        )}

        {/* {lastMinuteProjects.length > 0 && (
          <div className='last-minute-wrapper'>
            <div>
              {lastMinuteProjects.length > 0 && (
                <div className='slider-header'>
                  <H2 text='Last minute' />
                  <div className='slider-btn-container'>
                    {(breakpoint && breakpoint <= SM) ||
                    lastMinuteProjects.length <= slidesToShow ? null : (
                      <>
                        <p ref={prevRef}>
                          <PreviousArrow />
                        </p>
                        <p ref={nextRef}>
                          <NextArrow />
                        </p>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className='slider-container'>
                {lastMinuteProjects.length > 0 && (
                  <CardSlider slidesToShow={slidesToShow} prevButton={prevRef} nextButton={nextRef}>
                    {lastMinuteProjects.map(
                      ({
                        id,
                        opportunity_title,
                        opportunity_slug,
                        closing_date,
                        opportunity_summary,
                        total_investment_amount,
                        raise_goal,
                        images,
                        cover_image,
                      }: OpportunityCardProps) => (
                        <OpportunityCardHorizontal
                          key={id}
                          id={id}
                          projectSlug={opportunity_slug}
                          closingDate={closing_date}
                          projectName={opportunity_title}
                          projectSummary={opportunity_summary}
                          commitedPercentage={
                            total_investment_amount
                              ? calculateCommittedPercentage(
                                  String(total_investment_amount) || '',
                                  String(raise_goal) || '',
                                )
                              : '0%'
                          }
                          projectCover={cover_image ?? images[0]}
                          handleClick={handleProjectClick}
                        />
                      ),
                    )}
                  </CardSlider>
                )}
              </div>
            </div>
          </div>
        )} */}

        <H2 text='Browse' classes={['browse-heading-text']} />
        <div className='toolbar-layout gap-12 mb-32'>
          <div className='search-input'>
            <span className='search-input-icon'>
              <SearchIcon />
            </span>
            <Input
              name='keyword'
              value={searchParams.keyword}
              handleChange={(e) =>
                handleSearchParams({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
              placeholder='Search'
            />
          </div>
          <SelectDropdown
            name='filterBy'
            id='filterId'
            formId=''
            list={projectFilters}
            value={searchParams.filterBy}
            placeholder={'Filter'}
            handleChange={(val) => handleSearchParams({ name: 'filterBy', value: val })}
          />
          <div className='sort-reset'>
            <SelectDropdown
              name='sortBy'
              id='sortById'
              formId=''
              list={projectSort}
              placeholder={'Sort'}
              value={searchParams.sortBy}
              handleChange={(val) => handleSearchParams({ name: 'sortBy', value: val })}
            />
            <Button
              handleClick={() => resetSearchParams()}
              classes={['reset-btn']}
              variant='secondary-btn'
              text='Reset'
            />
          </div>
        </div>
        <div className='project-cards-container'>
          {projects.length > 0 ? (
            projects.map(
              ({
                id,
                opportunity_title,
                opportunity_slug,
                closing_date,
                opportunity_summary,
                asset_class,
                total_investment_amount,
                raise_goal,
                images,
                investment_return,
                ticket_size,
                cover_image,
                currency_code,
                short_opportunity_summary,
              }: OpportunityCardProps) => (
                <OpportunityCard
                  key={id}
                  id={id}
                  assetClass={asset_class}
                  irr={String(investment_return)}
                  ticketSize={String(ticket_size)}
                  projectSlug={opportunity_slug}
                  closingDate={closing_date}
                  projectName={opportunity_title}
                  projectSummary={short_opportunity_summary ?? trimText(opportunity_summary, 100)}
                  commitedPercentage={
                    total_investment_amount
                      ? calculateCommittedPercentage(
                          String(total_investment_amount) || '',
                          String(raise_goal) || '',
                        )
                      : '0%'
                  }
                  projectCover={cover_image ?? images[0]}
                  handleClick={handleProjectClick}
                  isHighCommitenceProject={false}
                  currencyCode={currency_code}
                />
              ),
            )
          ) : (
            <Span variant='thin' classes={['left']} text='No Opportunities Found' />
          )}
        </div>
      </Flex>
      {(breakpoint && breakpoint <= SM) || projects.length === 0 ? null : (
        <>
          <div className='projects-pagination-wrapper'>
            <div className='projects-pagination-wrapper-info'>
              Showing {pagination.offset + 1} - {pagination.offset + pagination.count} of total{' '}
              {pagination.total} results.
            </div>
            <Pagination
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
              pagination={pagination}
            />
          </div>
        </>
      )}
    </>
  )
}

export { OpportunityListPresenter }
