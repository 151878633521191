/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import './payment.scss'
import { H2, Span, SubmittedSuccessfulIcon, FlexCenter, H4, P, Button } from '../../lib/components'

const PaymentSubmittedSuccessful = ({ handleViewSummary }: any) => {
  return (
    <>
      <FlexCenter classes={['payment-submit-successful-wrapper']}>
        <SubmittedSuccessfulIcon />
        <H2 text={'Investment request submitted successfully!'} />
        <H4 text={'The investment is subject to allocation decision of the fund manager.'} />
        <P
          textSize='small'
          variant='default-gray'
          text={'Check your email for submitted details and status of your payment.'}
        />
        <Button
          classes={['auto-btn-width', 'mt-40']}
          variant={'primary-btn'}
          text={'View summary'}
          handleClick={handleViewSummary}
        />
      </FlexCenter>
    </>
  )
}

export { PaymentSubmittedSuccessful }
