import React from 'react'
import { Flex, H4, H5, Line, P } from '../../lib/components'
interface SectionHeaderProps {
  sectionName: string
  sectionHelperValues: {
    name: string
    value: string
  }[]
}

const SectionHeader = ({ sectionName, sectionHelperValues }: SectionHeaderProps) => {
  return (
    <div>
      <Flex>
        <H4 text={sectionName} classes={['mr-auto']} />
        {sectionHelperValues?.map(({ name, value }, idx) => {
          return (
            <>
              <Flex classes={['align-center']} key={idx}>
                <P text={name} textSize='small' />
                <H5 text={value} classes={['ml-12']} />
              </Flex>
              {idx !== sectionHelperValues.length - 1 && (
                <Flex classes={['border-separator', 'ml-16', 'mr-16']}></Flex>
              )}
            </>
          )
        })}
      </Flex>
      <Line variant='border-2' />
    </div>
  )
}

export default SectionHeader
