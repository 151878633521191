import React from 'react'
import { Anchor } from '../../lib/components'
import './privacy-policy.scss'

const PrivacyPolicyContainer = () => {
  return (
    <div className='container privacy-policy-container'>
      <h2>Privacy Policy</h2>
      <p>
        Credibila (“Credibila”) is a website operated by Credibila Markets (“Credibila Markets”),
        bearing Company No. 209658GBC, which is a Global Business Company incorporated in Mauritius,
        and has been granted an Investment Dealer (Full Service Dealer, excluding Underwriting)
        License , having License No. GB24202929 pursuant to Section 29 of the Securities Act 2005,
        Rule 4 of the Securities (Licensing ) Rules 2007 and the Financial Services (Consolidated
        Licensing and Fees) Rules, 2008, and is duly regulated by the Financial Services Commission,
        Mauritius.
      </p>

      <br />
      <p>
        Credibila (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy
        Policy explains how your personal information is collected, used, and disclosed by
        Credibila. This Privacy Policy applies to our website alongside our application, Credibila.
        By accessing or using our Service, you signify that you have read, understood, and agree to
        our collection, storage, use, and disclosure of your personal information as described in
        this Privacy Policy.
      </p>

      <h2>Definitions and key terms</h2>
      <p>
        To help explain things as clearly as possible in this Privacy Policy, every time any of
        these terms are referenced, are strictly defined as:
      </p>
      <ul>
        <li>
          <strong>Cookie:</strong> small amount of data generated by a website and saved by your web
          browser. It is used to identify your browser, provide analytics, remember information
          about you such as your language preference or login information.
        </li>
        <li>
          <strong>Company:</strong> when this policy mentions “Company,” “we,” “us,” or “our,” it
          refers to Credibila, that is responsible for your information under this Privacy Policy.
        </li>
        <li>
          <strong>Country:</strong> where Credibila is registered, in this case it is Mauritius
        </li>
        <li>
          <strong>Device:</strong> any internet connected device such as a phone, tablet, computer
          or any other device that can be used to visit Credibila and use the services.
        </li>
        <li>
          <strong>IP address:</strong> every device connected to the Internet is assigned a number
          known as an Internet protocol (IP) address. These numbers are usually assigned in
          geographic blocks. An IP address can often be used to identify the location from which a
          device is connecting to the Internet.
        </li>
        <li>
          <strong>Personnel:</strong> refers to those individuals who are employed by Credibila or
          are under contract to perform a service on behalf of one of the parties.
        </li>
        <li>
          <strong>Personal Data:</strong> any information that directly, indirectly, or in
          connection with other information including a personal identification number allows for
          the identification or identifiability of a natural person.
        </li>
        <li>
          <strong>Service:</strong> refers to the services provided by Credibila.
        </li>
        <li>
          <strong>Third-party service:</strong> refers to Sumsub which is One verification platform
          to secure the whole user journey
        </li>
        <li>
          <strong>Website:</strong> Credibila&apos;s site, which can be accessed via this URL:
          <Anchor
            variant='basic-anchor'
            target='_blank'
            classes={['ml-4']}
            href='https://www.credibila.com'
            text='https://www.credibila.com'
          />
        </li>
        <li>
          <strong>You:</strong> a person or entity that is registered with Credibila to use the
          Services.
        </li>
      </ul>

      <h2>What Information Do We Collect?</h2>
      <p>
        We collect the following information from you when you visit our website or aim to invest in
        any Opportunity listed on the website:
      </p>
      <ul>
        <li>Name</li>
        <li>Email Addresses</li>
        <li>Identity Proof</li>
        <li>
          Such other information as maybe required as part of the KYC process and by the Credibila
          Team
        </li>
      </ul>

      <h2>Do we share the information we collect with third parties?</h2>
      <p>
        We may share the information that we collect, both personal and non-personal, with Third
        Party service providers.
      </p>

      <h2>Where and when is information collected from Clients?</h2>
      <p>Credibila will collect personal information that you submit to us.</p>

      <h2>How Do We Use The Information We Collect?</h2>
      <p>Any of the information we collect from you may be used in one of the following ways:</p>
      <ul>
        <li>
          To personalize your experience (your information helps us to better respond to your
          individual needs)
        </li>
        <li>
          To improve our website (we continually strive to improve our website offerings based on
          the information and feedback we receive from you)
        </li>
        <li>
          To improve services to be provided to you (your information helps us to more effectively
          respond to your queries and support needs)
        </li>
        <li>To process transactions</li>
      </ul>

      <h2>How Do We Use Your Email Address?</h2>
      <p>
        By submitting your email address on this website, you agree to receive emails from us. We do
        not send unsolicited commercial emails, because we hate spam as much as you do.
      </p>

      <h2>How Long Do We Keep Your Information?</h2>
      <p>
        We keep your information only so long as you are a Client to Credibila. When we no longer
        need to use your information and there is no need for us to keep it to comply with our legal
        or regulatory obligations, we&apos;ll either remove it from our systems or depersonalize it
        so that we can&apos;t identify you.
      </p>

      <h2>How Do We Protect Your Information?</h2>
      <p>
        We implement a variety of security measures to maintain the safety of your personal
        information when you register on our website. We offer the use of a secure server. All
        supplied sensitive/credit information is to be accessible by those authorized with special
        access rights to such systems, and are required to keep the information confidential. After
        a transaction, your private information (credit cards, social security numbers, financials,
        etc.) is never kept on file. We are bound by the provisions of Data Protection Act, 2017 of
        Mauritius.
      </p>

      <h2>Is the information collected through the Credibila Service secure?</h2>
      <p>
        We take precautions to protect the security of your information. We have physical,
        electronic, and managerial procedures to help safeguard, prevent unauthorized access,
        maintain data security, and correctly use your information. However, neither people nor
        security systems are foolproof, including encryption systems. In addition, people can commit
        intentional crimes, make mistakes or fail to follow policies. Therefore, while we use
        reasonable efforts to protect your personal information, we cannot guarantee its absolute
        security. If applicable law imposes any non-disclaimable duty to protect your personal
        information, you agree that intentional misconduct will be the standards used to measure our
        compliance with that duty.
      </p>

      <h2>Affiliates</h2>
      <p>
        We may disclose information (including personal information) about you to our Corporate
        Affiliates. For purposes of this Privacy Policy, “Corporate Affiliate” means any person or
        entity which directly or indirectly controls, is controlled by or is under common control
        with Credibila, whether by ownership or otherwise. Any information relating to you that we
        provide to our Corporate Affiliates will be treated by those Corporate Affiliates in
        accordance with the terms of this Privacy Policy.
      </p>

      <h2>Governing Law</h2>
      <p>
        This Privacy Policy is governed by the laws of Mauritius without regard to its conflict of
        laws provision. You consent to the exclusive jurisdiction of the courts in connection with
        any action or dispute arising between the parties under or in connection with this Privacy
        Policy.
      </p>
      <p>
        The laws of Mauritius, excluding its conflicts of law rules, shall govern this Agreement and
        your use of the website. Your use of the website may also be subject to other local, state,
        national, or international laws.
      </p>
      <p>
        By using Credibila or contacting us directly, you signify your acceptance of this Privacy
        Policy. If you do not agree to this Privacy Policy, you should not engage with our website,
        or use our services. Continued use of the website, direct engagement with us, or following
        the posting of changes to this Privacy Policy that do not significantly affect the use or
        disclosure of your personal information will mean that you accept those changes.
      </p>

      <h2>Your Consent</h2>
      <p>
        We&apos;ve updated our Privacy Policy to provide you with complete transparency into what is
        being set when you visit our site and how it&apos;s being used. By using our website you
        hereby consent to our Privacy Policy and agree to its terms.
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        This Privacy Policy applies only to the Services. The Services may contain links to other
        websites not operated or controlled by Credibila. We are not responsible for the content,
        accuracy or opinions expressed in such websites, and such websites are not investigated,
        monitored or checked for accuracy or completeness by us. Please remember that when you use a
        link to go from the Services to another website, our Privacy Policy is no longer in effect.
        Your browsing and interaction on any other website, including those that have a link on our
        platform, is subject to that website&apos;s own rules and policies. Such third parties may
        use their own cookies or other methods to collect information about you.
      </p>

      <h2>Cookies</h2>
      <p>
        Credibila uses &ldquo;Cookies&rdquo; to identify the areas of our website that you have
        visited. A Cookie is a small piece of data stored on your computer or mobile device by your
        web browser. We use Cookies to enhance the performance and functionality of our website but
        are non-essential to their use. However, without these cookies, certain functionality like
        videos may become unavailable or you would be required to enter your login details every
        time you visit the website as we would not be able to remember that you had logged in
        previously. Most web browsers can be set to disable the use of Cookies. However, if you
        disable Cookies, you may not be able to access functionality on our website correctly or at
        all. We never place Personally Identifiable Information in Cookies.
      </p>

      <h2>Blocking and disabling cookies and similar technologies</h2>
      <p>
        Wherever you&apos;re located you may also set your browser to block cookies and similar
        technologies, but this action may block our essential cookies and prevent our website from
        functioning properly, and you may not be able to fully utilize all of its features and
        services. You should also be aware that you may also lose some saved information (e.g. saved
        login details, site preferences) if you block cookies on your browser. Different browsers
        make different controls available to you. Disabling a cookie or category of cookie does not
        delete the cookie from your browser, you will need to do this yourself from within your
        browser, you should visit your browser&apos;s help menu for more information.
      </p>

      <h2>Payment Details</h2>
      <p>
        In respect to any credit card or other payment processing details you have provided us, we
        commit that this confidential information will be stored in the most secure manner possible.
      </p>

      <h2>Kid&apos;s Privacy</h2>
      <p>
        We do not address anyone under the age of 21. We do not knowingly collect personally
        identifiable information from anyone under the age of 21.
      </p>

      <h2>Changes To Our Privacy Policy</h2>
      <p>
        We may change our Policies and we may need to make changes to this Privacy Policy as well so
        that they accurately reflect our Services and Policies. You will be bound by the updated
        Privacy Policy.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        We may display, include or make available third-party content (including data, information,
        applications and other products services) or provide links to third-party websites or
        services (&ldquo;Third- Party Services&rdquo;). You acknowledge and agree that Credibila
        shall not be responsible for any Third-Party Services, including their accuracy,
        completeness, timeliness, validity, copyright compliance, legality, decency, quality or any
        other aspect thereof. Credibila does not assume and shall not have any liability or
        responsibility to you or any other person or entity for any Third-Party Services.
      </p>
      <p>
        Third-Party Services and links thereto are provided solely as a convenience to you and you
        access and use them entirely at your own risk and subject to such third parties&apos; terms
        and conditions.
      </p>

      <h2>
        Information about Data Protection Act 2017 (DPA 2017 or Act), proclaimed through
        Proclamation No. 3 of 2018 and effective on January 15, 2018
      </h2>
      <p>
        We may be collecting and using information from you if you are falling under the ambit of
        Data Protection Act 2017 (DPA 2017 or Act), proclaimed through Proclamation No. 3 of 2018
        and effective on January 15, 2018 and in this section of our Privacy Policy we are going to
        explain exactly how and why is this data collected, and how we maintain this data under
        protection from being replicated or used in the wrong way.
      </p>

      <h2>What is DPA 2017?</h2>
      <p>
        Mauritius regulates data protection under the Data Protection Act 2017 (DPA 2017 or Act),
        proclaimed through Proclamation No. 3 of 2018 and effective on January 15, 2018.
      </p>

      <h2>What is personal data?</h2>
      <p>
        Personal data is defined as any information relating to a data subject. A data subject is a
        natural person who is identified or identifiable, in particular by reference to an
        identifier such as a name, identification number, location data, online identifier or to one
        or more factors specific to the physical, physiological, genetic, mental, economic, cultural
        or social identity of that individual.
      </p>
      <p>
        Similar to the GDPR, the DPA 2017 refers to sensitive personal data as special categories of
        data. Special categories of data include personal data pertaining to any of the following
        about a data subject:
      </p>
      <ul>
        <li>Racial or ethnic origin</li>
        <li>Political opinion or adherence</li>
        <li>Religious or philosophical beliefs</li>
        <li>Membership of a trade union</li>
        <li>Physical or mental health or condition</li>
        <li>Sexual orientation, practices or preferences</li>
        <li>Genetic or biometric data that is uniquely identifying</li>
        <li>Commission or proceedings related to the commission of a criminal offense</li>
        <li>
          Such other personal data as the Commissioner may determine to be sensitive personal data
        </li>
      </ul>

      <h2>NATIONAL DATA PROTECTION AUTHORITY</h2>
      <p>
        Under DPA 2017, the Data Protection Office (DPO) is responsible for data protection
        oversight. The DPO is an independent and impartial public office that is not subject to the
        control or direction of any person or authority. The DPO is headed by the Data Protection
        Commissioner (Commissioner), with the assistance of public officers as may be necessary.
      </p>

      <h2>Individual Data Subject&apos;s Rights - Data Access, Portability and Deletion</h2>
      <p>
        We are committed to helping our clients meet the data subject rights requirements of DPA
        2017. Credibila processes or stores all personal data in fully vetted, DPA compliant
        vendors. We do store all conversation and personal data for up to 6 years unless your
        account is deleted. In which case, we dispose of all data in accordance with our Terms of
        Service and Privacy Policy, but we will not hold it longer than 60 days.
      </p>

      <h2>Contact Us</h2>
      <p>
        Don&apos;t hesitate to contact us if you have any questions. You can reach us on email at
        <Anchor
          variant='basic-anchor'
          classes={['ml-4']}
          href='mailto:support@credibila.com'
          text='support@credibila.com'
        />
      </p>
    </div>
  )
}

export { PrivacyPolicyContainer }
