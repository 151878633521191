import { axiosInstance } from '.'
import { HOME } from './endpoints'

export const homeServices = {
  getHomeRequest: async () => {
    try {
      const data = await axiosInstance.get(HOME)
      return { data: data.data, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },
}
