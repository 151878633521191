import { axiosInstance } from '.'
import { CONTACT_US_GUEST_SUBMIT_V1, CONTACT_US_INVESTOR_SUBMIT_V1 } from './endpoints'
import { ApiResponse } from './types'
import { InvestorContactUsFormProps_v1, GuestContactUsFormProps_v1 } from '../lib/types'

export const contactsServices = {
  submitGuestContactForm_v1: async (
    details: GuestContactUsFormProps_v1,
    headers: { [key: string]: string },
  ) => {
    return axiosInstance.post<ApiResponse>(CONTACT_US_GUEST_SUBMIT_V1, details, { headers })
  },
  submitInvestorContactForm_v1: async (
    formDetails: InvestorContactUsFormProps_v1,
    headers: { [key: string]: string },
  ) => {
    const formData = new FormData()
    Object.entries(formDetails).forEach(([key, value]) => {
      if (key === 'attachments') {
        value.forEach((file: File) => {
          formData.append('attachments', file)
        })
      } else {
        formData.append(key, value)
      }
    })
    return axiosInstance.post<ApiResponse>(CONTACT_US_INVESTOR_SUBMIT_V1, formData, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
