export interface NewUser {
  name: string
  email: string
  password: string
  role: 'INVESTOR'
}

export interface User {
  email: 'string'
  password: 'string'
}

export enum Verification {
  verifyEmail = 'verify-email',
  resetPassword = 'reset-password',
}
