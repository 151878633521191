import React from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './store'
import { showBanner } from './global-state/banner-slice'
interface PrivateRouteProps {
  perms?: string[]
  children: React.ReactNode
}
interface PublicRouteProps {
  children: React.ReactNode
}
interface CanIUseProps {
  perms?: string[]
  isSelfDeclarationSigned: boolean
  isKycApproved: boolean
  isEmailVerified: boolean
  children: React.ReactNode
}
export const KYC = 'kyc'
export const NO_NDA = 'NotNDA'
export const NDA = 'NDA'
export const EmailVerified = 'EmailVerified'
const permissions = {
  KYC: 'kyc',
  NDA: 'NDA',
  NotNDA: 'NotNDA',
  EmailVerified: 'EmailVerified',
}
//these values should be part of the investor configuration in global state and should be fetched from there

const PrivateRoute = ({ perms, children }: PrivateRouteProps) => {
  const { authToken, acceptedNDA, kycStatus, isEmailVerified } = useSelector(
    (state: RootState) => state.investor,
  )
  const isLoggedIn = authToken && authToken
  const isSelfDeclarationSigned = acceptedNDA
  const isKycApproved = kycStatus === 'APPROVED' ? true : false
  if (!isLoggedIn) return <Navigate to='/login' replace />
  return canIUse({
    perms,
    isSelfDeclarationSigned,
    isKycApproved,
    isEmailVerified,
    children,
  })
}

const PublicRoute = ({ children }: PublicRouteProps) => {
  const { authToken } = useSelector((state: RootState) => state.investor)
  const isLoggedIn = authToken && authToken
  if (!isLoggedIn) return <>{children}</>
  return <Navigate to='/opportunities' replace />
}

const canIUse = ({
  perms,
  isSelfDeclarationSigned,
  isKycApproved,
  isEmailVerified,
  children,
}: CanIUseProps) => {
  const dispatch = useDispatch()
  if (perms && perms?.includes(permissions['KYC'])) {
    if (!isKycApproved) {
      dispatch(
        showBanner({
          text: 'Please complete KYC process to proceed',
          variant: 'warning-banner',
        }),
      )
      return <Navigate to='/profile/kyc' replace />
    }
  }

  if (perms && perms?.includes(permissions['EmailVerified'])) {
    if (!isEmailVerified) {
      return <Navigate to='/opportunities' replace />
    }
  }
  if (perms && perms?.includes(permissions['NDA'])) {
    if (!isSelfDeclarationSigned) {
      return <Navigate to='/accept-NDA' replace />
    }
  }
  if (perms && perms?.includes(permissions['NotNDA'])) {
    if (isSelfDeclarationSigned) return <></>
  }
  return <>{children}</>
}
export { PrivateRoute, PublicRoute }
