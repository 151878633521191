import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store'
import { Banner, ConfirmationModal } from './lib/components'

const ENV = process.env.REACT_APP_ENV || 'local'

if (ENV !== 'local' && ENV !== 'dev') {
  window.onUsersnapLoad = function (api) {
    api.init()
  }
  const script = document.createElement('script')
  script.defer = 1
  script.src =
    'https://widget.usersnap.com/global/load/af5117f4-c34a-4975-b900-23b7dc2e007e?onload=onUsersnapLoad'
  document.getElementsByTagName('head')[0].appendChild(script)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Banner />
    <ConfirmationModal />
    <App />
  </Provider>,
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
