import React, { useEffect, useState, useRef } from 'react'
import './opportunity-details-section-switcher.scss'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import { useBreakpoint } from '../../lib/utils/hooks'
import { MD } from '../../lib/utils/constants'
import { PreviousArrow } from '../../lib/components/Icon'

interface SectionSwitcherProps {
  opportunitySections: [string, string][]
}
interface SwitcherProps {
  opportunitySections: [string, string][]
  activeSection: string
  handleSectionChange: any
}

const DesktopSwitcher = ({
  opportunitySections,
  handleSectionChange,
  activeSection,
}: SwitcherProps) => {
  return (
    <Nav fill variant='pills' onSelect={handleSectionChange}>
      {opportunitySections.map(([section, id]) => (
        <Nav.Link
          href={`#${id}`}
          key={section}
          eventKey={section}
          active={section === activeSection}
        >
          {section}
        </Nav.Link>
      ))}
    </Nav>
  )
}

const MobileSwitcher = ({ opportunitySections, activeSection }: SwitcherProps) => {
  const [open, setOpen] = useState(false)

  return (
    <Dropdown onToggle={() => setOpen((state) => !state)}>
      <Dropdown.Toggle variant='success' id='dropdown-basic'>
        {activeSection}
        <PreviousArrow classes={['dropdown-arrow', open ? 'rotate-right-90' : 'rotate-left-90']} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {opportunitySections.map(([section, id]) => (
          <Dropdown.Item
            href={`#${id}`}
            key={section}
            onClick={(e) => console.log('hello', section)}
          >
            {section}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const OpportunitySectionSwitcher = ({ opportunitySections }: SectionSwitcherProps) => {
  const [activeSection, setActiveSection] = useState(opportunitySections[0]?.[0] || '')
  const offSetHeight = 130
  const isManualScroll = useRef(false)
  const { breakpoint } = useBreakpoint()

  const handleSectionChange = (newSection: any) => {
    setActiveSection(newSection)
    handleManualScroll(newSection)
  }

  const handleManualScroll = (newSection: string) => {
    const section = document.querySelector(
      `#${opportunitySections.find(([name, id]) => name === newSection)?.[1]}`,
    )
    if (section) {
      isManualScroll.current = true
      const targetPosition = section?.getBoundingClientRect()?.top + window.scrollY - offSetHeight
      window?.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      })
      setTimeout(() => {
        isManualScroll.current = false
      }, 1000)
    }
  }

  useEffect(() => {
    opportunitySections.forEach(([name, id]) => {
      const sectionButton = document.querySelector(`a[href^='#${id}']`)
      sectionButton?.addEventListener('click', function (event) {
        event.preventDefault()
        handleManualScroll(name)
      })
    })

    const handleScroll = () => {
      if (isManualScroll.current) return

      let closestSection: string | null = null
      let minTop = Infinity

      opportunitySections.forEach(([name, id]) => {
        const section = document.querySelector(`#${id}`)
        if (section) {
          const sectionRect = section.getBoundingClientRect()
          const isFullyInView = sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0

          if (isFullyInView && sectionRect.top < minTop) {
            closestSection = name
            minTop = sectionRect.top
          }
        }
      })

      if (closestSection && closestSection !== activeSection) {
        setActiveSection(closestSection)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [opportunitySections, activeSection])

  return breakpoint && breakpoint < MD ? (
    <MobileSwitcher
      opportunitySections={opportunitySections}
      handleSectionChange={handleSectionChange}
      activeSection={activeSection}
    />
  ) : (
    <DesktopSwitcher
      opportunitySections={opportunitySections}
      handleSectionChange={handleSectionChange}
      activeSection={activeSection}
    />
  )
}

export { OpportunitySectionSwitcher }
