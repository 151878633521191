import React from 'react'
import { IFormProps } from '../../lib/types'
import { FormikProps, Formik } from 'formik'
import { GLogin } from '../login/google-login-container'
import { Input, Button, Line, Anchor, P, Flex, GoogleReCaptcha } from '../../lib/components'
import { PASSWORD_ERROR } from '../../lib/utils/message'

const SignupPresenter = ({
  initialValues,
  handleFormSubmit,
  validationSchema,
  reRef,
  googleLoginHandler,
}: IFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {(formik) => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleChange,
          handleSubmit,
          setFieldValue,
        }: FormikProps<typeof initialValues> = formik
        return (
          <form>
            <Input
              labelText='Full name'
              name='name'
              placeholder='Enter your full name'
              type='text'
              required
              value={values.name}
              handleChange={handleChange(initialValues.name)}
              dirty={dirty}
              errors={{ name: !!errors && errors.name ? errors.name : null }}
              touched={{ name: !!touched && !!touched.name }}
            />

            <Input
              labelText='Email'
              name='email'
              placeholder='Enter your email'
              type='text'
              required
              value={values.email}
              handleChange={handleChange(initialValues.email)}
              dirty={dirty}
              errors={{ email: !!errors && errors.email ? errors.email : null }}
              touched={{ email: !!touched && !!touched.email }}
            />

            <div className='mt-16'>
              <Input
                labelText='Password'
                name='password'
                placeholder=''
                required
                type='password'
                value={values.password}
                handleChange={handleChange(initialValues.password)}
                dirty={dirty}
                errors={{
                  password: !!errors && errors.password ? errors.password : null,
                }}
                touched={{ password: !!touched && !!touched.password }}
              />
            </div>

            <div className='mt-16'>
              <Input
                labelText='Repeat password'
                name='passwordConfirmation'
                placeholder=''
                required
                infoText={PASSWORD_ERROR}
                type='password'
                value={values.passwordConfirmation}
                handleChange={handleChange(initialValues.passwordConfirmation)}
                dirty={dirty}
                errors={{
                  passwordConfirmation:
                    !!errors && errors.passwordConfirmation ? errors.passwordConfirmation : null,
                }}
                touched={{
                  passwordConfirmation: !!touched && !!touched.passwordConfirmation,
                }}
              />
            </div>

            <GoogleReCaptcha
              handleChange={(value) => setFieldValue('reCaptchaToken', value)}
              reCaptchaRef={reRef}
              size='invisible'
            />

            <Line />

            <Button
              text='Sign up'
              classes={['mt-16', 'mb-24']}
              buttonType='submit'
              variant='primary-btn'
              handleClick={handleSubmit}
            />

            {googleLoginHandler && (
              <GLogin
                buttonText='Sign up with google'
                onFailure={(res) => console.log('Login Failed ', res)}
                onSuccess={googleLoginHandler}
              />
            )}

            <Flex variant='row-center'>
              <P
                text='Already have an account?'
                classes={['mr-8']}
                variant='right'
                textSize='regular'
              />
              <Anchor text='Log In here' href='/login' variant='basic-anchor' />
            </Flex>
          </form>
        )
      }}
    </Formik>
  )
}

export { SignupPresenter }
