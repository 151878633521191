import React, { useEffect, useState, useCallback } from 'react'
import { FlexCenter, H2 } from '../../lib/components'
import { VerifiedEmailPresenter } from './verify-email-presenter'
import { userServices } from '../../services'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { showBanner } from '../../global-state/banner-slice'
import { Verification } from '../../lib/types'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
import { EVENTS } from '../../lib/utils/constants'

const VerifyEmailContainer = () => {
  const [isVerified, setIsVerified] = useState(false)
  const [isLinkExpired, setLinkExpired] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const verify_v1 = useCallback((code: string, user: string) => {
    userServices
      .verifyEmail_v1(code, user, Verification.verifyEmail)
      .then((response) => {
        setIsVerified(true)
        if (response.status === 400 && response.data.code === 'link-expired') {
          setLinkExpired(true)
        } else if (response.status === 200) {
          captureEvent(EVENTS.email_verified, { user })
        }
      })
      .catch((err) => {
        dispatch(
          showBanner({
            text: (err.data && err.data.message) || 'Something went wrong! Please try again.',
            variant: 'error-banner',
          }),
        )
        setIsVerified(false)
        navigate('/')
      })
  }, [])

  useEffect(() => {
    const code = searchParams.get('code')
    const user = searchParams.get('user')
    if (user && code) verify_v1(code, user)
    else {
      dispatch(
        showBanner({
          text: 'Something went wrong. Please try again.',
          variant: 'error-banner',
        }),
      )
      navigate('/login')
    }
  }, [])

  const handleClick = () => navigate('/login')
  return (
    <FlexCenter classes={['p-24']}>
      {isVerified ? (
        <VerifiedEmailPresenter handleClick={handleClick} isLinkExpired={isLinkExpired} />
      ) : (
        <H2 text='Verifying email, please wait..' />
      )}
    </FlexCenter>
  )
}

export { VerifyEmailContainer }
