/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { H2, MarkEmailReadIcon, Span, Button, ErrorIcon } from '../../lib/components'
import { VerifiedEmailProps } from '../../lib/types'
import {
  VERIFY_TEXT,
  VERIFY_SUBTEXT,
  VERIFIED_SUCCESS,
  LOGIN,
  VERIFY_LINK_EXPIRED_TEXT,
  VERIFICATION_FAILED,
  VERIFY_LINK_EXPIRED_SUBTEXT,
} from '../../lib/utils/message'
const VerifiedEmailPresenter = ({ handleClick, isLinkExpired }: VerifiedEmailProps) => {
  return isLinkExpired ? (
    <>
      <ErrorIcon width='84' height='72' />
      <H2 classes={['mt-32 mb-32']} text={VERIFICATION_FAILED} />
      <Span text={VERIFY_LINK_EXPIRED_TEXT} variant='default' />
      <Span text={VERIFY_LINK_EXPIRED_SUBTEXT} variant='default' />
      <Button
        classes={['auto-btn-width', 'mt-36']}
        variant={'primary-btn'}
        text={LOGIN}
        handleClick={handleClick}
      />
    </>
  ) : (
    <>
      <MarkEmailReadIcon />
      <H2 classes={['mt-32 mb-32']} text={VERIFIED_SUCCESS} />
      <Span text={VERIFY_TEXT} variant='default' />
      <Span text={VERIFY_SUBTEXT} variant='default' />
      <Button
        classes={['auto-btn-width', 'mt-36']}
        variant={'primary-btn'}
        text={LOGIN}
        handleClick={handleClick}
      />
    </>
  )
}

export { VerifiedEmailPresenter }
