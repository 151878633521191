import React from 'react'
import { Button, Flex, H3, H4, P } from '../../lib/components'
import { displayDollar } from '../../lib/utils/helpers'
import { useNavigate } from 'react-router-dom'

interface DashboardSummaryProps {
  activeInvestments: number
  pastInvestments: number
  watchListCount: number
  totalInvestmentAmount: number
  returnsAmount: number
}

const DashboardPortfolioSummary = ({
  activeInvestments,
  pastInvestments,
  watchListCount,
  totalInvestmentAmount,
  returnsAmount,
}: DashboardSummaryProps) => {
  const navigate = useNavigate()
  return (
    <div className='dashboard-container__top-section'>
      {/* First section with investment and returns numbers */}
      <div className='dashboard-container__top-section__returns-section'>
        <div className='dashboard-container__top-section__returns-section__progress-bar'>
          <div
            className='dashboard-container__top-section__returns-section__progress-bar__investment'
            style={{ width: '80%' }}
          />
          <div
            className='dashboard-container__top-section__returns-section__progress-bar__returns'
            style={{ width: '20%' }}
          />
        </div>

        <Flex classes={['space-between']}>
          <div className='dashboard-container__top-section__returns-section__investment'>
            <Flex classes={['align-center']}>
              <div className='dashboard-container__top-section__returns-section__investment__legend'></div>
              <P text='CURRENT INVESTMENT VALUE' textSize='x-small' />
            </Flex>
            <H3 text={displayDollar(totalInvestmentAmount.toString(), 'USD')} />
          </div>

          <div className='dashboard-container__top-section__returns-section__returns'>
            <Flex classes={['align-center']}>
              <div className='dashboard-container__top-section__returns-section__returns__legend'></div>
              <P text='RETURNS TILL DATE' textSize='x-small' />
            </Flex>
            <H3 text={displayDollar(returnsAmount.toString(), 'USD')} />
          </div>
        </Flex>
      </div>

      {/* Middle section with investment counts */}
      <div className='dashboard-container__top-section__investments-section'>
        <Flex classes={['align-center']}>
          <H4 text={activeInvestments.toString()} classes={['mr-20']} />
          <P text={'Active Investments'} />
        </Flex>
        <Flex classes={['align-center']}>
          <H4 text={pastInvestments.toString()} classes={['mr-20']} />
          <P text={'Past Investments'} />
        </Flex>
        <Flex classes={['align-center']}>
          <H4 text={watchListCount.toString()} classes={['mr-20']} />
          <P text={'Watchlist'} />
        </Flex>
      </div>

      {/* Last section with buttons */}
      <div className='dashboard-container__top-section__controls-section'>
        <Button
          variant='primary-btn'
          text='Add Investment'
          handleClick={() => navigate('/opportunities')}
        />
        <Button
          variant='basic-btn'
          text='Get assistance'
          classes={['mt-16']}
          handleClick={() => console.log('hello from add investment')}
        />
      </div>
    </div>
  )
}

export { DashboardPortfolioSummary }
