import { investorState } from '../types/propsTypes'

export const setInvestorDataToLS = (investorData: investorState) => {
  localStorage.setItem('authToken', investorData.authToken)
  localStorage.setItem(
    'investor_data',
    JSON.stringify({
      email: investorData.email,
      userId: investorData.userId,
      role: investorData.role,
      isEmailVerified: investorData.isEmailVerified,
      acceptedNDA: investorData.acceptedNDA,
      kycStatus: investorData.kycStatus,
      rememberMe: investorData.rememberMe,
      isActiveSession: investorData.isActiveSession,
    }),
  )
}

export const setInvestorDataToSS = (investorData: investorState) => {
  sessionStorage.setItem('authToken', investorData.authToken)
  sessionStorage.setItem(
    'investor_data',
    JSON.stringify({
      name: investorData.name,
      email: investorData.email,
      userId: investorData.userId,
      role: investorData.role,
      isEmailVerified: investorData.isEmailVerified,
      acceptedNDA: investorData.acceptedNDA,
      kycStatus: investorData.kycStatus,
      rememberMe: investorData.rememberMe,
      isActiveSession: investorData.isActiveSession,
    }),
  )
}

export const removeInvestorDataFromLS = () => {
  localStorage.removeItem('authToken')
  localStorage.removeItem('investor_data')
  localStorage.clear()
}

export const removeInvestorDataFromSS = () => {
  sessionStorage.removeItem('authToken')
  sessionStorage.removeItem('investor_data')
  sessionStorage.clear()
}

export const getInvestorDataFromLS = () => {
  const investorData = localStorage.getItem('investor_data')
  const authToken = localStorage.getItem('authToken')
  return {
    authToken,
    investorData: investorData ? JSON.parse(investorData) : null,
  }
}

export const getInvestorDataFromSS = () => {
  const investorDataSS = sessionStorage.getItem('investor_data')
  const authTokenSS = sessionStorage.getItem('authToken')
  return {
    authTokenSS,
    investorDataSS: investorDataSS ? JSON.parse(investorDataSS) : null,
  }
}
