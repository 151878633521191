import React, { useState } from 'react'
import './carousel.scss'
import Carousel from 'react-bootstrap/Carousel'
import { Image } from '../Image'

function ImageCarousel({
  images,
  prevLabel,
  nextLabel,
  imageClasses = ['project-cover-image'],
  id = null,
}: any) {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleSelect = (selectedIndex: number) => {
    setActiveIndex(selectedIndex)
  }

  return (
    <Carousel
      activeIndex={activeIndex}
      onSelect={handleSelect}
      prevLabel={prevLabel}
      nextLabel={nextLabel}
    >
      {images.map((src: any, idx: number) => (
        <Carousel.Item key={idx}>
          <div>
            <Image key={idx} classes={imageClasses} source={src} variant='all' radius='r16' />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export { ImageCarousel }
