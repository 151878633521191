import React, { useState } from 'react'
import { IFormProps } from '../../lib/types'
import { FormikProps, Formik } from 'formik'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Input, Button, Flex, SelectDropdown } from '../../lib/components'
import { COUNTRIES } from '../../lib/utils/message'
import { useBreakpoint } from '../../lib/utils/hooks'
import { XL } from '../../lib/utils/constants'

const GeneralForm = ({ initialValues, handleFormSubmit, validationSchema }: IFormProps) => {
  const { breakpoint } = useBreakpoint()
  const [isValidPhoneNumberValue, setIsValidPhoneNumberValue] = useState(true)
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {(formik) => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleChange,
          handleSubmit,
          setFieldValue,
        }: FormikProps<typeof initialValues> = formik
        return (
          <form>
            <Flex variant={breakpoint && breakpoint > XL ? 'space-between' : 'column'}>
              <div className='general-input-container'>
                <Input
                  labelText='Your name'
                  name='name'
                  placeholder='User Investor'
                  type='text'
                  required
                  value={values.name}
                  handleChange={(e) => setFieldValue('name', e.target.value)}
                  dirty={dirty}
                  errors={{
                    name: !!errors && errors.name ? errors.name : null,
                  }}
                  touched={{ name: !!touched && !!touched.name }}
                />
              </div>
              <div
                className={`general-input-container ${
                  breakpoint && breakpoint > XL ? '' : 'mt-36'
                }`}
              >
                <span className='general-input-label'> Country of residence </span>
                <SelectDropdown
                  name='countryOfResidence'
                  id='countryOfResidenceId'
                  classes={['general-input-select']}
                  formId=''
                  list={COUNTRIES}
                  value={values.countryOfResidence}
                  handleChange={(val: any) => setFieldValue('countryOfResidence', val)}
                  dirty={dirty}
                  errors={{
                    countryOfResidence:
                      !!errors && errors.countryOfResidence ? errors.countryOfResidence : null,
                  }}
                  touched={{
                    countryOfResidence: !!touched && !!touched.countryOfResidence,
                  }}
                />
              </div>
            </Flex>
            <Flex
              classes={['mt-32']}
              variant={breakpoint && breakpoint > XL ? 'space-between' : 'column'}
            >
              <div className='general-input-container'>
                <Input
                  labelText='Email'
                  name='email'
                  placeholder='user@email.com'
                  type='email'
                  disabled
                  required
                  value={values.email}
                />
              </div>
              <div
                className={`general-input-container ${
                  breakpoint && breakpoint > XL ? '' : 'mt-36'
                }`}
              >
                <span className='general-input-label'> Mobile phone </span>
                <PhoneInput
                  international
                  country={initialValues.countryCode}
                  placeholder='Enter phone number'
                  value={values.phoneNumber}
                  onChange={(val: any) => {
                    if (val && isValidPhoneNumber(val)) {
                      setFieldValue('phoneNumber', val)
                      setIsValidPhoneNumberValue(true)
                    } else setIsValidPhoneNumberValue(false)
                  }}
                />
                {!isValidPhoneNumberValue && (
                  <span className='general-input-error'> Invalid Mobile Number </span>
                )}
              </div>
            </Flex>
            <Button
              text='Save changes'
              classes={['auto-btn-width', 'button-top-margin']}
              variant='primary-btn'
              handleClick={handleSubmit}
            />
          </form>
        )
      }}
    </Formik>
  )
}

export { GeneralForm }
