/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import './payment.scss'
import {
  H2,
  Span,
  Anchor,
  H4,
  Image,
  Line,
  InputFile,
  PendingIcon,
  Button,
  P,
} from '../../lib/components'
import { displayDollar, displayPercentage, displayReturnsTitle } from '../../lib/utils/helpers'
import bankDetails from './bank-details'
import { ASSET_CLASSES } from '../../lib/utils/constants'

const PaymentUploadDocument = ({
  project,
  investment,
  handleFileUpload,
  handleCompletePayment,
}: any) => {
  const [paymentCopy, setPaymentCopy] = useState(investment ? investment.paymentCopy : undefined)
  const fileData = {
    name: 'Payment copy',
    status: 'UPLOADED',
    lastModified:
      investment && investment.updatedAt && new Date(investment.updatedAt).getMilliseconds(),
  }
  const fileInfo = paymentCopy && paymentCopy.length > 0 ? fileData : []
  const isDisabled = !(paymentCopy && paymentCopy.length > 0)

  const handleFileChange = async (file: any) => {
    if (!file) return
    const response = await handleFileUpload(file)
    if (response && response.status === 200) {
      setPaymentCopy(response.data.key)
    }
  }

  return (
    <>
      <div className='payment-upload-doc-heading-container'>
        <H2 text='Transfer funds' />
        <div>
          <Span text={'Payment process for'} />
          <Anchor
            classes={['invest-header-project-title']}
            text={project.projectName}
            href={`/opportunity/${project?.projectSlug}/${project?.id}`}
            variant='basic-anchor'
          />
        </div>
      </div>
      <div className='payment-upload-doc-user-wrapper'>
        <div className='payment-upload-doc-user-wrapper-left'>
          <H4 classes={['h4-heading']} text='Wire transfer details' />

          {
            <div className='payment-upload-doc-user-container'>
              <table className='payment-upload-doc-user-table'>
                <tbody>
                  {Object.keys(bankDetails.usdClient).map((k) => (
                    <tr key={k}>
                      <td>
                        {bankDetails.usdClient[k as keyof typeof bankDetails.usdClient].title}
                      </td>
                      <td>
                        {bankDetails.usdClient[k as keyof typeof bankDetails.usdClient].value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
          <div className='acknowledgement-container'>
            <H4 text='Swift copy or bank acknowledgement' />
            <span className='sub-heading'>{'Please upload the payment slip below.'} </span>
            <InputFile
              setRemoveFile={() => setPaymentCopy('')}
              type='doc'
              label=''
              name='swiftCopyBank'
              handleFiles={handleFileChange}
              fileInfo={fileInfo}
            />
          </div>
          <div className='payment-warning-container'>
            <PendingIcon classes={['info-icon']} width='24px' height='24px' />
            The investment is subject to allocation decision of the fund manager and availability of
            allocation
          </div>
          <div className='payment-button-container'>
            <Button
              text='Complete payment'
              isDisabled={isDisabled}
              handleClick={() => {
                handleCompletePayment(paymentCopy)
              }}
              variant='primary-btn'
            />
          </div>
        </div>
        <div className='payment-upload-doc-user-wrapper-right'>
          <div className='payment-company-card'>
            <span className='summary-label'> {'Summary'} </span>
            <div className='company-image-container'>
              <Image source={project.projectCover} radius='r8' variant='round' />
              <span className='company-name'> {project.projectName} </span>
            </div>
            <div className='property-container'>
              <div className='property-row'>
                <span className='property-column-left'>{'Ticket size:'}</span>
                <span className='property-column-right'>
                  {displayDollar(project.ticketSize, project.currencyCode)}{' '}
                </span>
              </div>
              {project?.assetClass !== ASSET_CLASSES.LISTED_EQUITIES && project.irr !== 0 && (
                <div className='property-row'>
                  <span className='property-column-left'>
                    {displayReturnsTitle(project.assetClass)}
                  </span>
                  <span className='property-column-right'>
                    {project.assetClass === ASSET_CLASSES.PRIVATE_EQUITY
                      ? displayDollar(project.irr, project.currencyCode)
                      : displayPercentage(project.irr) || ''}{' '}
                  </span>
                </div>
              )}
            </div>
            <Line />
            <div className='property-container'>
              <div className='property-row'>
                <span className='property-column-left'>{'Investment amount'}</span>
                <span className='property-column-right font-bold'>
                  {displayDollar(investment.amount, project.currencyCode)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PaymentUploadDocument }
