import React from 'react'
import { Link } from 'react-router-dom'
import { TabProps, TabsProps } from '../../types'
import { concatClassName } from '../../utils/helpers'
import './tabs-component.scss'

const Tabs = ({ active, classes, handleChange, children }: TabsProps) => {
  return (
    <>
      <div className={`tabs-container ${concatClassName(classes)}`}>
        {children &&
          children.map((c: any, index: number) => (
            <Link
              key={index}
              to={''}
              onClick={() => handleChange(index)}
              className={`tab-link ${active === index ? 'activeTab' : ''}`}
            >
              {c.props.title}
            </Link>
          ))}
      </div>
      <div className='tab-active-container'>{children[active]}</div>
    </>
  )
}

const Tab = ({ title, children }: TabProps) => <>{children}</>

export { Tabs, Tab }
