import React, { useState, useRef, useEffect } from 'react'
import { ContactUsPresenter } from './contact-us-presenter'
import { contactUsSchema } from '../../lib/utils/schema'
import { InvestorContactUsFormProps_v1 } from '../../lib/types'
import { useDispatch, useSelector } from 'react-redux'
import { showBanner } from '../../global-state/banner-slice'
import ReCAPTCHA from 'react-google-recaptcha'
import { userServices } from '../../services'
import { contactsServices } from '../../services/contact-us-services'
import { H3, P, Flex } from '../../lib/components'
import { RootState } from '../../store'

const ContactUsContainer = () => {
  const [initialValues, setInitialValues] = useState<InvestorContactUsFormProps_v1>({
    name: '',
    email: '',
    phone: '',
    assistance_category: 'Platform',
    subject: '',
    message: '',
    attachments: [],
  })
  const [apiResponse, setApiResponse] = useState<{
    type: 'success' | 'error' | ''
    message: string
  }>({
    type: '',
    message: '',
  })
  const { authToken, email } = useSelector((state: RootState) => state.investor)

  const dispatch = useDispatch()
  const reRef = useRef<ReCAPTCHA>(null)

  useEffect(() => {
    const getLoggedInUser = async () => {
      userServices
        .getProfileForCurrentUser_v1({
          Authorization: authToken ? authToken : '',
        })
        .then((response: any) => {
          setInitialValues({
            ...initialValues,
            name: response.data.name,
            email: response.data.email || email,
            phone: response.data.phone ?? '',
          })
        })
    }

    if (authToken) getLoggedInUser()
  }, [])

  const handleSubmit = async (values: InvestorContactUsFormProps_v1) => {
    const token = await reRef.current?.getValue()
    reRef.current?.reset()
    contactsServices
      .submitInvestorContactForm_v1(values, {
        Authorization: authToken ? authToken : '',
        'g-recaptcha-response': token ? token : '',
      })
      .then((response) => {
        window.scrollTo(0, 0)
        setApiResponse({
          type: 'success',
          message: response.data.message,
        })
        dispatch(
          showBanner({
            text: 'Query submitted sucessfully',
            variant: 'success-banner',
          }),
        )
      })
      .catch((err) => {
        const message = err.response.data ? err.response.data.message : 'Error recording your data'
        setApiResponse({
          type: 'error',
          message: message,
        })
        dispatch(
          showBanner({
            text: 'Something went wrong! Please try again.',
            variant: 'error-banner',
          }),
        )
      })
  }

  return (
    <div className='contact-us-main-layout'>
      <H3 text='Contact us' classes={['contact-us-main-layout__heading', 'mb-48']} />
      {['', 'error'].includes(apiResponse.type) ? (
        <ContactUsPresenter
          initialValues={initialValues}
          handleFormSubmit={handleSubmit}
          validationSchema={contactUsSchema}
          reRef={reRef}
          maxSize={20}
        />
      ) : (
        <Flex variant='row-center'>
          <P
            classes={['center', 'dark-gray']}
            text="Thank you for your interest in our investment opportunities. We'll be in touch soon with more information."
          />
        </Flex>
      )}
    </div>
  )
}

export { ContactUsContainer }
