import React, { useState } from 'react'
import {
  H2,
  H4,
  P,
  Flex,
  ProgressBar,
  Span,
  Anchor,
  PendingIcon,
  ApprovedIcon,
  DeniedIcon,
} from '../../lib/components'
import {
  displayDollar,
  displayPercentage,
  calculatePercentage,
  convertNumberWithCommas,
} from '../../lib/utils/helpers'
import { ContactUsModal } from '../../lib/components/ContactUsModal'

const DashboardInfoSection = ({
  kycStatus,
  email,
  name,
  userInvestmentDetail,
  totalInvestments,
  expectedIRR,
}: any) => {
  const [showContactUsModal, setShowContactUsModal] = useState(false)
  return (
    <div className='dashboard-top-section-wrapper'>
      <H2 text='Dashboard' />
      <div className='dashboard-top-section-container'>
        {(email || name) && (
          <div className='dashboard-top-section-left'>
            <H4 text={name?.length > 0 ? name : email} />
            <Flex variant='space-between' classes={['investment-container']}>
              <Span classes={['investment-limit']} variant='bold' text='Investment Limit' />
              <span className='investment-limit-values'>
                <strong>{`$${
                  userInvestmentDetail && userInvestmentDetail.totalInvestment
                    ? convertNumberWithCommas(parseInt(userInvestmentDetail.totalInvestment) / 100)
                    : '0'
                }`}</strong>
                {`/$${
                  userInvestmentDetail && userInvestmentDetail.investmentLimit
                    ? convertNumberWithCommas(parseInt(userInvestmentDetail.investmentLimit) / 100)
                    : '0'
                }`}
              </span>
            </Flex>
            <ProgressBar
              variant='default-bar'
              maxValue={'100'}
              value={
                userInvestmentDetail &&
                userInvestmentDetail.totalInvestment &&
                userInvestmentDetail.investmentLimit
                  ? Math.ceil(
                      calculatePercentage(
                        userInvestmentDetail.totalInvestment.toString(),
                        userInvestmentDetail.investmentLimit.toString(),
                      ),
                    ).toString()
                  : '0'
              }
            />
            {(userInvestmentDetail.length === 0 ||
              (userInvestmentDetail.totalInvestment &&
                userInvestmentDetail.investmentLimit &&
                calculatePercentage(
                  userInvestmentDetail.totalInvestment,
                  userInvestmentDetail.investmentLimit,
                ) > 90)) && (
              <>
                <Span
                  classes={['mt-16']}
                  variant='thin'
                  text={
                    userInvestmentDetail.totalInvestment &&
                    userInvestmentDetail.investmentLimit &&
                    calculatePercentage(
                      userInvestmentDetail.totalInvestment,
                      userInvestmentDetail.investmentLimit,
                    ) > 90 &&
                    'Your investment limit is soon to be reached.'
                  }
                />
                <span className='mt-4 basic-anchor' onClick={() => setShowContactUsModal(true)}>
                  {' '}
                  Request extension of the limit{' '}
                </span>
              </>
            )}

            {showContactUsModal ? (
              <ContactUsModal
                active={true}
                subject={'General'}
                handleOnClose={() => setShowContactUsModal(false)}
              />
            ) : null}
          </div>
        )}
        <div className='dashboard-top-section-right'>
          <div className='dashboard-top-section-right-status-container'>
            <H4 text={'KYC'} />
            <span className={` kyc-status-container-status-${kycStatus}`}>
              {kycStatus === 'NOT_STARTED' && (
                <>
                  <PendingIcon height='21' width='21' /> Not Started
                </>
              )}
              {kycStatus === 'INITIATED' && (
                <>
                  <PendingIcon height='21' width='21' /> Initiated
                </>
              )}
              {kycStatus === 'PENDING' && (
                <>
                  <PendingIcon height='18' width='20' /> Sent For Approval
                </>
              )}
              {kycStatus === 'REJECTED' && (
                <>
                  <DeniedIcon height='18' width='20' /> Rejected
                </>
              )}
              {kycStatus === 'RESUBMIT' && (
                <>
                  <DeniedIcon height='18' width='20' /> RESUBMIT
                </>
              )}
              {kycStatus === 'APPROVED' && (
                <>
                  <ApprovedIcon height='18' width='20' /> Approved
                </>
              )}
            </span>
          </div>
          {kycStatus !== 'APPROVED' && kycStatus !== 'REJECTED' && (
            <div>
              <P
                text={
                  kycStatus === 'PENDING'
                    ? 'The KYC is awaiting Credibila team approval. KYC approval is mandatory for investing in the opportunities.'
                    : 'Your KYC is not completed. Please complete the KYC for investing in the opportunities.'
                }
              />
              <Anchor
                classes={['mt-12']}
                variant='basic-anchor'
                text={'My KYC'}
                href='/profile/kyc'
              />
            </div>
          )}
          {kycStatus === 'APPROVED' && (
            <div>
              <P text='Your KYC is approved by the Credibila Team. You can now invest in the opportunities.' />
              <Anchor
                classes={['mt-12']}
                variant='basic-anchor'
                text='Discover opportunities'
                href='/opportunities'
              />
            </div>
          )}
          {kycStatus === 'REJECTED' && (
            <div>
              <P text={'Your KYC was rejected. Please contact the Credibila Team.'} />
              <Anchor
                classes={['mt-12']}
                variant='basic-anchor'
                text={'My KYC'}
                href='/profile/kyc'
              />
            </div>
          )}
        </div>
      </div>
      <div className='dashboard-info-card-container'>
        <div className='dashboard-info-card'>
          <span className='dashboard-info-card-heading'>{totalInvestments || '-'}</span>
          <span className='dashboard-info-card-sub-heading'>{'Total Investments'}</span>
        </div>
        <div className='dashboard-info-card'>
          <span className='dashboard-info-card-heading'>
            {userInvestmentDetail &&
            displayDollar(userInvestmentDetail.totalInvestment, 'USD') === '$0'
              ? '-'
              : displayDollar(userInvestmentDetail.totalInvestment, 'USD')}
          </span>
          <span className='dashboard-info-card-sub-heading'>{'Total Investment Amount'}</span>
        </div>

        {/* TODO: Need to rework on the logic as not all the asset classes will have expected return */}
        {/* <div className='dashboard-info-card'>
          <span className='dashboard-info-card-heading'>
            {(expectedIRR && displayPercentage(expectedIRR)) || '-'}
          </span>
          <span className='dashboard-info-card-sub-heading'>{'Returns'}</span>
        </div> */}
      </div>
    </div>
  )
}

export { DashboardInfoSection }
