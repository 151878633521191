import React from 'react'
import { Formik, FormikProps } from 'formik'
import { Button } from '../Button'
import { Input } from '../Input'
import { TextboxInput } from '../Input/input-component'
import { SelectDropdown } from '../SelectDropdown'
import { ContactUsFormProps } from '../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { GoogleReCaptcha } from '../ReCaptcha'

const contactUsSubjectOptions = [
  {
    text: 'Enquiry',
    value: 'Enquiry',
  },
  {
    text: 'Onboarding Procedure',
    value: 'Onboarding Procedure',
  },
  {
    text: 'Others',
    value: 'Others',
  },
]

const ContactUsForm = ({
  initialValues,
  validationSchema,
  handleSubmit,
  reRef,
  captchaToken,
  setCaptchaToken,
}: ContactUsFormProps) => {
  const { authToken, email } = useSelector((state: RootState) => state.investor)
  const isLoggedIn = authToken && email && authToken.length > 0 ? true : false
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const {
            values,
            touched,
            errors,
            dirty,
            handleChange,
            handleSubmit,
            setFieldValue,
          }: FormikProps<typeof initialValues> = formik
          return (
            <form>
              <Input
                labelText='Name'
                name='name'
                placeholder='Enter your full name'
                type='text'
                required
                value={values.name}
                handleChange={handleChange}
                dirty={dirty}
                errors={{
                  name: !!errors && errors.name ? errors.name : null,
                }}
                touched={{ name: !!touched && !!touched.name }}
              />

              <div className='mt-16'>
                <Input
                  labelText='Email Address'
                  name='email'
                  placeholder='Enter your email address'
                  type='email'
                  required
                  value={values.email}
                  handleChange={handleChange}
                  dirty={dirty}
                  disabled={isLoggedIn}
                  errors={{
                    email: !!errors && errors.email ? errors.email : null,
                  }}
                  touched={{ email: !!touched && !!touched.email }}
                />
              </div>

              <div className='mt-16'> {'Subject'}</div>
              <SelectDropdown
                name='subject'
                id='subject'
                formId=''
                list={contactUsSubjectOptions}
                value={values.subject}
                handleChange={(val) => {
                  setFieldValue('subject', val)
                }}
              />

              <TextboxInput
                name='message'
                labelText='Message'
                placeholder='Enter the message'
                required
                value={values.message}
                handleChange={handleChange}
                dirty={dirty}
                errors={{
                  message: !!errors && errors.message ? errors.message : null,
                }}
                touched={{
                  message: !!touched && !!touched.message,
                }}
              />

              <div className='mt-8'>
                <GoogleReCaptcha
                  handleChange={(value) => setCaptchaToken && setCaptchaToken(value)}
                  reCaptchaRef={reRef}
                  version='v2'
                />
              </div>

              <Button
                isDisabled={!captchaToken}
                text='Submit'
                classes={['mb-8 mt-12']}
                variant='primary-btn'
                handleClick={handleSubmit}
              />
            </form>
          )
        }}
      </Formik>
    </>
  )
}

export { ContactUsForm }
