import React from 'react'
import { ImageProps } from '../../types'
import './image-component.scss'
import { concatClassName } from '../../utils/helpers'

const Image = ({ source, classes, radius = 'r8', variant = 'all' }: ImageProps) => {
  return (
    <>
      <img
        className={`${variant}-${radius} ${concatClassName(classes)}`}
        src={source}
        alt='no image'
      />
    </>
  )
}

export { Image }
