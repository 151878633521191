import React, { useState } from 'react'
import './pagination.scss'
import { PreviousArrow, NextArrow } from '../Icon'

const Pagination = ({ currentPage, handleCurrentPage, pagination }: any) => {
  const { total, limit } = pagination
  const totalNoOfPages = Math.ceil(total / limit)

  const getPageNumber = (totalPages: number, currentPage: number) => {
    const items: JSX.Element[] = []
    if (totalPages <= 5) {
      for (let index = 0; index < totalPages; index++) {
        if (index + 1 === currentPage) {
          items.push(
            <p
              className='page-item page-item-active'
              key={index}
              onClick={() => handleCurrentPage(index + 1)}
            >
              {index + 1}{' '}
            </p>,
          )
          continue
        }
        items.push(
          <p className='page-item' key={index} onClick={() => handleCurrentPage(index + 1)}>
            {index + 1}{' '}
          </p>,
        )
      }
      return items
    }

    for (let index = 0; index < totalPages; index++) {
      if (index + 1 === currentPage) {
        items.push(
          <p
            className='page-item page-item-active'
            key={index}
            onClick={() => handleCurrentPage(index + 1)}
          >
            {index + 1}{' '}
          </p>,
        )
      } else if (index <= currentPage - 2 && index >= currentPage - 2) {
        items.push(
          <p className='page-item' key={index} onClick={() => handleCurrentPage(index + 1)}>
            {index + 1}{' '}
          </p>,
        )
      } else if (index >= currentPage && (index <= currentPage || index === 2)) {
        items.push(
          <p className='page-item' key={index} onClick={() => handleCurrentPage(index + 1)}>
            {index + 1}{' '}
          </p>,
        )
      } else if (index === currentPage + 2 && index <= totalPages) {
        items.push(
          <p className='page-item' key={index}>
            ...
          </p>,
        )
      } else if (index === totalPages - 1) {
        items.push(
          <p className='page-item' key={index} onClick={() => handleCurrentPage(index + 1)}>
            {index + 1}{' '}
          </p>,
        )
      }
    }
    return items
  }

  return (
    <div className='pagination-container'>
      <div className='items-container'>
        <p className='page-item' onClick={() => (currentPage === 1 ? '' : handleCurrentPage(1))}>
          {
            <>
              <PreviousArrow />
              <PreviousArrow />
            </>
          }
        </p>
        <p
          className='page-item'
          onClick={() => (currentPage === 1 ? '' : handleCurrentPage(currentPage - 1))}
        >
          {<PreviousArrow />}
        </p>
        {getPageNumber(totalNoOfPages, currentPage)}
        <p
          className='page-item'
          onClick={() => (totalNoOfPages === currentPage ? '' : handleCurrentPage(currentPage + 1))}
        >
          {<NextArrow />}
        </p>
        <p
          className='page-item'
          onClick={() => (totalNoOfPages === currentPage ? '' : handleCurrentPage(totalNoOfPages))}
        >
          {
            <>
              <NextArrow />
              <NextArrow />
            </>
          }
        </p>
      </div>
    </div>
  )
}

export { Pagination }
