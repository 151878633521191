import React from 'react'

const AcceptNDAText = () => {
  return (
    <>
      Credibila (the &ldquo;Website&rdquo;) is a private and confidential platform intended solely
      for the specific purposes granted to you and, if applicable, your organization. By utilizing
      the Website and its pages, along with the content, information, and materials they encompass
      (collectively, the &ldquo;Information&rdquo;), you acknowledge their confidential nature. This
      means that the Information is not meant for public dissemination or casual sharing,
      emphasizing its exclusivity and importance.
      <br />
      <br />
      You commit to maintaining the utmost secrecy and agree not to use the Information in any
      manner that could compromise Credibila&apos;s interests (the &ldquo;Disclosing Party&rdquo;)
      as defined in the Terms and Conditions of Use. For example, using the Information for
      competitive advantage, sharing it with unauthorized parties, or exploiting it for personal
      gain would be considered breaches of this agreement. Such actions could undermine
      Credibila&apos;s market position, damage its reputation, or cause financial loss.
      <br />
      <br />
      Additionally, you will not disclose the Information to any third party without obtaining prior
      written consent from Credibila. This restriction ensures that all Information is controlled
      and shared only under agreed-upon circumstances, protecting both the provider and the
      recipient from unauthorized exposure.
      <br />
      <br />
      You are obliged to notify Credibila immediately of any unauthorized use or disclosure of the
      Information and are responsible for any breaches of your confidentiality obligations as
      outlined in this User Confidentiality Policy by your employees and agents. This responsibility
      underscores the importance of ensuring that anyone with access to the Information within your
      organization understands and adheres to these confidentiality terms. An example of a breach
      might include an employee inadvertently sharing sensitive data via unsecured communication
      channels, highlighting the need for robust internal security protocols.
      <br />
      <br />
      Due to the unique nature of the Information, you acknowledge that any violation or potential
      breach of this policy will cause not only financial damage to the Disclosing Parties but also
      irreparable harm for which monetary compensation would be inadequate. This emphasizes the
      intrinsic value of the Information, suggesting that its loss or misuse could have far-reaching
      consequences beyond mere financial implications.
    </>
  )
}

export { AcceptNDAText }
