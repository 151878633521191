// src/lib/posthog.ts
import posthog from 'posthog-js'

const API_KEY = process.env.REACT_APP_POSTHOG_PROJECT_API_KEY ?? ''

// Initialize PostHog
posthog.init(API_KEY, { api_host: 'https://us.i.posthog.com' })

// Export PostHog instance for use in other parts of the application
export default posthog
