import React from 'react'
import './key-factors.scss'
import { Flex, CheckedCheckBoxIcon, P } from '../../lib/components'

interface KeyFactorSectionProps {
  keyFactors: string[]
}
const KeyFactorSection = ({ keyFactors }: KeyFactorSectionProps) => {
  return (
    <Flex variant='column' classes={['mt-32', 'mr-40', 'mb-32', 'ml-40', 'key-factors-container']}>
      <ol>
        {keyFactors.map((factor, idx) => (
          <li key={idx}>
            <CheckedCheckBoxIcon classes={['mr-16']} />

            <P text={factor} textSize={'small'} classes={['dark-gray']} />
          </li>
        ))}
      </ol>
    </Flex>
  )
}

export { KeyFactorSection }
