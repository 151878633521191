/* eslint-disable @typescript-eslint/no-empty-function */
import React, { act, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Flex, H3, H5, Input, P, RadioInput, Span } from '../../lib/components'
import { useBreakpoint } from '../../lib/utils/hooks'
import { ChartCard } from './chart-card'
import { convertNumberWithCommas, getFormattedDate, trimText } from '../../lib/utils/helpers'
import { DashboardOpportunityCard } from './opportunity-card'
import SectionHeader from './section-header'

interface PresenterProps {
  activeInvestment: {
    opportunity_title: string
    cover_image: string
    values: { date: string; return: number }[]
    short_opportunity_summary: string
    investmentDetails: { transaction_date: string; description: string; amount: string }[]
  }
  otherInvestments: {
    opportunity_title: string
    cover_image: string
    short_opportunity_summary: string
  }[]
  handleActiveInvestmentChange: (text: string) => void
}

const ActiveInvestmentPresenter = ({
  activeInvestment,
  otherInvestments,
  handleActiveInvestmentChange,
}: PresenterProps) => {
  const navigate = useNavigate()
  const { breakpoint } = useBreakpoint()
  const [redeemAmount, setRedeemAmount] = useState(0)
  const [redeemValue, setRedeemValue] = useState('partial')
  const redeemOptions = [
    {
      text: 'Partially Redeem',
      value: 'partial',
      name: 'redeem',
    },
    {
      text: 'Fully Redeem',
      value: 'full',
      name: 'redeem',
    },
  ]

  const calculateTotalInvestment = (investmentList: any) => {
    return convertNumberWithCommas(
      investmentList.reduce(
        (
          acc: number,
          curr: {
            transaction_date: string
            description: string
            amount: string
          },
        ) => {
          return acc + Number(curr.amount)
        },
        0,
      ),
    )
  }

  const handleOtherOpportunityClick = (newOpportunity: string) => {
    handleActiveInvestmentChange(newOpportunity)
    window.scrollTo(0, 0)
  }

  return (
    <div className='active-investment-container'>
      <Flex classes={['active-investment-container__breadcrumb-content-wrapper']}>
        <Flex classes={['active-investment-container__breadcrumb-navigation']}>
          <div className='pointer' onClick={() => navigate('/dashboard-new')}>
            <Span text='Portfolio Summary' />
          </div>
          <Span classes={['active-investment-container__breadcrumbs-arrow']} text='>' />
          <div className='pointer' onClick={() => navigate('/dashboard-new')}>
            <Span text='Active Investments' />
          </div>
          <Span classes={['active-investment-container__breadcrumbs-arrow']} text='>' />
          <div>
            <Span
              classes={['active-investment-container__breadcrumb-selected-tab']}
              text={activeInvestment?.opportunity_title ? activeInvestment.opportunity_title : ''}
            />
          </div>
        </Flex>
      </Flex>

      {/* Active investment information */}
      <Flex variant='column'>
        {/* Active investment image and name */}
        <Flex classes={['align-center', 'mt-60']}>
          <div className='dashboard-container__active-investments-section__investment-card__image'>
            <img src={activeInvestment.cover_image} alt='opportunity cover' />
          </div>
          <Flex classes={['ml-16']}>
            <H5 text={activeInvestment.opportunity_title} />
          </Flex>
        </Flex>
        {/* Active investment summary, charts, investment details */}
        <Flex variant='column' classes={['active-investment-container__information-container']}>
          <P text={activeInvestment.short_opportunity_summary} textSize='small' />

          <Flex
            classes={[
              'mt-32',
              'active-investment-container__information-container__card-container',
            ]}
          >
            <Flex>
              <ChartCard data={activeInvestment.values} />
            </Flex>

            <Flex>
              <div className='active-investment-container__information-container__card-container__redeem-card'>
                <H3
                  text='Redeem'
                  classes={[
                    'active-investment-container__information-container__card-container__redeem-card__title',
                  ]}
                />

                <RadioInput
                  defaultValue={redeemValue}
                  classes={['mt-24', 'mb-24']}
                  list={redeemOptions}
                  labelText={''}
                  handleChange={(val) => setRedeemValue(val)}
                />

                <Input
                  name='name'
                  labelText='Enter amount you wish to redeem'
                  type='number'
                  required
                  value={redeemAmount.toString()}
                  handleChange={(e) => {
                    setRedeemAmount(Number(e.target.value))
                  }}
                />

                <Button
                  variant='primary-btn'
                  text='Redeem'
                  classes={['mt-12']}
                  handleClick={() => {
                    console.log('value of ', redeemAmount)
                  }}
                />
              </div>
            </Flex>
          </Flex>

          <div className='active-investment-container__information-container__investment_details'>
            <H3
              text='Investment Details'
              classes={[
                'active-investment-container__information-container__investment_details__title',
              ]}
            />

            <table
              className='active-investment-container__information-container__investment_details__details-table'
              cellSpacing='0'
              cellPadding='0'
            >
              <tr className='header'>
                <th>Date</th>
                <th>Description</th>
                <th>Amount(USD)</th>
              </tr>
              {activeInvestment?.investmentDetails?.map((investment: any, idx: number) => (
                <tr key={idx}>
                  <td>
                    <P text={getFormattedDate(investment.transaction_date, 'D MMM YYYY')} />
                  </td>
                  <td>
                    <P text={investment.description} />
                  </td>
                  <td>
                    <P text={convertNumberWithCommas(Number(investment.amount))} />
                  </td>
                </tr>
              ))}
              <tr className='total'>
                <td>
                  <P text={'Total'} classes={['dark-gray']} />
                </td>
                <td>
                  <P text={'Current Investment Value as of DD-MM-YYYY'} classes={['dark-gray']} />
                </td>
                <td>
                  <P
                    text={calculateTotalInvestment(activeInvestment.investmentDetails)}
                    classes={['dark-gray']}
                  />
                </td>
              </tr>
            </table>
          </div>
        </Flex>

        {/* Other investments section */}
        <div className='dashboard-container__watchlist-section'>
          <SectionHeader
            sectionName='Other Investments'
            sectionHelperValues={[
              { name: 'Avg Return', value: '16%' },
              { name: 'Current Investment Value*', value: '131.4k' },
            ]}
          />

          {otherInvestments &&
            otherInvestments?.map((opportunity: any) => {
              const {
                id,
                opportunity_id,
                opportunity_slug,
                asset_class,
                currency_code,
                opportunity_title,
                short_opportunity_summary,
                investment_return,
                ticket_size,
                images,
                cover_image,
                raise_goal,
              } = opportunity
              return (
                <DashboardOpportunityCard
                  key={id}
                  opportunityCover={cover_image ?? images}
                  opportunityName={opportunity_title}
                  opportunitySummary={trimText(short_opportunity_summary, 150)}
                  opportunitySlug={opportunity_slug}
                  opportunityId={opportunity_id}
                  irr={investment_return}
                  assetClass={asset_class}
                  ticketSize={ticket_size}
                  currencyCode={currency_code}
                  raiseGoal={raise_goal}
                  handleClick={() => {
                    handleOtherOpportunityClick(opportunity_title)
                  }}
                />
              )
            })}
        </div>
      </Flex>
    </div>
  )
}

export { ActiveInvestmentPresenter }
