/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable arrow-spacing */
import React, { useState } from 'react'
import { CBLogoV2 } from '../Icon'
import { useNavigate } from 'react-router-dom'
import { P, Anchor, Input, Button } from '..'
import './footer.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

export const Footer = () => {
  const [email, setEmail] = useState('')
  const { authToken } = useSelector((state: RootState) => state.investor)
  const navigate = useNavigate()
  return (
    <div>
      <div className='footer'>
        <div className='footer-logo-container'>
          <div>
            <CBLogoV2 classes={['footer-logo-container-logo']} />
          </div>
        </div>
        <div className='footer-link-container-group'>
          <div className='footer-link-container'>
            <span className='footer-link-heading'>Links</span>
            <Anchor href={'/home'} text={'Home'} variant='nav-link' classes={['nav-link-footer']} />
            <Anchor
              href={'/opportunities'}
              text={'Opportunities'}
              variant='nav-link'
              classes={['nav-link-footer']}
            />
            <Anchor
              href={'/about'}
              text={'About Us'}
              variant='nav-link'
              classes={['nav-link-footer']}
            />
            {authToken && (
              <Anchor
                href={'/contact-us'}
                text={'Contact Us'}
                variant='nav-link'
                classes={['nav-link-footer']}
              />
            )}
          </div>
          <div className='footer-link-container'>
            <span className='footer-link-heading'>Legal</span>
            <Anchor
              href={'/privacy_policy'}
              text={'Privacy Policy'}
              variant='nav-link'
              classes={['nav-link-footer']}
            />
            <Anchor
              href={'/terms_and_conditions'}
              text={'Terms & Condition'}
              variant='nav-link'
              classes={['nav-link-footer']}
            />
          </div>
        </div>
        <div className='footer-signup-main-container'>
          {!authToken && (
            <div className='footer-signup-container'>
              <>
                <Input
                  name='email'
                  placeholder='Enter your email address'
                  type='text'
                  handleChange={(e) => setEmail(e.target.value)}
                  required
                  value={email}
                />
                <Button
                  text='Sign Up'
                  classes={['signup-button']}
                  handleClick={() => navigate(`/signup/${email}`)}
                  variant='primary-btn'
                />
              </>
            </div>
          )}
          <p>
            Credibila (“Credibila”) is a website operated by Credibila Markets (“Credibila
            Markets”), bearing Company No. 209658GBC, which is a Global Business Company
            incorporated in Mauritius, and has been granted an Investment Dealer (Full Service
            Dealer, excluding Underwriting) License , having License No. GB24202929 pursuant to
            Section 29 of the Securities Act 2005, Rule 4 of the Securities (Licensing ) Rules 2007
            and the Financial Services (Consolidated Licensing and Fees) Rules, 2008, and is duly
            regulated by the Financial Services Commission, Mauritius.
          </p>
        </div>
      </div>
      <div className='footer-disclaimer'>
        <h3>Important Message</h3>
        <ol className='ml-8 disclaimer'>
          <li>
            <p>
              Expected Yield to Maturity: The Expected Yield to Maturity (YTM) is the projected
              total annual return on the investment if held until maturity. This includes interest
              income and possible capital gains and losses.
            </p>
          </li>
          <li>
            <p>
              Annual Interest Rate: The Annual Interest Rate is the percentage of interest earned on
              an investment each year
            </p>
          </li>
          <li>
            <p>
              Expected Return: The Expected Return is the anticipated profit or loss from an
              investment over time, subject to macro and microeconomic changes. This includes
              potential capital gains and losses, and potential dividend income.
              <p className='mt-8'>
                The potential return of any investment is subject to a &apos;Force Majeure
                Event&apos;.*
                <br />
                An act or event is a &ldquo;Force Majeure Event&rdquo; if such act or event is
                beyond the reasonable control, and not the result of the fault or negligence, of
                Credibila and Credibila had been unable to overcome such act or event with the
                exercise of due diligence.
                <br />
                Subject to the foregoing conditions, &ldquo;Force Majeure Event&rdquo; shall include
                the following acts or events:
                <ol className='ml-16 lower-roman'>
                  <li>
                    Natural phenomena , such as storms, hurricanes, floods, lightning and
                    earthquakes
                  </li>
                  <li>
                    Explosions or fires arising from lightning or other causes unrelated to the acts
                    or omissions of the Party seeking to be excused from performance
                  </li>
                  <li>
                    Acts of war or public disorders, civil disturbances, riots, insurrection,
                    sabotage, epidemic, pandemic , terrorist acts, or rebellion
                  </li>
                  <li>Strikes or labor disputes</li>
                  <li>
                    Action by a Governmental Authority, including a moratorium on any activities
                    related to this Agreement
                  </li>
                  <li>
                    The impossibility for Credibila despite reasonable efforts, to obtain any
                    approval necessary to enable Credibila to fulfil its obligations, provided that
                    the impossibility is not attributable to Credibila and that Credibila has
                    exercised reasonable efforts to obtain such approval.
                  </li>
                </ol>
              </p>
            </p>
          </li>
          <li>
            <p>
              Performance Not Guaranteed: Past performance is no guarantee of future results. Any
              historical returns, expected returns, assured returns or probability projections are
              not guaranteed and may not reflect actual future performance.
            </p>
          </li>
          <li>
            <p>
              Risk of Loss: All the Opportunities displayed on the website of Credibila involve a
              high degree of risk and may result in partial or total loss of your investment.
              Investors in Credibila are not protected by any statutory compensation arrangements in
              Mauritius in the event of the failure of Opportunities. The Mauritius FSC does not
              vouch for the financial soundness of the Opportunities displayed on the website or for
              the correctness of any statements made or opinions expressed with regard to it.
            </p>
          </li>
          <li>
            <p>
              Not a Public Exchange: Credibila is NOT a stock exchange or public securities
              exchange, there is no guarantee that Credibila will continue to operate or remain
              available to Clients.
            </p>
          </li>
          <li>
            <p>
              Opportunities: Many of the opportunities displayed on the website are high-risk
              investments. No securities commission or regulatory authority has recommended or
              approved any investment or the accuracy or completeness of any of the information or
              materials provided by or through us. Please refer to the investment opportunity and
              the company&apos;s website for additional information, disclosures, and a more
              detailed explanation of expenses and risks.
            </p>
          </li>
        </ol>
      </div>
    </div>
  )
}
