import React, { Children, useEffect, useRef, useState } from 'react'
import './slider.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

function CardSlider({ slidesToShow, prevButton, nextButton, children }: any) {
  const sliderRef: any = useRef(null)
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  prevButton.current ? (prevButton.current.onclick = handlePrev) : null
  nextButton.current ? (nextButton.current.onclick = handleNext) : null
  const settings = {
    infinite: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    useCSS: true,
  }
  return (
    <>
      <Slider ref={sliderRef} {...settings} className='slider-container'>
        {children}
      </Slider>
    </>
  )
}

export { CardSlider }
