import React, { useEffect } from 'react'

export const CloseTabComponent = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.close()
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div>
      <h2>Thank you for signing the agreement!</h2>
      <p className='basic mt-12 default-gray regular'>
        The tab will close automatically in 3 seconds..
      </p>
    </div>
  )
}
